<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <v-row class="ma-0 mb-10" align="center">
      <v-col cols="1" md="3">
        <v-icon color="dark" size="40" @click="$emit('back')">
          mdi-keyboard-backspace
        </v-icon>
      </v-col>
      <v-col
        cols="11"
        md="6"
        class="text-uppercase text-center font-weight-bold"
        style="font-size: 1.2rem"
      >
        {{ $t('articles.manageCategories') }}
      </v-col>
      <v-col cols="12" md="3" class="text-end">
        <v-btn color="primary" @click="createCat">{{ $t('icons.new') }}</v-btn>
      </v-col>
    </v-row>

    <template v-if="categories.length">
      <v-row class="mx-auto" style="max-width: 700px;">
        <v-col cols="12" md="6">{{ $t('other.name') }}</v-col>
        <v-col cols="4" class="text-center d-none d-md-inline-block">{{
          $t('blog.articleNumber')
        }}</v-col>
      </v-row>

      <v-divider class="mx-auto" style="max-width: 700px;" />

      <div class="mt-2 py-3">
        <v-row
          v-for="categorie in categories"
          :key="categorie.id"
          class="my-4 mx-auto line elevation-1"
          style="max-width: 700px;"
        >
          <v-col cols="8" md="6" class="font-weight-bold" align-self="center">
            {{ controli18n($i18n.locale, categorie.name) }}
          </v-col>
          <v-col
            cols="2"
            md="4"
            align-self="center"
            class="text-center d-none d-md-inline-block"
          >
            {{ categorie.posts ? categorie.posts.length : 0 }}
          </v-col>
          <v-col
            cols="4"
            md="2"
            class="d-flex justify-space-around"
            align-self="center"
          >
            <v-icon color="green" @click="editCat(categorie)"
              >mdi-pencil</v-icon
            >
            <v-icon color="red darken-4" @click="deleteCat(categorie)">
              mdi-delete
            </v-icon>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else>
      <div class="text-center">
        <v-alert outlined>
          {{ $t('categories.haveNotCreatedYet') }}
        </v-alert>
      </div>
    </template>

    <!--  EDIT CATEGORY DIALOG  -->
    <v-dialog width="450" persistent v-model="editCategoryDialog">
      <action-modal
        @actionModal="actionForm"
        :title="$t('blog.editBlogCategory')"
      >
        <template v-slot:actionModalContent>
          <add-post-category
            ref="itemForm"
            :category="category"
            :key="category ? category.id : 'new'"
          />
        </template>
      </action-modal>
    </v-dialog>
  </div>
</template>

<script>
import { controli18n } from '../../../helpers/langs'
import swal from 'sweetalert'
import ActionModal from '../../helpers/actionModal'
import AddPostCategory from './addPostCategory'

export default {
  name: 'categoriesManager',
  components: { AddPostCategory, ActionModal },
  props: {
    categories: {
      type: Array,
      default: () => null
    }
  },
  data: () => {
    return {
      category: null,
      editCategoryDialog: false,
      loading: false
    }
  },
  methods: {
    controli18n,
    createCat() {
      this.editCategoryDialog = true
    },
    editCat(cat) {
      this.category = cat
      this.editCategoryDialog = true
    },
    async deleteCat(cat) {
      swal({
        title: this.$t('articles.areYouSureDeleteCategory', {
          category: controli18n(this.$i18n.locale, cat.name)
        }),
        buttons: [this.$t('icons.cancel'), this.$t('icons.delete')]
      }).then(async willDelete => {
        if (willDelete) {
          this.loading = true

          try {
            await this.$store.dispatch('blog/deletePostCategory', {
              postCategory: cat
            })

            this.$emit('update')
          } catch (err) {
            console.log('err:>>', err)
          }

          this.loading = false
        }
      })
    },

    actionForm(e) {
      if (e) {
        this.handleForm()
      } else {
        this.closeForm()
      }
    },
    async handleForm() {
      if (await this.$refs.itemForm.handleForm()) {
        this.$emit('update')
        this.closeForm()
      }
    },
    closeForm() {
      this.editCategoryDialog = false
      this.category = null
    }
  }
}
</script>

<style scoped>
.line {
  border: 1px solid #333333;
  border-radius: 5px;
  background-color: #fff;
  height: 75px;
}

.scrollable {
  height: 55vh;
  width: 700px;
  overflow: auto;
  margin: auto;
}
</style>
