<template>
  <div>
    <v-dialog max-width="400px" persistent v-model="openDeposit">
      <deposit-item
        :deposit="selectedItem"
        :montant-max="totalRemaining()"
        @valid="save"
        @cancel="openDeposit = false"
      />
    </v-dialog>
    <v-card>
      <v-app-bar flat color="black">
        <v-toolbar-title class="text-h5 white--text pl-0"
          >Liste des acomptes : {{ shopLine.shopName }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon color="white" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text v-if="!openDeposit">
        <v-row>
          <v-col cols="4">
            <v-sheet outlined rounded>
              <div style="padding: 5px; text-align: center">
                <label>Total Articles</label>
                {{
                  currency(shopLine.m_total, {
                    symbol: '€',
                    separator: ' ',
                    decimal: ',',
                    pattern: '# !'
                  }).format()
                }}
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="4">
            <v-sheet outlined rounded>
              <div style="padding: 5px; text-align: center">
                <label>Commission TTC</label>
                {{
                  currency(shopLine.shopCommission, {
                    symbol: '€',
                    separator: ' ',
                    decimal: ',',
                    pattern: '# !'
                  }).format()
                }}
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="4">
            <v-sheet outlined rounded>
              <div style="padding: 5px; text-align: center">
                <label>Total Boutique</label>
                {{
                  currency(shopLine.m_total - shopLine.shopCommission, {
                    symbol: '€',
                    separator: ' ',
                    decimal: ',',
                    pattern: '# !'
                  }).format()
                }}
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="4">
            <v-sheet outlined rounded>
              <div style="padding: 5px; text-align: center">
                <label>Total Acomptes</label>
                {{
                  currency(totalDeposit(), {
                    symbol: '€',
                    separator: ' ',
                    decimal: ',',
                    pattern: '# !'
                  }).format()
                }}
              </div>
            </v-sheet>
          </v-col>
          <v-col cols="4">
            <v-sheet outlined rounded>
              <div style="padding: 5px; text-align: center">
                <label>Reste à verser</label>
                {{
                  currency(totalRemaining(), {
                    symbol: '€',
                    separator: ' ',
                    decimal: ',',
                    pattern: '# !'
                  }).format()
                }}
              </div>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="deposits">
              <template v-slot:top>
                <div style="display: flex">
                  <v-spacer />
                  <v-btn
                    :disabled="totalRemaining() <= 0"
                    text
                    color="black"
                    @click="newItem()"
                  >
                    nouvel Acompte</v-btn
                  >
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:[`item.m_montant`]="{ item }">
                <div style="text-align: right">
                  {{
                    currency(item.m_montant, {
                      symbol: '€',
                      separator: ' ',
                      decimal: ',',
                      pattern: '# !'
                    }).format()
                  }}
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import currency from 'currency.js'
import { CaSDK } from '@/packages/artebeaute-sdk/src'
import dayjs from 'dayjs'
import DepositItem from '@/components/managment/deposit/depositItem'
import swal from 'sweetalert'
export default {
  name: 'depositShopCrud',
  components: { DepositItem },
  props: {
    shopLine: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      openDeposit: false,
      selectedItem: null,
      changeDeposit: false,
      deposits: [],
      headers: [
        { text: '*', value: 'actions', width: 75, sortable: true },
        { text: 'id', value: 'm_id', width: 75, sortable: true },
        { text: 'Boutique', value: 'm_idShop', width: 75, sortable: true },
        { text: 'Créé le', value: 'm_createAt', width: 75, sortable: true },
        { text: 'Montant', value: 'm_montant', width: 75, sortable: true }
      ]
    }
  },

  mounted() {
    this.deposits = JSON.parse(JSON.stringify(this.shopLine.shopDeposits))
  },
  watch: {
    shopLine: {
      handler(val) {
        this.deposits = JSON.parse(JSON.stringify(val.shopDeposits))
        this.changeDeposit = false
      }
    }
  },
  methods: {
    currency,
    totalRemaining() {
      return (
        this.shopLine.m_total -
        this.shopLine.shopCommission -
        this.totalDeposit()
      )
    },
    totalDeposit() {
      return this.deposits.reduce((prev, cur) => {

        prev += cur.m_montant
        return prev
      }, 0)
    },
    editItem(val) {
      const myDeposit = {
        m_versement: val.m_montant,
        m_versementGlobal: this.shopLine.shopToTal,
        m_createAt: dayjs(val.m_createAt).format('YYYY-MM-DD'),
        m_shopId: this.shopLine.m_idshop,
        m_shopName: this.shopLine.shopName,
        m_prct: this.shopLine.shopTxCommission,
        m_id: val.m_id
      }
      this.selectedItem = myDeposit
      this.openDeposit = true
    },
    newItem() {
      const myDeposit = {
        m_versement: null,
        m_versementGlobal: this.shopLine.shopToTal,
        m_createAt: dayjs().format('YYYY-MM-DD'),
        m_shopId: this.shopLine.m_idshop,
        m_shopName: this.shopLine.shopName,
        m_prct: this.shopLine.shopTxCommission,
        m_id: 0
      }
      this.selectedItem = myDeposit
      this.openDeposit = true
    },
    deleteItem(val) {
      swal({
        title: 'Suppression acompte',
        text: 'Êtes vous sure de vouloir supprimer cette accompte ?',
        buttons: [this.$t('icons.no'), this.$t('icons.yes')]
      }).then(async wilDelete => {
        if (wilDelete) {
          const myDeposit = {
            m_versement: 0,
            m_versementGlobal: this.shopLine.shopToTal,
            m_createAt: dayjs(val.m_createAt).format('YYYY-MM-DD'),
            m_shopId: this.shopLine.m_idshop,
            m_shopName: this.shopLine.shopName,
            m_prct: this.shopLine.shopTxCommission,
            m_id: val.m_id
          }

          const rep = await CaSDK.Advise.adviseUpdateDeposit(myDeposit)
          console.log(rep)
          const l = this.deposits.findIndex(d => d.m_id === val.m_id)
          if (l > -1) {
            this.deposits.splice(l, 1)
          }
        }
      })
    },
    async save(val) {
      if (val.m_id === 0) {
        console.log(val)
        const rep = await CaSDK.Advise.adviseAddDeposit(val)
        console.log(rep.deposit.data)
        this.openDeposit = false

        this.deposits.push(rep.deposit.data)
      } else if (parseInt(val.m_id) > 0) {
        console.log(val)
        const rep = await CaSDK.Advise.adviseUpdateDeposit(val)
        console.log('update', rep)
        const l = this.deposits.findIndex(d => d.m_id === val.m_id)

        if (l > -1) {
          this.deposits[l] = JSON.parse(JSON.stringify(rep.deposit.data))
        }
        this.openDeposit = false
      }
      this.changeDeposit = true
    },
    close() {

      if (this.changeDeposit) {
        this.$emit('refresh', this.shopLine.m_idshop)
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped></style>
