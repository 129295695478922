<template>
  <v-container>
    <v-row>
      <div v-for="(block, index) in blocks" :key="index">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-col>
            <v-card
              :width="$vuetify.breakpoint.smAndUp ? '350px' : '90vh'"
              height="200"
              :elevation="hover ? 24 : 0"
              flat
              outlined
              :to="block.to"
            >
              <v-card-title
                ><v-icon :color="block.color">{{ block.icon }}</v-icon
                ><v-spacer />
                <h3 class="font-weight-medium" :style="{ color: block.color }">
                  {{ block.title }}
                </h3></v-card-title
              >
              <v-card-text>{{ block.description }}</v-card-text>
            </v-card>
          </v-col>
        </v-hover>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import blocks from '@/views/home/blocks.js'
import layoutHome from '../../layouts/layout-home'

export default {
  name: 'Home',
  data: () => {
    return {
      // blocks: blocks().getBlocks()
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    blocks() {
      return blocks().getBlocks()
    }
  }
}
</script>
