<template>
  <div>
    <v-app>
      <v-app-bar app fixed flat dark>
        <v-toolbar-title>Avis de paiement</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon to="../management"><v-icon>mdi-close</v-icon></v-btn>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          {{ diagDepositPrct }}
          <loading-pagination
            :title="titleLoading"
            :progress-bar="progressBar"
            :progress-bar-secondary="progressBarSecondary"
            :stoppable="stoppable"
            @stop="
              stopSearch = true
              progressBar.isLoading = false
            "
          ></loading-pagination>
          <v-dialog v-model="diagDepositPrct" width="300px">
            <deposit-batch @deposit="generateBatchDeposit" />
          </v-dialog>
          <v-dialog
            v-if="itemsReglementsByShop.length > 0"
            v-model="openShopCurrentDeposit.dialog"
            persistent
            max-width="500px"
          >
            <v-card>
              <v-app-bar flat color="black">
                <v-toolbar-title class="text-h5 white--text pl-0"
                  >Acompte pour la boutique :
                  {{
                    itemsReglementsByShop[openShopCurrentDeposit.id].shopName
                  }}
                </v-toolbar-title>
                <v-spacer />
                <v-btn
                  icon
                  color="white"
                  @click="openShopCurrentDeposit.dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-app-bar>
              <v-card-text>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="
                        itemsReglementsByShop[openShopCurrentDeposit.id]
                          .shopCurrentDeposit
                      "
                      type="number"
                      single-line
                      clearable
                      autofocus
                      @keypress.enter="
                        saveDeposit(
                          itemsReglementsByShop[openShopCurrentDeposit.id],
                          true
                        )
                      "
                    />
                  </v-col>
                  <v-col cols="6" style="margin-top: auto; margin-bottom: auto">
                    <v-btn
                      :disabled="openShopCurrentDeposit.id === 0"
                      icon
                      @click="openShopCurrentDeposit.id = 0"
                      ><v-icon>mdi-page-first</v-icon></v-btn
                    >
                    <v-btn
                      :disabled="openShopCurrentDeposit.id === 0"
                      icon
                      @click="openShopCurrentDeposit.id--"
                      ><v-icon>mdi-chevron-left</v-icon></v-btn
                    >

                    <v-btn
                      :disabled="
                        openShopCurrentDeposit.id >
                        openShopCurrentDeposit.length
                      "
                      icon
                      @click="openShopCurrentDeposit.id++"
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                    <v-btn icon @click="lastcurrentDeposit()"
                      ><v-icon>mdi-page-last</v-icon></v-btn
                    >
                    {{ openShopCurrentDeposit.id }} /
                    {{ itemsReglementsByShop.length - 1 }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            max-width="700px"
            persistent
            v-model="openShopDeposit.dialog"
          >
            <deposit-shop-crud
              :shop-line="openShopDeposit.itemRef"
              @close="openShopDeposit.dialog = false"
              @refresh="refreshDeposit"
            />
            <!--<advise-deposits-shop
              @close="depositAction('close')"
              @refresh="depositAction('refresh')"
              :deposits="openShopDeposit.deposits"
              :shop="openShopDeposit.shop"
              :item-ref="openShopDeposit.itemRef"
            />-->
          </v-dialog>
          <v-dialog> </v-dialog>
          <v-dialog v-model="openPreviewRegTo" fullscreen>
            <preview-gift-and-order
              v-if="regToShopSel"
              @close="openPreviewRegTo = false"
              :shopName="regToShopSel.shopName"
              :list-booker="regToShopSel.bookerListByIdShop"
              :list-gift="regToShopSel.giftListByIdShop"
            />
          </v-dialog>
          <v-dialog v-model="openPreviewAdvise" fullscreen>
            <preview-advise-details
              v-if="adviseDetailSel"
              @close="
                openPreviewAdvise = false
                directPrint = false
              "
              :advise="adviseDetailSel"
              :direct-print="directPrint"
            />
          </v-dialog>
          <v-row>
            <v-col>
              <v-tabs hide-slider v-model="tab">
                <v-tab>Règlements non effectués</v-tab>
                <v-tab>Avis de Paiement</v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-tabs-items v-model="tab">
            <v-tab-item key="tab-0">
              <div style="display: flex; flex-wrap: wrap">
                <div>
                  <date-range
                    :period="filterReg.period"
                    @updateDate="changeDateReg"
                  />
                </div>
                <div
                  style="
                    margin-bottom: auto;
                    margin-top: auto;
                    margin-left: 10px;
                  "
                >
                  <v-btn icon @click="getReglement()"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  >
                </div>

                <v-spacer />
                <v-menu offset-y>
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          <span>Opérations</span>
                        </v-btn>
                      </template>
                      <span>Opérations</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item @click="diagDepositPrct = true">
                      <v-list-item-title
                        >Générer des acomptes par lot</v-list-item-title
                      > </v-list-item
                    ><v-list-item @click="generateAdvise()">
                      <v-list-item-title
                        >Générer tous les avis de paiement</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item @click="generateAdviseSel()">
                      <v-list-item-title
                        >Générer les avis de paiement pour les boutiques
                        sélectionnées</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-row no-gutters style="margin-top: 10px">
                <v-col cols="6" md="4" lg="2">
                  <totalizer
                    :amount="getTotalRegNoAdviseBoutique('total')"
                    label="Total"
                  ></totalizer>
                </v-col>
                <v-col cols="6" md="4" lg="2">
                  <totalizer
                    :amount="getTotalRegNoAdviseBoutique()"
                    label="Total Boutique(s)"
                  ></totalizer>
                </v-col>
                <v-col cols="6" md="4" lg="2">
                  <totalizer
                    :amount="getTotalRegNoAdviseBoutique('commission')"
                    label="Total Commission"
                  ></totalizer>
                </v-col>
                <v-col cols="6" md="4" lg="2">
                  <totalizer
                    :amount="getTotalRegNoAdviseBoutique('deposit')"
                    label="Total Acompte"
                  ></totalizer>
                </v-col>
                <v-col cols="6" md="4" lg="2">
                  <totalizer
                    :amount="getTotalRegNoAdviseBoutique('toPay')"
                    label="Reste à payer"
                  ></totalizer>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="searchRegTotalize"
                    append-icon="mdi-magnify"
                    label="Rechercher"
                    clearable
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-data-table
                    v-if="!adviseInGeneration"
                    :items="itemsReglementsByShop"
                    :headers="headersReglementsByShop"
                    :footer-props="dataTableOptions.footerProp"
                    :search="searchRegTotalize"
                    :items-per-page="50"
                    :fixed-header="true"
                    height="60vh"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip color="primary" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            icon
                            @click="getListByShop(item.m_idshop)"
                            ><v-icon>mdi-view-list</v-icon></v-btn
                          >
                        </template>
                        <span style="font-weight: bolder"
                          >Visualiser les opérations de la boutique</span
                        >
                      </v-tooltip>

                      <v-tooltip color="green" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              generateAdviseSolo(item.m_idshop, item.shopName)
                            "
                          >
                            <v-icon>mdi-folder-plus-outline</v-icon>
                          </v-btn>
                        </template>
                        <span style="font-weight: bolder"
                          >Générer l'avis de paiement pour cette boutique</span
                        >
                      </v-tooltip>
                      <v-tooltip color="blue" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :color="
                              item.shopDeposits.length > 0 ? 'blue' : 'black'
                            "
                            icon
                            @click="openDeposits(item)"
                            ><v-icon>mdi-cash-fast</v-icon></v-btn
                          >
                        </template>
                        <span style="font-weight: bolder"
                          >Liste des accomptes générés pour la boutique</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:[`item.shopCurrentDeposit`]="props">
                      <v-edit-dialog
                        :return-value="props.item.shopCurrentDeposit"
                        @save="saveDeposit(props.item)"
                      >
                        {{
                          currency(props.item.shopCurrentDeposit, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="props.item.shopCurrentDeposit"
                            single-line
                            clearable
                            autofocus
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                    </template>
                    <template v-slot:[`item.shopSel`]="{ item }">
                      <v-btn
                        icon
                        class="cursor-pointer"
                        @click="item.shopSel = !item.shopSel"
                      >
                        <v-icon v-if="!item.shopSel">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                        <v-icon v-else> mdi-checkbox-blank-circle </v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:[`item.m_nbTransaction`]="{ item }">
                      {{
                        currency(item.m_nbTransaction, {
                          symbol: '',
                          precision: 0,
                          pattern: '#!',
                        }).format()
                      }}
                    </template>
                    <template v-slot:[`item.m_total`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.m_total, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.shopDeposit`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.shopDeposit, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>

                    <template v-slot:[`item.shopToTal`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.shopToTal, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.shopToPay`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.shopToPay, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="tab-1">
              <div style="display: flex; flex-wrap: wrap">
                <div>
                  <date-range
                    :period="filterAdv.period"
                    @updateDate="changeDateAdv"
                  />
                </div>
                <div
                  style="
                    margin-bottom: auto;
                    margin-top: auto;
                    margin-left: 10px;
                  "
                >
                  <v-btn icon @click="getAdvisePayments()"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  >
                </div>

                <v-spacer />

                <v-btn color="primary" dark @click="printAdvises()"
                  >tout imprimer</v-btn
                >
              </div>
              <v-row no-gutters style="margin-top: 10px">
                <v-col>
                  <v-row>
                    <v-col cols="6" md="4" lg="2">
                      <totalizer
                        :amount="getTotalAdvise('total')"
                        label="Total"
                      ></totalizer>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                      <totalizer
                        :amount="getTotalAdvise('boutique')"
                        label="Total Boutique"
                      ></totalizer>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                      <totalizer
                        :amount="getTotalAdvise('commission')"
                        label="Total Commission"
                      ></totalizer>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                      <totalizer
                        :amount="getTotalAdvise('deposit')"
                        label="Total Acompte"
                      ></totalizer>
                    </v-col>
                    <v-col cols="6" md="4" lg="2">
                      <totalizer
                        :amount="getTotalAdvise('toPay')"
                        label="Reste à payer"
                      ></totalizer>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="searchAdvTotalize"
                    append-icon="mdi-magnify"
                    label="Rechercher"
                    clearable
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    :headers="headersAdvise"
                    :items="itemsAdvisePayment"
                    :footer-props="dataTableOptions.footerProp"
                    :search="searchAdvTotalize"
                    :items-per-page="50"
                    :fixed-header="true"
                    height="60vh"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip color="primary" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            icon
                            @click="getAdviseSel(item.m_id, false)"
                            ><v-icon>mdi-view-list</v-icon></v-btn
                          >
                        </template>
                        <span style="font-weight: bolder"
                          >Visualiser l'avis de paiement</span
                        >
                      </v-tooltip>
                      <v-tooltip color="black" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="black"
                            icon
                            @click="getAdviseSel(item.m_id, true)"
                            ><v-icon>mdi-printer</v-icon></v-btn
                          >
                        </template>
                        <span style="font-weight: bolder"
                          >Imprimer l'avis de paiement</span
                        >
                      </v-tooltip>
                    </template>

                    <template v-slot:[`item.shopMail`]="{ item }">
                      {{ getShopMail(item.m_shopId) }}
                    </template>
                    <template v-slot:[`item.m_reglement`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.m_reglement, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.m_deposit`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.m_deposit, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.m_comTtc`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.m_comTtc, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.m_articleAmount`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.m_articleAmount, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                    <template v-slot:[`item.m_regaVerse`]="{ item }">
                      <div style="text-align: right">
                        {{
                          currency(item.m_regaVerse, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !',
                          }).format()
                        }}
                      </div>
                    </template>
                  </v-data-table>
                </v-col></v-row
              >
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { CaSDK } from '@/packages/artebeaute-sdk/src'
import DateRange from '@/components/general/Date/DateRange'
import dayjs from 'dayjs'
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
import PreviewGiftAndOrder from '@/components/managment/previewGiftAndOrder'
import LoadingPagination from '@/components/general/loading/loadingPagination'
import PreviewAdviseDetails from '@/components/managment/previewAdviseDetails'

import swal from 'sweetalert'
import currency from 'currency.js'
import DepositShopCrud from '@/components/managment/deposit/depositShopCRUD'
import Totalizer from '@/components/general/objects/totalizer'
import DepositBatch from '@/components/managment/deposit/depositBatch'

export default {
  name: 'reversBookerByShop',
  components: {
    DepositBatch,
    Totalizer,
    DepositShopCrud,

    PreviewAdviseDetails,
    LoadingPagination,
    PreviewGiftAndOrder,
    DateRange,
  },
  data() {
    return {
      diagDepositPrct: false,
      directPrint: false,
      openShopCurrentDeposit: {
        dialog: false,
        id: 0,

        shop: {
          id: 0,
          name: '',
        },
      },
      shopLineSelected: null,
      openShopDeposit: {
        dialog: false,
        deposits: [],
        shop: {
          id: 0,
          name: '',
        },
        itemRef: null,
      },
      searchRegTotalize: '',
      searchAdvTotalize: '',
      tab: 0,
      dataTableOptions: {
        footerProp: {
          itemsPerPageOptions: [10, 25, 50, 100],
        },
      },
      itemsReglementsByShop: [],
      itemsAdvisePayment: [],
      regToShopSel: null,
      adviseDetailSel: null,
      openPreviewRegTo: false,
      openPreviewAdvise: false,
      // isLoading: false,
      titleLoading: '',
      stoppable: false,
      stopSearch: false,
      progressBar: {
        isLoading: false,
        type: 'linear',
        title: 'Chargement',
        value: -1,
        duration: -1,
      },
      progressBarSecondary: {
        isLoading: false,
        type: 'linear',
        title: '',
        value: 0,
        duration: -1,
      },
      adviseInGeneration: false,
      prctCommission: 1.8,
      loadItemPerpage: 100,
      filterReg: {
        period: {
          start: null,
          end: null,
        },
      },
      filterAdv: {
        period: {
          start: null,
          end: null,
        },
      },
      headersAdvise: [
        { text: '*', value: 'actions', width: 75, sortable: true },

        { text: 'N°', value: 'm_numfacture', width: 50, sortable: false },
        { text: 'Société', value: 'm_societe', width: 50, sortable: false },
        { text: 'Boutique', value: 'm_shopName', width: 50, sortable: false },
        { text: 'Mail', value: 'shopMail', width: 50, sortable: false },
        {
          text: 'Opération',
          value: 'm_ope',
          width: 50,
          sortable: true,
        },
        { text: 'règlement du', value: 'm_start', width: 75, sortable: false },
        { text: 'au', value: 'm_end', width: 75, sortable: false },
        {
          text: 'Total Articles',
          value: 'm_articleAmount',
          width: 50,
          sortable: true,
        },
        {
          text: 'Tx Com',
          value: 'm_tauxCommission',
          width: 50,
          sortable: true,
        },
        {
          text: 'Com. TTC',
          value: 'm_comTtc',
          width: 50,
          sortable: true,
        },
        {
          text: 'Total Boutique',
          value: 'm_regaVerse',
          width: 50,
          sortable: true,
        },
        {
          text: 'Total Acompte',
          value: 'm_deposit',
          width: 50,
          sortable: true,
        },
        {
          text: 'Reste à Verser',
          value: 'm_reglement',
          width: 50,
          sortable: true,
        },
      ],
      headersReglementsByShop: [
        { text: '*', value: 'actions', width: 100, sortable: true },
        { text: 'sel', value: 'shopSel', width: 50, sortable: true },
        { text: 'Groupement', value: 'shopGrp', width: 50, sortable: false },
        { text: 'Société', value: 'shopSociety', width: 100, sortable: false },
        { text: 'Boutique', value: 'shopName', width: 150, sortable: false },
        {
          text: 'nb T',
          value: 'm_nbTransaction',
          width: 50,
          sortable: true,
        },
        { text: 'Total', value: 'm_total', width: 50, sortable: false },

        {
          text: 'Tx Com.',
          value: 'shopTxCommission',
          width: 30,
          sortable: false,
        },
        {
          text: 'Com. TTC',
          value: 'shopCommission',
          width: 50,
          sortable: false,
        },
        {
          text: 'Total Boutique',
          value: 'shopToTal',
          width: 50,
          sortable: false,
        },
        {
          text: 'Acomptes',
          value: 'shopDeposit',
          width: 50,
          sortable: true,
        },
        {
          text: 'à verser',
          value: 'shopToPay',
          width: 50,
          sortable: false,
        },
      ],
    }
  },

  created() {
    if (this.$store.state.auth.company.identity.prctcom) {
      this.prctCommission = this.$store.state.auth.company.identity.prctcom
    } else {
      this.prctCommission = 0
    }

    this.filterReg.period.start = dayjs().format('YYYYMMDD')
    this.filterReg.period.end = dayjs().format('YYYYMMDD')
    this.filterAdv.period.start = dayjs().format('YYYYMMDD')
    this.filterAdv.period.end = dayjs().format('YYYYMMDD')
  },
  computed: {},
  methods: {
    currency,
    lastcurrentDeposit() {
      this.openShopCurrentDeposit.id = this.itemsReglementsByShop.length - 1
    },
    openDeposits(item) {
      this.openShopDeposit.deposits = JSON.parse(
        JSON.stringify(item.shopDeposits)
      )

      this.openShopDeposit.shop.id = item.m_idshop
      this.openShopDeposit.shop.name = item.shopName
      this.openShopDeposit.dialog = true
      this.openShopDeposit.itemRef = item
    },
    async generateAdviseSel() {
      swal({
        title: 'Avis de paiement',
        text: "Voulez vous générer l'avis de paiement pour les boutiques sélectionnées ?",
        buttons: [this.$t('icons.no'), this.$t('icons.yes')],
      }).then(async (willDelete) => {
        if (willDelete) {
          this.adviseInGeneration = true
          this.progressBar.isLoading = true

          await this.addAdvise()

          this.progressBar.isLoading = false
          this.adviseInGeneration = false
          await this.getReglement()
        }
      })
    },
    async generateAdvise() {
      swal({
        title: 'Avis de paiement',
        text: "Voulez vous générer l'avis de paiement pour toutes les boutiques ?",
        buttons: [this.$t('icons.no'), this.$t('icons.yes')],
      }).then(async (willDelete) => {
        if (willDelete) {
          this.adviseInGeneration = true
          this.progressBar.isLoading = true
          this.itemsReglementsByShop.forEach((e) => {
            e.shopSel = true
          })
          await this.addAdvise()

          this.progressBar.isLoading = false
          this.adviseInGeneration = false
          await this.getReglement()
        }
      })
    },
    async generateAdviseSolo(id, shopName = '') {
      swal({
        title: 'Avis de paiement',
        text:
          "Voulez vous générer l'avis de paiement pour la boutique " +
          shopName +
          '?',
        buttons: [this.$t('icons.no'), this.$t('icons.yes')],
      }).then(async (willDelete) => {
        if (willDelete) {
          const i = this.itemsReglementsByShop.findIndex(
            (e) => e.m_idshop === id
          )
          if (i > -1) {
            this.adviseInGeneration = true
            this.progressBar.isLoading = true
            this.stopSearch = false

            await this.addAdvise(i, true)
            this.progressBar.isLoading = false
            if (!this.stopSearch) {
              this.itemsReglementsByShop.splice(i, 1)
            }
            this.adviseInGeneration = false
          }
        }
      })
    },
    async addAdviseLineGift(adviseId, i = 0, start = 0, end = 0) {
      this.progressBarSecondary.title =
        'Sauvegarde ligne Bkdo ( ' +
        start +
        ' à ' +
        end +
        ' ) ' +
        ' sur ' +
        this.itemsReglementsByShop[i].giftListByIdShop.length

      const tabGiftSlice = this.itemsReglementsByShop[i].giftListByIdShop.slice(
        start,
        end
      )

      const body = {
        items: tabGiftSlice,
        adviseId,
      }

      await CaSDK.Advise.adviseAddLines(body)

      if (this.itemsReglementsByShop[i].giftListByIdShop.length > end) {
        await this.addAdviseLineGift(
          adviseId,
          i,
          end,
          end + this.loadItemPerpage
        )
      }
    },
    async addAdviseLineBooker(adviseId, i = 0, start = 0, end = 0) {
      this.progressBarSecondary.isLoading = true
      this.progressBarSecondary.title =
        'Sauvegarde ligne Résa ( ' +
        start +
        ' à ' +
        end +
        ' ) ' +
        ' sur ' +
        this.itemsReglementsByShop[i].bookerListByIdShop.length

      const tabBookerSlice = this.itemsReglementsByShop[
        i
      ].bookerListByIdShop.slice(start, end)

      const body = {
        items: tabBookerSlice,
        adviseId,
      }

      await CaSDK.Advise.adviseAddLines(body)

      if (this.itemsReglementsByShop[i].bookerListByIdShop.length > end) {
        await this.addAdviseLineBooker(
          adviseId,
          i,
          end,
          end + this.loadItemPerpage
        )
      } else {
        this.progressBarSecondary.isLoading = false
      }
    },
    async addAdvise(i = 0, solo = false) {
      if (
        (this.itemsReglementsByShop[i].shopSel || solo) &&
        !this.itemsReglementsByShop[i].done
      ) {
        if (!this.itemsReglementsByShop[i].charged) {
          this.titleLoading =
            'Chargement ' +
            this.itemsReglementsByShop[i].shopName +
            ' ( ' +
            i +
            '/' +
            this.itemsReglementsByShop.length +
            ' )'

          await this.getListByShop(
            this.itemsReglementsByShop[i].m_idshop,
            false
          )
          this.stoppable = false
        } else {
          this.stoppable = false
        }
        if (!this.stopSearch) {
          const advise = {}
          advise.shopId = this.itemsReglementsByShop[i].m_idshop
          advise.deposit = this.itemsReglementsByShop[i].shopDeposit
          advise.address = this.$store.state.auth.company.identity.address

          advise.articleAmount = this.itemsReglementsByShop[i].m_total
          advise.shopName = this.itemsReglementsByShop[i].shopName
          advise.comTtc = this.itemsReglementsByShop[i].shopCommission
          advise.txTva = 20
          advise.comHt =
            Math.round((advise.comTtc / (1 + advise.txTva / 100)) * 100) / 100
          advise.comTva =
            Math.round(advise.comTtc * 100 - advise.comHt * 100) / 100
          advise.start = dayjs(this.filterReg.period.start).format('YYYYMMDD')
          advise.end = dayjs(this.filterReg.period.end).format('YYYYMMDD')
          advise.dOpe = dayjs().format('YYYYMMDD')
          advise.info = this.$store.state.auth.company.identity.city // this.itemsReglementsByShop[i].m_idshop
          advise.infoSociete = 'à ajouter' // this.itemsReglementsByShop[i].m_idshop
          advise.localite =
            this.$store.state.auth.company.identity.zipCode +
            ' ' +
            this.$store.state.auth.company.identity.city // this.itemsReglementsByShop[i].m_idshop
          advise.nbLine =
            this.itemsReglementsByShop[i].bookerListByIdShop.length +
            this.itemsReglementsByShop[i].giftListByIdShop.length
          advise.observation = ''
          advise.reglement = this.itemsReglementsByShop[i].shopToPay
          advise.reglementAverse = this.itemsReglementsByShop[i].shopToTal
          advise.societe = this.$store.state.auth.company.identity.companyName
          advise.txcom = this.itemsReglementsByShop[i].shopTxCommission
          this.titleLoading =
            'Enregistrement ' + this.itemsReglementsByShop[i].shopName
          this.progressBarSecondary.isLoading = true
          this.progressBarSecondary.value = -1
          this.progressBarSecondary.duration = -1
          this.progressBarSecondary.title = ''
          const rep = await CaSDK.Advise.adviseAddPayment(advise)
          this.progressBarSecondary.isLoading = false
          // Traitement des boutiques
          if (
            this.itemsReglementsByShop[i].bookerListByIdShop.length >
            this.loadItemPerpage
          ) {
            await this.addAdviseLineBooker(
              rep.adviseIdPayment.data,
              i,
              0,
              this.loadItemPerpage
            )
          } else if (
            this.itemsReglementsByShop[i].bookerListByIdShop.length > 0
          ) {
            await this.addAdviseLineBooker(
              rep.adviseIdPayment.data,
              i,
              0,
              this.itemsReglementsByShop[i].bookerListByIdShop.length
            )
          }
          // Traitement des boutiquers
          if (
            this.itemsReglementsByShop[i].giftListByIdShop.length >
            +this.loadItemPerpage
          ) {
            await this.addAdviseLineGift(
              rep.adviseIdPayment.data,
              i,
              0,
              +this.loadItemPerpage
            )
          } else if (
            this.itemsReglementsByShop[i].giftListByIdShop.length > 0
          ) {
            await this.addAdviseLineGift(
              rep.adviseIdPayment.data,
              i,
              0,
              this.itemsReglementsByShop[i].giftListByIdShop.length
            )
          }

          if (!solo && i < this.itemsReglementsByShop.length - 1) {
            this.itemsReglementsByShop[i].done = true
            await this.addAdvise(i + 1)
          }
        }
      } else if (!solo && i < this.itemsReglementsByShop.length - 1) {
        await this.addAdvise(i + 1)
      }
    },
    getTotalAdvise(val = 'boutique') {
      if (val === 'total') {
        return this.itemsAdvisePayment.reduce((prev, cur) => {
          prev += cur.m_articleAmount
          return prev
        }, 0)
      } else if (val === 'commission') {
        return this.itemsAdvisePayment.reduce((prev, cur) => {
          prev += cur.m_comTtc
          return prev
        }, 0)
      } else if (val === 'boutique') {
        return this.itemsAdvisePayment.reduce((prev, cur) => {
          prev += cur.m_regaVerse
          return prev
        }, 0)
      } else if (val === 'deposit') {
        return this.itemsAdvisePayment.reduce((prev, cur) => {
          prev += cur.m_deposit
          return prev
        }, 0)
      } else if (val === 'toPay') {
        return this.itemsAdvisePayment.reduce((prev, cur) => {
          prev += cur.m_reglement
          return prev
        }, 0)
      }
    },
    getTotalRegNoAdviseBoutique(val = 'boutique') {
      if (val === 'boutique') {
        return this.itemsReglementsByShop.reduce((prev, cur) => {
          prev += cur.shopToTal
          return prev
        }, 0)
      } else if (val === 'total') {
        return this.itemsReglementsByShop.reduce((prev, cur) => {
          prev += cur.m_total
          return prev
        }, 0)
      } else if (val === 'commission') {
        return this.itemsReglementsByShop.reduce((prev, cur) => {
          prev += cur.shopCommission
          return prev
        }, 0)
      } else if (val === 'deposit') {
        return this.itemsReglementsByShop.reduce((prev, cur) => {
          prev += cur.shopDeposit
          return prev
        }, 0)
      } else if (val === 'toPay') {
        return this.itemsReglementsByShop.reduce((prev, cur) => {
          prev = (prev * 100 + cur.shopToPay * 100) / 100
          return prev
        }, 0)
      }
      return 0
    },
    getShopMail(shopId) {
      const shop = this.$store.getters['shop/abShopById'](shopId.toString())
      if (shop) {
        return shop.email
      }
      return ''
    },
    changeDateReg(val) {
      this.filterReg.period.start = val.start
      this.filterReg.period.end = val.end
    },
    changeDateAdv(val) {
      this.filterAdv.period.start = val.start
      this.filterAdv.period.end = val.end
    },
    async printAdvises() {
      this.stopSearch = false
      for (const adv of this.itemsAdvisePayment) {
        if (this.stopSearch) {
          break
        }
        await this.getAdviseSel(adv.m_id)
      }
    },
    async getAdviseSel(id, direct = true) {
      this.titleLoading = 'Chargement'
      const adviseidx = this.itemsAdvisePayment.findIndex((s) => s.m_id === id)

      if (adviseidx !== -1) {
        if (this.itemsAdvisePayment[adviseidx].details) {
          this.adviseDetailSel = this.itemsAdvisePayment[adviseidx]

          this.progressBar.isLoading = false

          this.openPreviewAdvise = true
        } else {
          this.progressBar.isLoading = true
          this.progressBar.value = 0
          this.stoppable = true
          await this.getAdviseDetails(true, adviseidx, 1, 0)

          this.progressBar.isLoading = false
          if (direct) {
            this.directPrint = true
          }

          this.openPreviewAdvise = true
          this.adviseDetailSel = this.itemsAdvisePayment[adviseidx]
        }
      } else {
        return []
      }
    },
    async getAdviseDetails(init = true, id, page = 1, nbpage = 0) {
      const adviseidx = id
      let nbpagec = nbpage
      let rep = null
      if (page === 1) {
        this.progressBarSecondary.title = 'Initialisation chargement'
      } else {
        this.progressBarSecondary.title =
          'Chargement page ' + page + ' sur ' + nbpagec
      }
      const query = {
        idAdvise: this.itemsAdvisePayment[adviseidx].m_id,
        itemPerPage: this.loadItemPerpage,
        page,
      }

      if (init) {
        this.itemsAdvisePayment[adviseidx].details = []
        rep = await CaSDK.Advise.advisePaymentDetail(query)
        if (rep.advisePayments.success) {
          this.itemsAdvisePayment[adviseidx].details = this.itemsAdvisePayment[
            adviseidx
          ].details.concat(rep.advisePayments.data.items)
        } else {
          this.progressBar.isLoading = false
          return false
        }

        nbpagec = rep.advisePayments.data.m_nbpage
        if (nbpagec > rep.advisePayments.data.m_numpage) {
          await this.getAdviseDetails(false, id, page + 1, nbpagec)
        } else {
          this.adviseDetailSel = this.itemsAdvisePayment[adviseidx]
          this.progressBar.isLoading = false
          return true
        }
      } else {
        rep = await CaSDK.Advise.advisePaymentDetail(query)
        if (rep.advisePayments.success) {
          this.itemsAdvisePayment[adviseidx].details = this.itemsAdvisePayment[
            adviseidx
          ].details.concat(rep.advisePayments.data.items)
        } else {
          this.progressBar.isLoading = false
          return false
        }

        if (nbpagec > rep.advisePayments.data.m_numpage) {
          await this.getAdviseDetails(false, id, page + 1, nbpagec)
        } else {
          this.adviseDetailSel = this.itemsAdvisePayment[adviseidx]
          this.progressBar.isLoading = false
          return true
        }
      }
    },
    async getAdvisePayments(init = true, page = 1, nbpage = 0) {
      let nbpagec = nbpage
      let rep = null

      const query = {
        start: this.filterAdv.period.start.replaceAll('-', ''),
        end: this.filterAdv.period.end.replaceAll('-', ''),
        page,
      }
      if (init) {
        this.progressBar.isLoading = true
        this.itemsAdvisePayment = []
        rep = await CaSDK.Advise.advisePayments(query)
        if (rep.advisePayments.success) {
          this.itemsAdvisePayment = this.itemsAdvisePayment.concat(
            rep.advisePayments.data.items
          )
          nbpagec = rep.advisePayments.data.m_nbpage

          if (nbpagec > rep.advisePayments.data.m_numpage) {
            await this.getAdvisePayments(false, page + 1, nbpagec)
          } else {
            this.progressBar.isLoading = false
            return false
          }
        } else {
          this.progressBar.isLoading = false
          return false
        }
      } else {
        rep = await CaSDK.Advise.advisePayments(query)
        if (rep.advisePayments.success) {
          this.itemsAdvisePayment = this.itemsAdvisePayment.concat(
            rep.advisePayments.data.items
          )
          if (nbpagec > rep.advisePayments.data.m_numpage) {
            await this.getAdvisePayments(false, page + 1, nbpagec)
          } else {
            this.progressBar.isLoading = false
            return false
          }
        } else {
          this.progressBar.isLoading = false
          return false
        }
      }
    },
    async getReglement() {
      this.stoppable = false
      this.titleLoading = 'Chargement'
      this.progressBar.isLoading = true
      this.progressBar.type = 'linear'
      this.progressBar.title = 'Réglements non versés'
      this.progressBar.value = 0
      this.progressBarSecondary.isLoading = false

      this.itemsReglementsByShop = []
      const query1 = {
        start: this.filterReg.period.start.replaceAll('-', ''),
        end: this.filterReg.period.end.replaceAll('-', ''),
        typeReg: 'eden',
      }

      const regTotal = await CaSDK.Reglement.reglementByShop(query1)
      this.progressBar.value = 50
      this.progressBar.title = 'Acomptes versés'
      const deposits = await this.getRemainingDepositShops()
      this.progressBar.value = 90
      if (regTotal.reglements.success) {
        this.prepareRegShop(regTotal.reglements.data, deposits)
        this.progressBar.value = 100
      }
      this.progressBar.isLoading = false
    },
    async generateBatchDeposit(val = 100) {
      this.diagDepositPrct = false

      let myDeposit = {}
      this.progressBar.isLoading = true
      for (const item of this.itemsReglementsByShop) {
        if (item.shopToPay > 0) {
          const total = Math.round(item.shopToPay * 100)
          let versement = Math.round((total * val) / 100) / 100
          if (val === 100) {
            versement = item.shopToPay
          }
          myDeposit = {
            m_versement: versement,
            m_versementGlobal: item.shopToTal,
            m_createAt: dayjs().format('YYYY-MM-DD'),
            m_shopId: item.m_idshop,
            m_shopName: item.shopName,
            m_prct: item.shopTxCommission,
            m_shopTopay: item.shopToPay,
            m_id: 0,
          }

          await CaSDK.Advise.adviseAddDeposit(myDeposit)
        }
      }
      this.progressBar.isLoading = false
    },
    async saveDeposit(val, next = false) {
      if (
        Math.floor(
          (val.m_total -
            val.shopCommission -
            val.shopDeposit -
            val.shopCurrentDeposit) *
            100
        ) /
          100 <
        0
      ) {
        return
      }
      const l = this.itemsReglementsByShop.findIndex(
        (s) => s.m_idshop === val.m_idshop
      )
      if (l > -1) {
        const prct =
          val.shopToPay > 0
            ? Math.round(
                (parseFloat(val.shopCurrentDeposit) /
                  parseFloat(val.shopToPay)) *
                  100 *
                  100
              ) / 100
            : 0
        const myDeposit = {
          m_versement: parseFloat(val.shopCurrentDeposit),
          m_versementGlobal: val.shopToPay,
          m_shopId: val.m_idshop,
          m_prct: prct,
          m_id: val.shopCurrentDepositId,
        }

        const rep = await CaSDK.Advise.adviseAddDeposit(myDeposit)

        this.itemsReglementsByShop[l].shopCurrentDepositId = rep.deposit.data
        this.itemsReglementsByShop[l].shopToPay =
          Math.floor(
            (this.itemsReglementsByShop[l].m_total -
              this.itemsReglementsByShop[l].shopCommission -
              this.itemsReglementsByShop[l].shopDeposit -
              this.itemsReglementsByShop[l].shopCurrentDeposit) *
              100
          ) / 100
        if (next) {
          if (
            this.openShopCurrentDeposit.id < this.itemsReglementsByShop.length
          ) {
            this.openShopCurrentDeposit.id++
          }
        }
      }
    },
    async depositAction(val) {
      if (val === 'refresh') {
        // const deposits = await this.getRemainingDepositShops()
        await this.getRemainingDepositShop(this.openShopDeposit.shop.id)
      }
      this.openShopDeposit.dialog = false
    },
    async refreshDeposit(val) {
      await this.getRemainingDepositShop(val)
      this.openShopDeposit.dialog = false
    },
    async prepareRegShop(val, valdeposit) {
      this.itemsReglementsByShop = val.reduce((prev, cur) => {
        const shop = this.$store.getters['shop/abShopById'](cur.m_idshop)
        const shopDeposits = valdeposit.filter(
          (s) => s.m_idShop === cur.m_idshop
        )

        const shopDeposit = shopDeposits.reduce((prev, cur) => {
          prev += cur.m_montant
          return prev
        }, 0)

        const shopCurrentDeposit = 0
        const shopCurrentDepositId = 0
        const shopName = shop.name
        const shopGrp = shop.grp.name
        const shopSociety = shop.society
        const shopMail = shop.email
        const shopSel = false
        const listLoading = false
        const bookerListByIdShop = []
        const giftListByIdShop = []

        const charged = false
        const done = false
        const shopCommission =
          Math.floor(cur.m_total * (this.prctCommission / 100) * 100) / 100
        if (cur.m_idshop === '129') {
          debugger
        }
        const shopToTal = (cur.m_total * 100 - shopCommission * 100) / 100
        const shopToPay =
          Math.round(
            cur.m_total * 100 - shopCommission * 100 - shopDeposit * 100
          ) / 100
        const shopTxCommission = this.prctCommission

        prev.push({
          ...cur,
          done,
          shopCurrentDeposit,
          shopCurrentDepositId,
          shopDeposits,
          shopTxCommission,
          charged,
          shopCommission,
          shopToTal,
          shopToPay,
          shopDeposit,
          shopSel,
          shopName,
          shopGrp,
          shopSociety,
          shopMail,
          bookerListByIdShop,
          giftListByIdShop,
          listLoading,
        })
        return prev
      }, [])
    },
    async getListByShop(id, manageProgressbar = true) {
      this.stopSearch = false
      this.stoppable = true
      const shopId = this.itemsReglementsByShop.findIndex(
        (s) => s.m_idshop === id
      )
      if (shopId !== -1) {
        if (!this.itemsReglementsByShop[shopId].listLoading) {
          this.progressBar.title = ''
          this.progressBar.isLoading = true
          this.progressBar.type = 'linear'
          this.progressBar.value = 0

          let rep = false
          this.progressBarSecondary.isLoading = true
          this.progressBarSecondary.value = 0
          this.progressBarSecondary.duration = -1
          rep = await this.getBookerListByShop(true, shopId, 1, 0)
          this.progressBar.value = 50
          this.progressBarSecondary.duration = -1
          if (rep) {
            // this.progressBar.title = 'Chargement des bons cadeaux encaissés'
            rep = await this.getGiftListByShop(true, shopId, 1, 0)
            this.progressBar.value = 100
          }
          if (this.stopSearch) {
            return false
          }
          this.itemsReglementsByShop[shopId].listLoading = true
          if (manageProgressbar) {
            this.progressBar.isLoading = false
          }

          this.progressBarSecondary.isLoading = false
        }

        this.regToShopSel = this.itemsReglementsByShop[shopId]
        this.openPreviewRegTo = !this.adviseInGeneration
      }
    },
    async getRemainingDepositShop(shopid) {
      const i = this.itemsReglementsByShop.findIndex(
        (s) => s.m_idshop === shopid
      )
      const shopId = this.itemsReglementsByShop[i].m_idshop
      const query = {
        shopId: shopId,
      }
      const rep = await CaSDK.Advise.adviseRemainingDeposit(query)
      if (rep.adviseRemainingDeposit.success) {
        this.itemsReglementsByShop[i].shopDeposits = JSON.parse(
          JSON.stringify(rep.adviseRemainingDeposit.data)
        )
        this.itemsReglementsByShop[i].shopDeposit = this.itemsReglementsByShop[
          i
        ].shopDeposits.reduce((prev, cur) => {
          prev += cur.m_montant
          return prev
        }, 0)
        this.itemsReglementsByShop[i].shopToPay =
          Math.floor(
            (this.itemsReglementsByShop[i].m_total -
              this.itemsReglementsByShop[i].shopCommission -
              this.itemsReglementsByShop[i].shopDeposit -
              this.itemsReglementsByShop[i].shopCurrentDeposit) *
              100
          ) / 100
      }
    },
    async getRemainingDepositShops() {
      const rep = await CaSDK.Advise.adviseRemainingDeposit('')

      if (rep.adviseRemainingDeposit.success) {
        return rep.adviseRemainingDeposit.data
      } else {
        return []
      }
    },
    async getBookerListByShop(
      init = true,
      shopId,
      page = 1,
      nbpage = 0,
      durationCalc = 0
    ) {
      if (this.stopSearch) {
        return false
      }
      let nbpagec = nbpage
      if (shopId !== -1) {
        let query = {}
        let rep = null
        if (page === 1) {
          this.progressBarSecondary.title = 'Réservations '
        } else {
          const estimateDuration = (nbpage - page) * durationCalc
          if (page > 2) {
            this.progressBarSecondary.duration = estimateDuration
          }
          this.progressBarSecondary.title =
            'Réservation page  ' + page + ' sur ' + nbpage
        }

        if (init) {
          this.progressBarSecondary.value = 0
          query = {
            start: this.filterReg.period.start.replaceAll('-', ''),
            end: this.filterReg.period.end.replaceAll('-', ''),
            idshop: this.itemsReglementsByShop[shopId].m_idshop,
            itemPerPage: this.loadItemPerpage,
            page,
          }
          rep = await CaSDK.Reglement.bookerListByIdShop(query)

          if (rep.list.success) {
            this.itemsReglementsByShop[shopId].bookerListByIdShop =
              this.itemsReglementsByShop[shopId].bookerListByIdShop.concat(
                rep.list.data.items
              )

            nbpagec = rep.list.data.m_nbpage

            if (nbpagec > rep.list.data.m_numpage) {
              await this.getBookerListByShop(
                false,
                shopId,
                page + 1,
                nbpagec,
                rep.list.duration
              )
            } else {
              return true
            }
          } else {
            return false
          }
        } else {
          query = {
            start: this.filterReg.period.start.replaceAll('-', ''),
            end: this.filterReg.period.end.replaceAll('-', ''),
            idshop: this.itemsReglementsByShop[shopId].m_idshop,
            itemPerPage: this.loadItemPerpage,
            page,
          }
          this.progressBarSecondary.value =
            nbpagec !== 0 ? parseInt((page / nbpagec) * 100) : 100
          const rep = await CaSDK.Reglement.bookerListByIdShop(query)
          if (rep.list.success) {
            this.itemsReglementsByShop[shopId].bookerListByIdShop =
              this.itemsReglementsByShop[shopId].bookerListByIdShop.concat(
                rep.list.data.items
              )

            if (nbpagec > rep.list.data.m_numpage) {
              await this.getBookerListByShop(
                false,
                shopId,
                page + 1,
                nbpagec,
                rep.list.duration
              )
            } else {
              return true
            }
          } else {
            return false
          }
        }
      }
      return true
    },
    async getGiftListByShop(
      init = true,
      shopId,
      page = 1,
      nbpage = 0,
      durationCalc = 0
    ) {
      if (this.stopSearch) {
        return false
      }
      let nbpagec = nbpage
      if (page === 1) {
        this.progressBarSecondary.title = 'Bons Cadeaux'
      } else {
        const estimateDuration = (nbpage - page) * durationCalc
        if (page > 2) {
          this.progressBarSecondary.duration = estimateDuration
        }

        this.progressBarSecondary.title =
          'Bons Cadeaux page  ' + page + ' sur ' + nbpage
      }
      if (shopId !== -1) {
        let query = {}
        let rep = null
        if (init) {
          this.progressBarSecondary.value = 0
          query = {
            start: this.filterReg.period.start.replaceAll('-', ''),
            end: this.filterReg.period.end.replaceAll('-', ''),
            idshop: this.itemsReglementsByShop[shopId].m_idshop,
            itemPerPage: this.loadItemPerpage,
            page,
          }
          rep = await CaSDK.Reglement.giftListByIdShop(query)

          if (rep.list.success) {
            this.itemsReglementsByShop[shopId].giftListByIdShop =
              this.itemsReglementsByShop[shopId].giftListByIdShop.concat(
                rep.list.data.items
              )

            nbpagec = rep.list.data.m_nbpage
            if (nbpagec > rep.list.data.m_numpage) {
              await this.getGiftListByShop(
                false,
                shopId,
                page + 1,
                nbpagec,
                rep.list.duration
              )
            } else {
              return true
            }
          } else {
            return false
          }
        } else {
          query = {
            start: this.filterReg.period.start.replaceAll('-', ''),
            end: this.filterReg.period.end.replaceAll('-', ''),
            idshop: this.itemsReglementsByShop[shopId].m_idshop,
            itemPerPage: 1,
            page,
          }
          this.progressBarSecondary.value =
            nbpagec !== 0 ? parseInt((page / nbpagec) * 100) : 100
          rep = await CaSDK.Reglement.giftListByIdShop(query)
          if (rep.list.success) {
            this.itemsReglementsByShop[shopId].giftListByIdShop =
              this.itemsReglementsByShop[shopId].giftListByIdShop.concat(
                rep.list.data.items
              )

            if (nbpagec > rep.list.data.m_numpage) {
              await this.getGiftListByShop(
                false,
                shopId,
                page + 1,
                nbpagec,
                rep.list.duration
              )
            } else {
              return true
            }
          } else {
            return false
          }
        }
      }
      return false
    },
  },
}
</script>

<style scoped></style>
