// import colors from 'vuetify/lib/util/colors'
import i18n from '@/i18n'

export default function () {
  return {
    values: [
      {
        index: 0,
        title: 'Avis de paiement / Boutique',
        icon: 'mdi-store',
        description:
          'Gestion des réservations et des bons cadeaux à reverser au boutique',
        // color: colors.pink.accent1,
        color: 'rgb(243, 244, 246)',
        to: '/management/reversBookerByShop',
        category: 'Booker',
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome'),
          },
        ],
      },
      // {
      //   index: 2,
      //   title: 'Comptes Clients',
      //   icon: 'mdi-store',
      //   description:
      //     'Gestion des réservations et des bons cadeaux à reverser au boutique',
      //   // color: colors.pink.accent1,
      //   color: 'rgb(243, 244, 246)',
      //   to: '/management/customers',
      //   category: 'Clients',
      //   navBottom: [
      //     {
      //       to: '/',
      //       icon: 'mdi-home',
      //       tooltip: i18n.t('database.backToHome')
      //     }
      //   ]
      // }
    ],
    getBlockSortByCategory() {
      return this.values.reduce((all, curr) => {
        const already = all.findIndex(({ title }) => title === curr.category)
        if (already !== -1) {
          all[already].items.push(curr)
        } else {
          all.push({
            title: curr.category,
            items: [curr],
          })
        }
        return all
      }, [])
    },
  }
}
