<template>
  <div>
    <edit-panel
      :display="newItemPopup"
      :title="newItem ? $t('blog.editBlogPost') : $t('blog.addBlogPost')"
      @actionModal="actionForm"
      @close="newItemPopup = false"
    >
      <template v-slot:body>
        <add-blog-post ref="itemForm" :post="newItem" />
      </template>
    </edit-panel>

    <blog-posts
      :includeAddAction="true"
      :addActionCallback="addNewItem"
      :editItemCallback="editItem"
      :deleteItemCallback="deleteItem"
    />
  </div>
</template>

<script>
import layoutHome from '../../../layouts/layout-home.vue'
import BlogPosts from '@/components/database/blog/blogPosts.vue'
import AddBlogPost from '@/components/database/blog/addBlogPost.vue'
import EditPanel from '@/components/helpers/edit-panel'
import swal from 'sweetalert'
import { controli18n } from '../../../helpers/langs'

export default {
  components: {
    EditPanel,
    BlogPosts,
    AddBlogPost
  },
  data() {
    return {
      newItemPopup: false,
      newItem: null
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  methods: {
    actionForm(e) {
      if (e) {
        this.handleForm()
      } else {
        this.closeForm()
      }
    },
    async handleForm() {
      if (await this.$refs.itemForm.handleForm()) {
        this.closeForm()
      }
    },
    closeForm() {
      this.newItemPopup = false
      this.newItem = null
    },
    addNewItem() {
      this.newItemPopup = true
      this.newItem = null
    },
    editItem(item) {
      this.newItemPopup = true
      this.newItem = JSON.parse(JSON.stringify(item))
    },
    async deleteItem(item) {
      swal({
        title: this.$t('articles.areYouSureDeleteArticle', {
          article: controli18n(this.$i18n.locale, item.title)
        }),
        buttons: [this.$t('icons.cancel'), this.$t('icons.delete')]
      }).then(async willDelete => {
        if (willDelete) {
          try {
            await this.$store.dispatch('blog/deleteBlogPost', {
              post: item
            })
          } catch (err) {
            console.log('err :>> ', err)
          }
        }
      })
    }
  }
}
</script>

<style></style>
