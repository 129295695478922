// import colors from 'vuetify/lib/util/colors'
import i18n from '../i18n'

export default function() {
  return {
    values: [
      {
        index: 0,
        title: i18n.t('booker.transactions'),
        icon: 'mdi-store',
        description: i18n.t('booker.listOfTransactions'),
        // color: colors.pink.accent1,
        color: 'rgb(243, 244, 246)',
        to: '/booker/transactions',
        category: i18n.t('settings.onlineBooking.payments'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ]
      },
      // {
      //   index: 23,
      //   title: 'Clients',
      //   icon: 'mdi-account',
      //   description: 'Liste des clients',
      //   // color: colors.pink.accent1,
      //   color: 'rgb(243, 244, 246)',
      //   to: '/booker/customers',
      //   category: 'Clients',
      //   navBottom: [
      //     {
      //       to: '/',
      //       icon: 'mdi-home',
      //       tooltip: i18n.t('database.backToHome')
      //     }
      //   ],
      // },
      {
        index: 10,
        title: 'Maintenance',
        icon: 'mdi-cog',
        description: 'Maintenance',
        color: 'rgb(243, 244, 246)',
        to: '/booker/maintenance',
        category: i18n.t('settings.onlineBooking.payments'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        secret: true
      },
      {
        index: 10,
        title: i18n.t('booker.failedTransactions'),
        icon: 'mdi-store',
        description: i18n.t('booker.listTransactionsWithError'),
        // color: colors.pink.accent1,
        color: 'rgb(243, 244, 246)',
        to: '/booker/fails-transactions',
        category: i18n.t('settings.onlineBooking.payments'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        secret: true
      },
      {
        index: 20,
        title: i18n.t('booker.carts'),
        icon: 'mdi-store',
        description: i18n.t('booker.cartsDescription'),
        // color: colors.pink.accent1,
        color: 'rgb(243, 244, 246)',
        to: '/booker/carts',
        category: i18n.t('settings.onlineBooking.payments'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        secret: true
      },
      {
        index: 20,
        title: 'Stripe Checker',
        icon: 'mdi-store',
        description: 'Check transactions Stripe',
        // color: colors.pink.accent1,
        color: 'rgb(243, 244, 246)',
        to: '/booker/stripe',
        category: i18n.t('settings.onlineBooking.payments'),
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: i18n.t('database.backToHome')
          }
        ],
        secret: true
      }
      // {
      //   index: 1,
      //   title: i18n.t('settings.menu.gift'),
      //   icon: 'mdi-store',
      //   description: i18n.t('booker.listGift'),
      //   // color: colors.pink.accent1,
      //   color: 'rgb(250, 209, 249)',
      //   to: '/booker/gifts',
      //   category: i18n.t('settings.onlineBooking.payments'),
      //   navBottom: [
      //     {
      //       to: '/',
      //       icon: 'mdi-home',
      //       tooltip: i18n.t('database.backToHome')
      //     }
      //   ]
      // }
      // {
      //   index: 2,
      //   title: i18n.t('booker.schedulesDiscounts'),
      //   icon: 'mdi-store',
      //   description: i18n.t('booker.managementDiscountsByDateServicesShop'),
      //   // color: colors.pink.accent1,
      //   color: 'rgb(250, 209, 249)',
      //   to: '/booker/remises',
      //   category: i18n.t('booker.commercialOperations'),
      //   navBottom: [
      //     {
      //       to: '/',
      //       icon: 'mdi-home',
      //       tooltip: i18n.t('database.backToHome'),
      //     },
      //   ],
      // },
      /* {
        title: 'Paramétrages',
        icon: 'mdi-cog-box',
        description: `Paramétrages de la compagnie`,
        color: colors.grey.accent1,
        to: '/database/parametrages',
        navBottom: [
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: `retour à l accueil`,
          },
        ],
      }, */
    ],
    getBlocks() {
      return this.values
    },
    getBlockByTo(val) {
      const blk = this.values.filter(block => block.to === val)
      return blk ? blk[0] : null
    },
    getBlockSortByCategory() {
      return this.values.reduce((all, curr) => {
        const already = all.findIndex(({ title }) => title === curr.category)
        if (already !== -1) {
          all[already].items.push(curr)
        } else {
          all.push({
            title: curr.category,
            items: [curr]
          })
        }
        return all
      }, [])
    }
  }
}
