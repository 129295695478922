<template>
  <v-container>
    <h1>Modifier un compte</h1>
    <v-divider class="mb-6" />

    <div v-if="$store.state.auth.user.role === 'cmpadmin'">
      <v-alert type="error" v-if="error">
        {{ error }}
      </v-alert>
      <UserForm :from-manager="true" :current-auth="user" v-if="user && !error" />
      <p v-if="!user && !error">
        Chargement...
      </p>
    </div>
    <div v-else>
      <p>Accès refusé</p>
    </div>
  </v-container>
</template>

<script>
import UserForm from '../../components/users/form.vue'
import layoutHome from '../../layouts/layout-home'
import {MKPSDK} from "@/packages/artebeaute-sdk/src";

export default {
  name: 'Home',
  components: {
    UserForm
  },
  data: () => {
    return {
      error: null,
      user: null,
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {
    try {
      const response = await MKPSDK.CmpUsers.getAllCmpUsers()

      this.user = response.users.find((u) => u.id === parseInt(this.$route.params.userId))

      if (!this.user) {
        this.error = 'Impossible de récupérer le compte'
      }
    } catch (err) {
      this.error = 'Impossible de récupérer le compte'
    }
  },
  methods: {}
}
</script>
