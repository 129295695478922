<template>
  <div>
    <v-sheet color="grey lighten-3" class="mx-n3 px-3 pa-4">
      <v-btn small @click="openInputModal"> Entrer un Order ID </v-btn>
      <v-btn small @click="openTransactionIdModal">
        Rembourser un transactionID
      </v-btn>
    </v-sheet>
    <v-data-table :headers="headers" :items="transactions">
      <template v-slot:item.customer="{ item }">
        <strong>{{ item.customer.lastname }}</strong>
        {{ item.customer.firstname }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ $t('booker.the') }}
        {{ item.bookingOrder.details.appointment.date.substring(6, 8) }} /
        {{ item.bookingOrder.details.appointment.date.substring(4, 6) }} /
        {{ item.bookingOrder.details.appointment.date.substring(0, 4) }}
        {{ $t('booker.at') }}
        {{ item.bookingOrder.details.appointment.appointment[0].start }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn type="button" @click="resolve(item)">
          {{ $t('booker.resolve') }}
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog :value="inputModal" persistent max-width="400">
      <v-card v-if="!booking">
        <v-card-title> Entrer un Order ID </v-card-title>
        <v-card-text>
          <v-alert type="error" v-if="orderError">
            {{ orderError }}
          </v-alert>
          <v-text-field
            v-model="orderID"
            outlined
            dense
            hide-details
            label="Order ID"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeInputModal" text> Fermer </v-btn>
          <v-spacer />
          <v-btn @click="searchOrderID" color="primary"> Rechercher </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="booking">
        <v-card-title> Corriger une réservation </v-card-title>
        <v-card-text>
          <v-alert type="error" v-if="orderError">
            {{ orderError }}
          </v-alert>
          <v-alert
            type="info"
            v-if="booking.transactionMKP.status !== 'waiting'"
          >
            Cette réservation est complète.
          </v-alert>
          <v-alert
            type="warning"
            v-if="booking.transactionMKP.status === 'waiting'"
          >
            Cette réservation est incomplète.
          </v-alert>
          <v-row dense>
            <v-col cols="4">
              <strong>ID Order</strong>
            </v-col>
            <v-col cols="8">
              {{ orderID }}
            </v-col>
            <v-col cols="4">
              <strong>Montant</strong>
            </v-col>
            <v-col cols="8">
              {{
                currency(booking.mAmount, {
                  precision: 2,
                  pattern: `# !`,
                  symbol: '€',
                }).format()
              }}
            </v-col>
            <v-col cols="4">
              <strong>Payé</strong>
            </v-col>
            <v-col cols="8">
              {{
                currency(booking.mPaid, {
                  precision: 2,
                  pattern: `# !`,
                  symbol: '€',
                }).format()
              }}
            </v-col>
            <v-col cols="4">
              <strong>Date</strong>
            </v-col>
            <v-col cols="8">
              {{ convertDate(booking.m_orderDetails.date) }}
            </v-col>
            <v-col cols="4">
              <strong>Client</strong>
            </v-col>
            <v-col cols="8">
              {{ booking.customerLastname }}
              {{ booking.customerFirstname }}
            </v-col>
            <v-col cols="4">
              <strong>Téléphone</strong>
            </v-col>
            <v-col cols="8">
              {{ booking.customerPhone }}
            </v-col>
            <v-col cols="4">
              <strong>Email</strong>
            </v-col>
            <v-col cols="8">
              {{ booking.customerEmail }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="backInputModal" text> Retour </v-btn>
          <v-spacer />
          <v-btn
            @click="repairBooking"
            color="primary"
            :disabled="booking.transactionMKP.status !== 'waiting'"
          >
            Corriger
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :value="transactionIdModal" persistent max-width="400">
      <v-card v-if="!transactionValue">
        <v-card-title> Entrer une Transaction ID </v-card-title>
        <v-card-text>
          <v-alert type="error" v-if="orderError">
            {{ orderError }}
          </v-alert>
          <v-text-field
            v-model="transactionIdValue"
            outlined
            dense
            hide-details
            label="Transaction ID"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeInputModal" text> Fermer </v-btn>
          <v-spacer />
          <v-btn @click="searchTransactionID" color="primary">
            Rechercher
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="transactionValue">
        <v-card-title> {{ transactionValue.transactionId }} </v-card-title>
        <v-card-text>
          <v-alert type="error" v-if="orderError">
            {{ orderError }}
          </v-alert>
          Ceci remboursera la transaction et enverra un email.
          <v-text-field v-model="emailSubject" label="Sujet du mail" />
          <v-textarea v-model="emailToSend" label="Email à envoyer" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeInputModal" text> ANNULER </v-btn>
          <v-spacer />
          <v-btn @click="refundAndMailTransactionID" color="primary">
            Rembourser + Email
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import currency from 'currency.js'
import { ArteBeauteSDK, MKPSDK, StatsSDK } from '@/packages/artebeaute-sdk/src'
import layoutHome from '../../layouts/layout-home.vue'

export default {
  components: {},
  data() {
    return {
      transactions: [],
      inputModal: false,
      orderID: '',
      orderError: '',
      booking: null,
      transactionIdModal: false,
      transactionIdValue: null,
      transactionValue: null,
      emailToSend: null,
    }
  },
  computed: {
    headers() {
      return [
        { value: 'id', text: 'ID' },
        { value: 'customer', text: this.$t('other.customer') },
        { value: 'date', text: this.$t('other.dateAppointment') },
        { value: 'actions', text: this.$t('database.tables.actions') },
      ]
    },
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {
    await this.fetchFails()
  },
  methods: {
    currency,
    openInputModal() {
      this.inputModal = true
      this.orderError = ''
    },
    openTransactionIdModal() {
      this.transactionIdModal = true
      this.transactionIdValue = null
      this.transactionValue = null
      this.orderError = ''
      this.emailSubject = 'Remboursement'
      this.emailToSend = `Bonjour,

Vous avez reçu un remboursement pour votre réservation.`
    },
    closeInputModal() {
      this.inputModal = false
      this.orderID = ''
      this.booking = null
      this.orderError = ''
      this.transactionIdModal = false
      this.transactionIdValue = null
      this.transactionValue = null
    },
    backInputModal() {
      this.orderID = ''
      this.booking = null
      this.orderError = ''
    },
    convertDate(dateStr) {
      const year = dateStr.substring(0, 4)
      const month = dateStr.substring(4, 6)
      const day = dateStr.substring(6, 8)

      return day + '/' + month + '/' + year
    },
    async searchTransactionID() {
      try {
        const responseTransaction = await MKPSDK.Payment.getTransaction(
          this.transactionIdValue
        )
        this.transactionValue = responseTransaction.transaction
      } catch (err) {
        console.log(err)
        this.orderError = "La transaction n'a pas été trouvée."
      }
    },
    async refundAndMailTransactionID() {
      let error = ''

      try {
        if (!confirm('Etes-vous sûr ?')) {
          return false
        }

        const response = await MKPSDK.Booker.refundAndMailBooking(
          this.transactionIdValue,
          this.emailSubject,
          this.emailToSend
        )

        if (response.error) {
          this.orderError = response.message
          error = response.message
          return false
        }

        alert('Success')
      } catch {
        this.orderError = error ? error : 'Une erreur est survenue.'
      }
    },
    async searchOrderID() {
      try {
        const response = await StatsSDK.StatBooking.orderById(this.orderID, '1')
        const responseTransaction = await MKPSDK.Payment.getTransaction(
          response.eorder.data.m_orderDetails.transactionUUID
        )
        this.booking = response.eorder.data
        this.booking.transactionMKP = responseTransaction.transaction
        this.orderError = ''
      } catch (err) {
        console.log(err)
        this.orderError = "La réservation n'a pas été trouvée."
      }
    },
    async repairBooking() {
      try {
        await MKPSDK.Booker.repairBooking(this.booking)
        // this.closeInputModal()

        const response = await StatsSDK.StatBooking.orderById(this.orderID, '1')
        const responseTransaction = await MKPSDK.Payment.getTransaction(
          response.eorder.data.m_orderDetails.transactionUUID
        )
        this.booking = response.eorder.data
        this.booking.transactionMKP = responseTransaction.transaction
        this.orderError = ''
      } catch {
        this.orderError = 'Une erreur est survenue.'
      }
    },
    async fetchFails() {
      const result = await ArteBeauteSDK.Transactions.getAllFails()
      this.transactions = result.transactions
    },
    async resolve(item) {
      try {
        const result = await ArteBeauteSDK.Transactions.resolveFails(
          item.id,
          item.bookingOrder.details
        )

        if (result.success) {
          alert('SUCCES')
          this.transactions = this.transactions.filter((t) => t.id !== item.id)
        } else {
          alert('ECHEC')
        }
      } catch (err) {
        alert('ECHEC')
        console.log(err)
      }
    },
  },
}
</script>

<style scoped></style>
