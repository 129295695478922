<template>
  <v-container>
    <div
      class="d-flex align-center justify-space-between flex-column flex-md-row"
    >
      <h1>Liste des comptes</h1>
      <div v-if="$store.state.auth.user.role === 'cmpadmin'">
        <v-btn color="primary" to="/users/add" class="my-2">
          <v-icon left>
            mdi-plus
          </v-icon>
          Ajouter un compte
        </v-btn>
      </div>
    </div>
    <v-divider class="mb-6" />

    <div v-if="$store.state.auth.user.role === 'cmpadmin'">
      <div v-if="error">
        <v-alert type="error">{{ error }}</v-alert>
      </div>
      <div v-else>
        <v-card tile>
          <v-list-item v-for="user in users" :key="user.id">
            <v-list-item-icon>
              <v-avatar color="primary" size="32">
                {{ user.firstname ? user.firstname[0] : ''
                }}{{ user.lastname ? user.lastname[0] : '' }}
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >{{ user.firstname }} {{ user.lastname }}</v-list-item-title
              >
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-btn
                icon
                @click="removeUser(user)"
                v-if="user.id !== $store.state.auth.user.id"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
              <v-btn icon :to="'/users/edit/' + user.id">
                <v-icon>
                  mdi-cog
                </v-icon>
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-card>
      </div>
    </div>
    <div v-else>
      <p>Accès refusé</p>
    </div>
  </v-container>
</template>

<script>
import LayoutHome from '../../layouts/layout-home.vue'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'

export default {
  name: 'Home',
  data: () => {
    return {
      error: null,
      users: []
    }
  },
  async mounted() {
    try {
      const response = await MKPSDK.CmpUsers.getAllCmpUsers()

      this.users = response.users
    } catch (err) {
      this.error = 'Impossible de récupérer les comptes'
    }
  },
  created() {
    this.$emit('update:layout', LayoutHome)
  },
  methods: {
    async removeUser(user) {
      if (!confirm('Etes-vous sûr ?')) {
        return
      }

      try {
        await MKPSDK.CmpUsers.deleteCmpUser(user.id)

        this.users = this.users.filter(u => u.id !== user.id)
      } catch (err) {
        this.error = 'Impossible de supprimer le compte'
      }
    }
  }
}
</script>
