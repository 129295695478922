<template>
  <div>
    <module-list-light>
      <template v-slot:actionBlock="{ isMobile }">
        <v-btn
          @click="addActionCallback"
          :color="isMobile ? 'dark' : 'white'"
          :dark="isMobile"
          large
          elevation="0"
          :class="isMobile ? 'py-6' : ''"
        >
          {{ $t('articles.addCategory') }}
        </v-btn>
      </template>
      <template v-slot:actionSearch>
        <v-text-field
          :style="{ backgroundColor: '#fff', minWidth: '150px' }"
          dense
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('other.research')"
          single-line
          hide-details
          outlined
          class="ml-3"
        ></v-text-field>
      </template>
      <template v-slot:contentBlock>
        <v-data-table
          class="table-one elevation-1"
          fixed-header
          :search="search"
          :headers="headers"
          :items="postCategories"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :hide-default-footer="$vuetify.breakpoint.mdAndUp"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.nb_posts`]="{ item }">
            {{ item.posts ? item.posts.length : 0 }}
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span v-if="item.name && controli18n($i18n.locale, item.name)">
              {{ controli18n($i18n.locale, item.name) }}
            </span>
            <span v-else> {{ $t('blog.noName') }} </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <icon-display
              @actionClick="editItemCallback(item)"
              :small="false"
              :icon="$icones.edit"
            />
            <icon-display
              @actionClick="deleteItemCallback(item)"
              :small="false"
              :icon="$icones.delete"
            />
          </template>
        </v-data-table>
      </template>
    </module-list-light>
  </div>
</template>

<script>
import IconDisplay from '@/components/helpers/iconDisplay.vue'
import ModuleListLight from '@/components/general/moduleListLight.vue'
import icones from '../../../helpers/icones'
import { controli18n } from '../../../helpers/langs'

export default {
  components: {
    IconDisplay,
    ModuleListLight
  },
  props: {
    includeAddAction: {
      type: Boolean,
      default: true
    },
    addActionCallback: {
      type: Function,
      default: null
    },
    editItemCallback: {
      type: Function,
      default: null
    },
    deleteItemCallback: {
      type: Function,
      default: null
    }
  },
  computed: {
    postCategories() {
      return this.$store.getters['blog/getPostCategories']
    },
    headers() {
      return [
        {
          text: this.$t('other.name'),
          value: 'name',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true
        },
        {
          text: this.$t('blog.articleNumber'),
          value: 'nb_posts',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true
        },
        {
          text: this.$t('database.tables.actions'),
          value: 'actions',
          width: 150,
          divider: false,
          sortable: false
        }
      ]
    },
    $icones() {
      return icones().getIcones()
    }
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      search: ''
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('blog/fetchPostCategories')
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    controli18n
  }
}
</script>

<style></style>
