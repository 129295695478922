<template>
  <div>
    <v-container class="py-8" v-if="false">
      <MultipleCouponForm />
    </v-container>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import MultipleCouponForm from '@/components/coupons/MultipleCouponForm'

export default {
  components: {
    MultipleCouponForm,
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
}
</script>

<style scoped></style>
