<template>
  <div>
    <div class="d-flex">
      <v-btn @click="startSearch" :disabled="loading">
        Lancer la recherche
      </v-btn>
    </div>
    <div class="d-flex">
      <!--      <v-btn @click="prevPage" :disabled="curPage === 0 || loading">-->
      <!--        Précédent-->
      <!--      </v-btn>-->
      <v-text-field
        v-model="transactionID"
        outlined
        type="text"
        hide-details
        dense
      />
      <v-btn @click="resolveTransactionID" :disabled="loading">
        Résoudre Transaction
      </v-btn>
    </div>
    <div class="d-flex">
      <!--      <v-btn @click="prevPage" :disabled="curPage === 0 || loading">-->
      <!--        Précédent-->
      <!--      </v-btn>-->
      <v-text-field v-model="selDate" outlined type="date" hide-details dense />
      <v-btn @click="resolveAll" :disabled="loading">
        Résoudre
      </v-btn>
    </div>
    <div class="d-flex">
      <v-text-field
        v-model="testMail"
        outlined
        type="email"
        hide-details
        dense
        label="Email Test"
      />
      <v-text-field
        v-model="testPhone"
        outlined
        hide-details
        dense
        label="Tel Test"
      />
      <v-btn @click="testResolve" :disabled="loading">
        TESTER
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="failed(transactions)"
      :loading="loading"
      :items-per-page="-1"
      hide-default-footer
    >
      <template #[`item.details.amount.finalPriceTTC`]="{ item }">
        {{
          currency(parseInt(item.details.amount.finalPriceTTC) / 100, {
            precision: 2,
            pattern: `# !`,
            symbol: '€'
          }).format()
        }}
      </template>
      <template #[`item.s_price`]="{ item }">
        {{
          currency(parseInt(item.stripeIntent.amount) / 100, {
            precision: 2,
            pattern: `# !`,
            symbol: '€'
          }).format()
        }}
      </template>
      <template #[`item.s_status`]="{ item }">
        {{ item.stripeIntent.status }}
      </template>
      <template #[`item.c_actions`]="{ item }">
        <v-btn @click="resolveSingle(item)" :disabled="loading">
          Résoudre
        </v-btn>
      </template>
    </v-data-table>
    <div>
      <strong>Total {{ failed(transactions).length }}</strong>
    </div>
  </div>
</template>

<script>
import currency from 'currency.js'
import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
import layoutHome from '../../layouts/layout-home.vue'

export default {
  components: {},
  data() {
    return {
      transactions: [],
      intents: [],
      loading: false,
      pages: [],
      curPage: 0,
      selDate: '2021-09-01',
      testMail: '',
      testPhone: '',
      transactionID: ''
    }
  },
  computed: {
    headers() {
      return [
        { value: 'transactionId', text: 'ID' },
        {
          value: 'details.amount.finalPriceTTC',
          text: 'Prix payé (transaction)'
        },
        { value: 's_price', text: 'Prix payé (stripe)' },
        { value: 'status', text: 'Status (transaction)' },
        { value: 's_status', text: 'Status (stripe)' },
        { value: 'c_actions', text: 'Actions' }
      ]
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {},
  watch: {
    selDate() {
      this.transactions = []
      this.fetchStripe()
    }
  },
  methods: {
    currency,
    async startSearch() {
      this.pages.push(null)
      this.curPage = 0
      await this.fetchStripe()
    },
    async nextPage() {
      this.pages.push(this.intents[this.intents.length - 1].id)
      this.curPage++
      await this.fetchStripe()
    },
    async prevPage() {
      this.curPage--
      await this.fetchStripe()
    },
    async fetchStripe(page = 1) {
      this.curPage = page
      this.loading = true
      const result = await ArteBeauteSDK.Transactions.getAllStripe({
        page
      })

      this.transactions = [...this.transactions, ...result.transactions]

      console.log(this.transactions)

      if (
        this.failed(this.transactions).length < 50 &&
        this.transactions.length &&
        new Date(this.transactions[this.transactions.length - 1].createdAt) >
          new Date(this.selDate) &&
        page < 50
      ) {
        await this.fetchStripe(page + 1)
      }

      this.loading = false
    },
    async testResolve() {
      if (!confirm("Valider l'envois TEST ?")) {
        return
      }

      this.loading = true
      try {
        const resp = await ArteBeauteSDK.Transactions.fixStripe(
          this.failed(this.transactions),
          true,
          this.testMail,
          this.testPhone
        )
        console.log('success', resp)
        this.transactions = []
        this.fetchStripe()
      } catch (err) {
        console.log(err)
        alert('error')
      }
      this.loading = false
    },
    async resolveAll() {
      if (!confirm("Valider l'envois REEL ?")) {
        return
      }

      this.loading = true
      try {
        const resp = await ArteBeauteSDK.Transactions.fixStripe(
          this.failed(this.transactions)
        )
        console.log('success', resp)
        this.transactions = []
        this.fetchStripe(this.curPage)
      } catch (err) {
        console.log(err)
        alert('error')
      }
      this.loading = false
    },
    async resolveSingle(item) {
      if (!confirm("Valider l'envois SINGLE ?")) {
        return
      }

      this.loading = true
      try {
        const resp = await ArteBeauteSDK.Transactions.fixStripe([item])
        console.log('success', resp)
        this.transactions = this.transactions.filter(t => t.id !== item.id)
      } catch (err) {
        console.log(err)
        alert('error')
      }
      this.loading = false
    },
    async resolveTransactionID() {
      if (!confirm("Valider l'envois TRANSACTION ?")) {
        return
      }

      this.loading = true
      try {
        const resp = await ArteBeauteSDK.Transactions.fixStripe([
          this.transactionID
        ])
        console.log('success', resp)
        this.transactions = this.transactions.filter(
          t => t.this.transactionId !== this.transactionID
        )
      } catch (err) {
        console.log(err)
        alert('error')
      }
      this.loading = false
    },
    getPI(transaction) {
      return this.intents.find(pi => pi.id === transaction.transactionId)
    },
    failed(transactions) {
      return transactions
        .filter(t => t.status === 'fail')
        .filter(t => !!t.stripeIntent && t.stripeIntent.status === 'succeeded')
    }
  }
}
</script>

<style scoped></style>
