<template>
  <div
    :style="fullwidth ? 'width: 100%' : 'max-width: 1200px'"
    class="white mx-auto"
  >
    <div class="actionBlock">
      <slot name="actionBlock" />
    </div>
    <div class="actionSearch">
      <slot name="actionSearch" />
    </div>
    <div class="content">
      <div class="contentBlock"><slot name="contentBlock" /></div>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="footerBlock">
      <slot name="footerBlock" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'moduleList',
  props: {
    fullwidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.actionBlock {
  padding: 15px;
  background-color: white;
  width: 100%;

  top: 80px;
}
.actionSearch {
  padding-right: 15px;
  padding-left: 15px;
}
.blockRight {
  width: 80px;
}

/* for desktop */
.content::after {
  content: '';
  clear: both;
  display: grid;
}
.contentBlock {
  width: 100%;
  padding: 15px;
}

.footerBlock {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0;
  position: fixed;
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 5;
  left: 0;
  height: 80px;
  border-top: 1px solid rgb(197, 197, 197);
}
</style>
