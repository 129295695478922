<template>
  <div v-if="compEdit" class="ma-0">
    <div>
      <images-selector v-model="compEdit.img" single :size="[750, 300]" />
    </div>

    <v-row dense class="my-10">
      <v-col cols="12" class="d-flex align-center justify-center mb-2">
        <v-switch
          v-model="compEdit.showDates"
          :label="$t('communications.events.showDates')"
        />
      </v-col>

      <v-col cols="12" md="6" class="text-center">
        <div class="text-left editTitle mx-5">{{ $t('blog.startDate') }}</div>
        <v-date-picker v-model="compEdit.start" class="elevation-2" />
      </v-col>
      <v-col cols="12" md="6" class="text-center">
        <div class="text-left editTitle mx-5">{{ $t('blog.endDate') }}</div>

        <v-date-picker v-model="compEdit.end" class="elevation-2" />
      </v-col>
    </v-row>

    <div class="my-10">
      <div class="editTitle">
        {{ $t('settings.emails.contents') }}
      </div>
      <multilang-editor v-model="compEdit.body" height="300px" />
    </div>

    <div>
      <div class="editTitle">
        {{ $t('communications.events.redirectsTo') }}
      </div>
      <div
        class="d-flex align-center justify-space-between flex-column flex-md-row align-stretch"
      >
        <multilang-text-field
          v-model="compEdit.link.title"
          outlined
          :label="$t('blog.title')"
          class="mx-2 my-2"
        />
        <v-text-field
          v-model="compEdit.link.url"
          outlined
          hide-details
          label="Url"
          class="mx-2 my-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImagesSelector from '../helpers/imagesSelector'
import MultilangEditor from '../helpers/multilangEditor'
import MultilangTextField from '../helpers/multilangTextField'
export default {
  name: 'editEvents',
  components: { MultilangTextField, MultilangEditor, ImagesSelector },
  props: {
    edit: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    this.compEdit = JSON.parse(JSON.stringify(this.edit))
  },
  data: () => ({
    compEdit: null
  }),
  watch: {
    compEdit: {
      handler(val) {
        this.$emit('updateEdit', val)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.editTitle {
  font-size: 1.2rem;
  font-weight: 800;
  border-bottom: 1px solid #333;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
</style>
