<template>
  <div>
    <v-alert v-if="error" type="error"> Une erreur est survenue. </v-alert>
    <div>
      <div>
        <div v-if="pathData === '/'">
          <h1 class="mb-3">Liste des boutiques</h1>
          <v-progress-linear
            indeterminate
            color="blue darken-2"
            v-if="isLoading"
          ></v-progress-linear>
          <v-row no-gutters v-show="!isLoading">
            <template v-for="(file, fileIndex) in files">
              <v-col cols="12" md="4" :key="fileIndex">
                <v-card class="ma-4" v-if="file.name" @click="clickLine(file)">
                  <v-card-title>
                    {{ file.name }}
                  </v-card-title>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </div>
        <div v-else>
          <h1 class="mb-3">Liste des fichiers</h1>
          <v-progress-linear
            indeterminate
            color="blue darken-2"
            v-if="isLoading"
          ></v-progress-linear>
          <v-row no-gutters v-show="!isLoading">
            <v-col cols="12" md="12">
              <v-card class="ma-4">
                <v-list flat>
                  <v-list-item-group>
                    <v-list-item
                      v-if="pathData !== '/'"
                      @click="previousDirectory"
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-arrow-left </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content> Retour </v-list-item-content>

                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        @click.stop="downloadCheckedFiles"
                        v-if="someElementsChecked"
                      >
                        Télécharger
                      </v-btn>
                    </v-list-item>
                    <v-list-item v-else>
                      <v-spacer></v-spacer>

                      <v-btn
                        color="primary"
                        @click.stop="downloadCheckedFiles"
                        :disabled="!someElementsChecked"
                      >
                        Télécharger
                      </v-btn>
                    </v-list-item>
                    <v-list-item
                      v-for="(file, fileIndex) in files"
                      :key="fileIndex"
                    >
                      <template>
                        <v-list-item-icon>
                          <v-icon v-if="file.isDirectory" color="primary">
                            mdi-folder
                          </v-icon>
                          <v-icon v-else>mdi-file-document</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content @click="clickLine(file)">
                          <v-list-item-title
                            v-text="file.name"
                          ></v-list-item-title>
                          <br />
                          <span v-if="!file.isDirectory" class="file-info">
                            {{ getFormattedDate(file.lastModified) }}
                          </span>
                        </v-list-item-content>
                        <v-spacer v-if="!file.isDirectory"></v-spacer>
                        <v-checkbox
                          v-if="!file.isDirectory"
                          v-model="checked[file.name]"
                        ></v-checkbox>
                      </template>
                    </v-list-item>
                    <v-list-item v-if="!files.length">
                      <v-list-item-content>
                        <v-list-item-title>Aucun fichier</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

import { saveAs } from 'file-saver'
import JSZip from 'jszip'

export default {
  name: 'FileExplorer',
  props: {
    path: {
      type: String,
      default: '/'
    }
  },
  data() {
    return {
      error: false,
      files: [],
      pathData: this.path,
      previousPaths: [],
      checked: {},
      isLoading: false
    }
  },
  computed: {
    someElementsChecked() {
      let checked = false

      Object.keys(this.checked).forEach(key => {
        if (this.checked[key]) {
          checked = true
        }
      })

      return checked
    },
    shops() {
      return this.$store.state.shop.shops
    }
  },
  watch: {
    async pathData() {
      try {
        this.isLoading = true
        await this.fetchFiles()
        this.checked = {}
        this.isLoading = false
      } catch (err) {
        this.error = true
      }
    }
  },
  async mounted() {
    try {
      this.isLoading = true
      await this.fetchFiles()
      this.isLoading = false
    } catch (err) {
      this.error = true
    }
  },
  methods: {
    getShopNameById(id) {
      const shop = this.shops.find(s => s.id === id)

      if (!shop) {
        return false
      }

      return shop.publicInfo.name
    },
    async downloadCheckedFiles() {
      const zip = new JSZip()

      for (let i = 0; i < Object.keys(this.checked).length; i++) {
        const key = Object.keys(this.checked)[i]

        if (this.checked[key]) {
          await axios({
            method: 'get',
            url: this.getFilePath({ name: key }),
            responseType: 'arraybuffer'
          })
            .then(response => {
              zip.file(key, response.data)
            })
            .catch(() => console.log('error occured'))
        }
      }

      await zip.generateAsync({ type: 'blob' }).then(blob => {
        saveAs(blob, 'download.zip')
      })

      this.checked = {}
    },
    getFormattedDate(date) {
      return moment(date)
        .locale('fr')
        .format('dddd Do MMMM  YYYY à hh:mm:ss')
    },
    clickLine(file) {
      if (file.isDirectory) {
        this.openDirectory(file)
      } else {
        this.downloadFile(file)
      }
    },
    async fetchFiles() {
      try {
        const response = await axios.get(
          process.env.VUE_APP_VAULT_SERVICE_API +
            '/api/uploads?dir=' +
            this.pathData
        )
        this.files = response.data
      } catch (err) {
        this.error = true

        if (this.pathData !== '/') {
          this.previousDirectory()
        }
      }
    },
    previousDirectory() {
      this.pathData = this.previousPaths[this.previousPaths.length - 1]
      this.previousPaths.pop()
    },
    async openDirectory(directory) {
      this.previousPaths.push(this.pathData)
      this.pathData = directory.target
    },
    async downloadFile(file) {
      axios({
        method: 'get',
        url: this.getFilePath(file),
        responseType: 'arraybuffer'
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')

          link.href = url
          link.setAttribute('download', file.name)

          document.body.appendChild(link)
          link.click()
        })
        .catch(() => console.log('error occured'))
    },
    getFilePath(file) {
      return (
        process.env.VUE_APP_VAULT_SERVICE_API +
        '/api/uploads/download?file=' +
        this.pathData +
        (this.pathData.endsWith('/') ? '' : '/') +
        file.name
      )
    }
  }
}
</script>

<style scoped>
.file-info {
  font-size: 0.75rem;
  color: #999999;
}

.back {
  background: #ccc;
  color: #333;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.back a {
  color: #333;
  text-decoration: none;
}

.back .fas {
  margin-right: 10px;
}

.files {
  list-style: none;
  padding: 0;
  margin: 0;
}

.files .file {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.files .file .file-left {
  display: flex;
  align-items: center;
}

.files .file .file-icon {
  width: 25px;
}

.files .file .file-icon .fa-folder {
  color: steelblue;
}

.files .file .file-icon .fa-file {
  color: coral;
}

.files .file .file-info {
  color: #999;
  font-size: 0.75rem;
}

.files .file a {
  color: #333;
}
</style>
