<template>
  <div>
    <v-container class="py-8">
      <CouponForm />
    </v-container>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import CouponForm from '@/components/coupons/CouponForm'

export default {
  components: { CouponForm },
  created() {
    this.$emit('update:layout', layoutHome)
  },
}
</script>

<style scoped></style>
