<template>
  <v-card v-if="myDeposit">
    <v-card-title
      >Acompte pour la boutique {{ myDeposit.m_shopName }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer />
        <v-col cols="10">
          <v-sheet outlined rounded>
            <div style="padding: 5px; text-align: center">
              <label>Montant Maximal</label>
              {{
                currency(montantMax + montantBefore, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !'
                }).format()
              }}
            </div>
          </v-sheet>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(100)">
            <div style="padding: 5px; text-align: center">100%</div>
          </v-sheet>
        </v-col>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(50)">
            <div style="padding: 5px; text-align: center">50%</div>
          </v-sheet>
        </v-col>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(25)">
            <div style="padding: 5px; text-align: center">25%</div>
          </v-sheet>
        </v-col>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(10)">
            <div style="padding: 5px; text-align: center">10%</div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="myDeposit.m_versement"
            type="number"
            :rules="[rulesAmount, rulesIsNumber]"
            label="Montant"
            required
            @keypress.enter="save"
            autofocus
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="myDeposit.m_createAt"
            type="date"
            required
            label="Versé le "
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text @click="cancel">Annuler</v-btn>
      <v-btn
        :disabled="!rulesAmount(myDeposit.m_versement)"
        text
        color="primary"
        @click="save()"
        >Valider</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import currency from 'currency.js'
export default {
  name: 'depositItem',
  props: {
    deposit: {
      type: Object,
      default: () => null
    },
    montantMax: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      myDeposit: null,
      montantBefore: 0,
      valid: false,
      rules: {
        isnumber: value => {
          const pattern =
            '(?:NaN|-?(?:(?:\\d+|\\d*\\.\\d+)(?:[E|e][+|-]?\\d+)?|Infinity))'
          return pattern.test(value) || 'invalid number'
        }
      }
    }
  },
  watch: {
    deposit: {
      handler(val) {
        this.myDeposit = val

        this.montantBefore = val.m_versement
      }
    }
  },
  mounted() {
    this.myDeposit = this.deposit
    this.montantBefore = this.myDeposit.m_versement
  },
  methods: {
    currency,
    rulesIsNumber(value) {

      if (!value) {
        return true
      }
      const pattern =
        '(?:NaN|-?(?:(?:\\d+|\\d*\\.\\d+)(?:[E|e][+|-]?\\d+)?|Infinity))'
      const regex = new RegExp(pattern)
      return regex.test(value)
    },
    rulesAmount(value) {
      if (!value) {
        return false
      }
      if (
        value >
        Math.round(this.montantMax * 100 + this.montantBefore * 100) / 100
      ) {
        return false
      }
      return true
    },
    autoDeposit(val) {
      if (val === 100) {
        this.myDeposit.m_versement = this.montantMax + this.montantBefore
      } else {
        /* this.myDeposit.m_versement = (
          ((Math.round(this.montantMax, 2) +
            Math.round(this.montantBefore, 2)) *
            val) /
          100
        ).toFixed(2)*/
      }
      const total = Math.round(this.montantMax * 100 + this.montantBefore * 100)
      this.myDeposit.m_versement = Math.round((total * val) / 100) / 100
      console.log(total, 'versements')
    },
    save() {
      if (!this.rulesAmount(this.myDeposit.m_versement)) {
        return false
      }
      this.$emit('valid', JSON.parse(JSON.stringify(this.myDeposit)))
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.prct {
  background-color: white;
}
.prct:hover {
  background-color: #cccccc;
  cursor: grab;
}
</style>
