<template>
  <div>
    <v-data-table :headers="headers" :items="carts">
      <template v-slot:item.customer="{ item }">
        {{ item.content.body.customer.firstname }}
        {{ item.content.body.customer.lastname.toUpperCase() }}
      </template>
<!--      <template v-slot:item.date="{ item }">-->
<!--        {{ $t('booker.the') }}-->
<!--        {{ item.content.body.appointment.date.substring(6, 8) }}/{{-->
<!--          item.content.body.appointment.date.substring(4, 6)-->
<!--        }}/{{ item.content.body.appointment.date.substring(0, 4) }}-->
<!--        {{ $t('booker.at') }}-->
<!--        {{ item.content.body.appointment.appointment[0].start }}-->
<!--      </template>-->
<!--      <template v-slot:item.price="{ item }">-->
<!--        {{ getFormatCurrency(item.content.body.appointment.payedAmount) }}-->
<!--      </template>-->
      <template v-slot:item.actions="{ item }">
        <v-btn type="button" @click="resolve(item)" text>
          {{ $t('booker.resolve') }}
        </v-btn>
      </template>
    </v-data-table>
    <v-overlay class="d-flex justify-center align-center" v-if="loading">
      <v-progress-circular indeterminate />
    </v-overlay>
  </div>
</template>

<script>
import currency from 'currency.js'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import layoutHome from '../../layouts/layout-home.vue'

export default {
  components: {},
  data() {
    return {
      carts: [],
      loading: false,
    }
  },
  computed: {
    headers() {
      return [
        { value: 'id', text: 'ID' },
        { value: 'customer', text: this.$t('other.customer') },
        { value: 'date', text: this.$t('other.dateAppointment') },
        { value: 'price', text: this.$t('other.price'), align: 'right' },
        {
          value: 'actions',
          text: this.$t('database.tables.actions'),
          align: 'right',
        },
      ]
    },
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {
    this.loading = true
    await this.fetchCarts()
    this.loading = false
  },
  methods: {
    currency,
    async fetchCarts() {
      const result = await MKPSDK.Cart.getAllFailedCarts()
      this.carts = result.carts
    },
    async resolve(item) {
      this.loading = true
      try {
        await MKPSDK.Cart.resolve(item.id)

        this.carts = this.carts.filter((c) => c.id !== item.id)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    getFormatCurrency(val) {
      return currency(val, {
        symbol: '€',
        decimal: ',',
        pattern: ' # !',
        negativePattern: '- # !',
      }).format()
    },
  },
}
</script>

<style scoped></style>
