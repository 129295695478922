<template>
<card class="elevation-1">
  <v-card-title  >
    <div   style="width: 100%;font-size: .8rem;font-weight: normal;margin-bottom: 2px" class="d-flex justify-space-between">
      <div class="mb-0 mr-4">
        <p class="card-title mb-0">{{ title }}</p>
      </div>
      <div v-if="buttonText">
        <v-btn x-small text>{{buttonText}}</v-btn>
      </div>
    </div>
    <div   style="width: 100%;font-size: 1rem" class="d-flex justify-space-between">
      <div class="mb-0 mr-4">
        <p class="card-title mb-0">{{ currency(amount, {
          symbol: '',
          separator: ' ',
          decimal: ',',
          pattern: '# !'
        }).format() }}</p>
        <span v-if="amountCompare >0" style="font-size: .6em">
        <p class="card-title mb-0">( {{ currency(amountCompare, {
          symbol: '',
          separator: ' ',
          decimal: ',',
          pattern: '# !'
        }).format() }} )</p>
        </span>
      </div>
      <div v-if="chargedCompare" class="mb-0 mr-4">

        <p v-if="amountCompare !== null" class="card-title mb-0">
          <span v-if="isGrow" >
            <v-icon color="green">mdi-arrow-up-thin-circle-outline</v-icon>
               <span style="font-size: .8em;color:green;padding-left: 5px">{{grow}} %</span>
          </span>
          <span v-else>
            <v-icon color="red">mdi-arrow-down-thin-circle-outline</v-icon>
            <span style="font-size: .8em;color:red;padding-left: 5px">{{grow}} %</span>
          </span>
        </p>
        <p v-else>...</p>
      </div>
      <div v-else-if="!chargedCompare">
        ...
        <v-progress-linear indeterminate>aqsd</v-progress-linear>
      </div>
    </div>
  </v-card-title>
  <v-card-text style="height: 210px; background-color: #f1f1f1;border-bottom: 1px #c3c3c3 solid;border-top: 1px #c3c3c3 solid">
    <div v-if="!charged && !chargedCompare" class="text-center justify-center " style="height: 100%;width: 100%;display: flex">
      <v-progress-circular class="align-self-center" indeterminate>Chargement</v-progress-circular>
    </div>
    <div v-else-if="amount!==null && amountCompare !== null">
      <apexcharts height="200px" :options="getOptions()" :series="[amount,amountCompare]"/>
    </div>
  </v-card-text>
  <v-card-actions>
    <div style="width: 100%">
      <div v-if="!dateSame"  class="d-flex  justify-end">
        <div  style="font-size: .6rem;margin-right: 5px">
          <v-icon small color="grey" style="padding: 5px">mdi-square</v-icon>
          <span>{{startCS}} </span>
          <span >
           - {{endCL}}
        </span>
        </div>
        <div style="font-size: .6rem;margin-right: 5px">
          <v-icon small color="blue" style="padding: 5px">mdi-square</v-icon>
          <span>{{startS}} </span>
          <span >
           - {{endL}}
        </span>
        </div>
      </div>
      <div v-else class="d-flex  justify-end">
        <div  style="font-size: .6rem;margin-right: 5px">
          <v-icon small color="grey" style="padding: 5px">mdi-square</v-icon>
          <span>{{startCS}} </span>

        </div>
        <div style="font-size: .6rem;margin-right: 5px">
          <v-icon small color="blue" style="padding: 5px">mdi-square</v-icon>
          <span>{{startL}} </span>
        </div>
      </div>
    </div>
  </v-card-actions>

</card>
</template>

<script>

import VueApexCharts from 'vue-apexcharts'
import currency from 'currency.js'
import dayjs from "dayjs";
import Card from "../base/card";
require('dayjs/locale/fr')
dayjs.locale('fr')
export default {
  name: "widgDashBoard",
  components: {Card, apexcharts: VueApexCharts},
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    amount:{
      type: Number,
      default: ()=> 0
    },
    amountCompare:{
      type: Number,
      default: ()=> 0
    },
    periode: {
      type: Object,
      default: () => {
        return {
          start: dayjs(),
          end: dayjs()
        }
      }
    },
    charged: {
      type: Boolean,
      default : true
    },
    chargedCompare: {
      type: Boolean,
      default : true
    },
    periodeCompare: {
      type: Object,
      default: () => {
        return {
          start: dayjs(),
          end: dayjs()
        }
      }
    },
  },
  methods:{
    currency,
    getOptions() {
      return {
        colors: ['#3496f0', '#9d9d9e'],
        chart: {
          type: 'donut',
        },
        legend: {
          show:false
        }
      }
    }
  },
computed: {
    dateSame() {
      return dayjs(this.periode.start).format('dd DD MM YY') ===  dayjs(this.periode.end).format('dd DD MM YY')
    },
    isGrow() {
      return this.amount > this.amountCompare
    },
    grow(){
      if(this.charged && this.chargedCompare){
        if(this.amountCompare !==0){
          return  (((this.amount - this.amountCompare) * 100 / (this.amountCompare *100)) *100).toFixed(2)
        }
        return ''
      }
      return ''
    },
    startS(){
      return dayjs(this.periode.start).locale('fr').format('dd DD MMM')
    },
  startL(){
    return dayjs(this.periode.start).locale('fr').format('dd DD MMM YYYY')
  },
   endL(){
    return dayjs(this.periode.end).locale('fr').format('dd DD MMM YYYY')
    },
    startCS(){
      return dayjs(this.periodeCompare.start).locale('fr').format('dd DD MMM')
    },
    endCS(){
    return dayjs(this.periodeCompare.end).locale('fr').format('dd DD MMM')
  }, endCL(){
    return dayjs(this.periodeCompare.end).locale('fr').format('dd DD MMM YYYY')
  }
}
}
</script>

<style scoped>

</style>