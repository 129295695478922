<template>
  <v-container fluid>
    <v-dialog width="50vw" :value="loadedStatus" persistent>
      <v-card>
        <v-card-title><h3>Chargement encours</h3></v-card-title>
        <v-card-text>
          <v-progress-linear
            color="blue-grey"
            height="25"
            :value="loadedPercent"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="stopSearch">Annuler le chargement</v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn v-if="!dialogFilter" @click="dialogFilter = true" icon
      ><v-icon>mdi-filter</v-icon></v-btn
    >
    <v-sheet tile v-if="dialogFilter" elevation="1" width="100%" height="300">
      <v-btn @click="dialogFilter = false" icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <v-btn @click="createJsonCsv">export csv</v-btn>
      <v-btn @click="getCustomersSearch">tester la requete search</v-btn
      ><v-btn @click="getCustomers">tester la requete</v-btn>
      <v-container>
        <v-row>
          <v-col cols="12" sm="3" md="2">
            période du
            <input class="ml-2" type="date" v-model="start" />
          </v-col>
          <v-col cols="12" sm="3" md="2">
            au
            <input v-model="end" class="ml-2" type="date" />
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet width="100%" height="100%">
      {{ testResponse }}
      percent {{ loadedPercent }} pages {{ loadedPages }} current pages
      {{ loadedCurrentPage }}
      <v-pagination
        @input="getCustomersSearch(page, start, end, false)"
        :length="nbpages"
        :total-visible="5"
        v-model="page"
      ></v-pagination>
      <v-data-table
        :items="items"
        :headers="headers"
        :loading="loading"
        :items-per-page="100"
        hide-default-footer
      ></v-data-table>
    </v-sheet>
  </v-container>
</template>

<script>
//import { ArteBeauteSDK } from "@/packages/artebeaute-sdk/src";
import { arrayToCSV, downloadFile } from '../../../helpers/util.js'
// import jsonexport from "jsonexport/dist";
// import { saveAs } from "file-saver";
const Moment = require('moment')

export default {
  name: 'StatCustomers',
  data: () => {
    return {
      start: Moment().format('YYYY-MM-DD'),
      end: Moment().format('YYYY-MM-DD'),
      totalItems: 0,
      page: 0,
      nbpages: 0,
      currentItem: [],
      items: [],
      dialogFilter: false,
      testResponse: null,
      loading: false,
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'firstName',
          value: 'firstname'
        }
      ]
    }
  },
  computed: {
    loadedStatus() {
      return this.$store.state.customers.loaded.status
    },
    loadedPages() {
      return this.$store.state.customers.loaded.nbPages
    },
    loadedCurrentPage() {
      return this.$store.state.customers.loaded.currentPage
    },
    loadedPercent() {
      return this.$store.state.customers.loaded.nbPages > 0
        ? Math.round(
            (this.$store.state.customers.loaded.currentPage /
              this.$store.state.customers.loaded.nbPages) *
              100
          )
        : 0
    }
  },
  mounted() {},
  methods: {
    createJsonCsv() {
      const customs = this.$store.state.customers.customers
      const csv = arrayToCSV({ data: customs })
      downloadFile('clients.csv', csv)
    },
    async stopSearch() {
      await this.$store.dispatch('customers/fetchStop')
    },
    getCustomers() {
      this.$store.dispatch('customers/fetchCustomersAll', null)
    },
    getCustomersSearch() {
      const filter = { filter: { start: null, end: null } }
      this.$store.dispatch('customers/fetchCustomersSearch', filter)
    }
  }
}
</script>

<style></style>
