<template>
  <div>
    <v-container class="py-8">
      <div class="text-center" v-if="!coupon">
        <v-progress-circular indeterminate class="text-center mx-auto my-8" />
      </div>
      <CouponForm :coupon="coupon" v-else />
    </v-container>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import CouponForm from '@/components/coupons/CouponForm'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import dayjs from 'dayjs'

export default {
  components: { CouponForm },
  data() {
    return {
      coupon: null,
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {
    const couponResponse = await MKPSDK.Coupons.getCoupon(this.$route.params.id)
    this.coupon = couponResponse.coupon
  },
  methods: {
    canEditCoupon(coupon) {
      const currentDay = dayjs()

      const couponStart = dayjs(coupon.couponStartDate)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
      const couponEnd = dayjs(coupon.couponEndDate)
        .hour(23)
        .minute(59)
        .second(59)
        .millisecond(0)

      if (currentDay.isBefore(couponStart) || currentDay.isAfter(couponEnd)) {
        return true
      }

      return false
    },
  },
}
</script>

<style scoped></style>
