<template>
  <div v-if="compEdit" class="ma-0">
    <v-row dense class="mb-10">
      <v-col cols="12" md="6" class="text-center">
        <div class="text-left editTitle mx-5">{{ $t('blog.startDate') }}</div>
        <v-date-picker v-model="compEdit.start" class="elevation-2" />
      </v-col>
      <v-col cols="12" md="6" class="text-center">
        <div class="text-left editTitle mx-5">{{ $t('blog.endDate') }}</div>

        <v-date-picker v-model="compEdit.end" class="elevation-2" />
      </v-col>
    </v-row>

    <div class="my-10">
      <div class="editTitle">
        {{ $t('settings.emails.contents') }}
      </div>
      <multilang-editor v-model="compEdit.body" height="300px" />
    </div>

    <div>
      <div class="editTitle">
        {{ $t('communications.alerts.redirectionOnServicePage') }}
      </div>
      <div class="mt-7">
        <v-select
          v-model="compEdit.serviceId"
          :items="onlineArticles"
          outlined
          :label="$t('settings.appearance.services')"
          item-value="id"
        >
          <template v-slot:selection="{ item }">
            {{ controli18n($i18n.locale, item.name) || item.artebeauteName }}
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-title>
              {{ controli18n($i18n.locale, item.name) || item.artebeauteName }}
            </v-list-item-title>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import MultilangEditor from '../helpers/multilangEditor'
import { controli18n } from '../../helpers/langs'
export default {
  name: 'editAlerts',
  components: { MultilangEditor },
  props: {
    edit: {
      type: Object,
      default: () => {}
    },
    onlineArticles: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.compEdit = JSON.parse(JSON.stringify(this.edit))
  },
  data: () => ({
    compEdit: null
  }),
  methods: {
    controli18n
  },
  watch: {
    compEdit: {
      handler(val) {
        this.$emit('updateEdit', val)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.editTitle {
  font-size: 1.2rem;
  font-weight: 800;
  border-bottom: 1px solid #333;
  margin-bottom: 15px;
  padding-bottom: 5px;
}
</style>
