<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
      style="z-index: 10"
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <v-alert type="error" v-if="errors.length">
      <ul>
        <li v-for="error in errors" :key="error">
          {{ error }}
        </li>
      </ul>
    </v-alert>

    <v-tabs v-model="tab" class="tab-edit-panel" slider-color="primary">
      <v-tab href="#tab-settings">
        <span>{{ $t('settings.emails.settings') }}</span>
      </v-tab>
      <v-tab href="#tab-content">
        <span>{{ $t('settings.emails.contents') }}</span>
      </v-tab>
      <v-tab href="#tab-marketing"><span>Marketing</span></v-tab>
      <v-tab href="#tab-publication">
        <span>{{ $t('blog.publication') }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-settings" class="form-edit-panel">
        <div>
          <images-selector v-model="banner" single :size="[1900, 300]" />
        </div>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="categoryId"
              :items="categoryItems"
              :placeholder="$t('blog.relatedCategory')"
              outlined
            >
              <template v-slot:append-item>
                <v-divider class="mb-2"></v-divider>
                <v-list-item @click="addCategoryDialog = true">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t('articles.addCategory') }}
                      <v-icon
                        style="border: 2px solid #777"
                        class="rounded-circle ml-5"
                      >
                        mdi-plus
                      </v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="lang"
              :items="langItems"
              :placeholder="$t('other.language')"
              outlined
            />
          </v-col>
        </v-row>
        <multilang-text-field
          v-model="title"
          :placeholder="$t('blog.titleOfArticle')"
        />

        <!--  ADD CATEGORY DIALOG  -->
        <v-dialog width="450" persistent v-model="addCategoryDialog">
          <action-modal
            @actionModal="actionForm"
            :title="$t('blog.addBlogCategory')"
          >
            <template v-slot:actionModalContent>
              <add-post-category ref="itemForm" />
            </template>
          </action-modal>
        </v-dialog>
      </v-tab-item>
      <v-tab-item value="tab-content" class="py-6 px-6">
        <multilang-editor
          v-model="body"
          :placeholder="$t('blog.articleContent')"
        />
      </v-tab-item>
      <v-tab-item value="tab-marketing" class="form-edit-panel">
        <v-select
          v-model="articleId"
          :items="articleItems"
          :placeholder="$t('blog.relatedArticle')"
          :clearable="true"
          outlined
        />
        <v-select
          v-model="position"
          :items="positionItems"
          :placeholder="$t('blog.positionArticle')"
          outlined
        />
        <v-select
          v-model="shopId"
          :items="shopItems"
          :placeholder="$t('blog.shopRelated')"
          v-if="position === 'shop'"
          outlined
        />
      </v-tab-item>
      <v-tab-item value="tab-publication" class="form-edit-panel">
        <v-switch :label="$t('blog.publishedArticle')" v-model="published" />
        <v-switch :label="$t('blog.featuredArticle')" v-model="vedette" />
        <div v-if="vedette">
          <v-row>
            <v-col cols="12" md="6">
              <div>
                <strong>{{ $t('blog.startDate') }}</strong>
              </div>
              <v-date-picker
                v-model="vedetteStart"
                class="mt-4"
              ></v-date-picker>
            </v-col>
            <v-col cols="12" md="6">
              <div>
                <strong>{{ $t('blog.endDate') }}</strong>
              </div>
              <v-date-picker v-model="vedetteEnd" class="mt-4"></v-date-picker>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import MultilangTextField from '@/components/helpers/multilangTextField.vue'
import MultilangEditor from '@/components/helpers/multilangEditor.vue'
import uploadsHelpers from '@/helpers/uploads'
import slugify from 'slugify'
import ImagesSelector from '@/components/helpers/imagesSelector.vue'
import { controli18n } from '../../../helpers/langs'
import ActionModal from '../../helpers/actionModal'
import AddPostCategory from './addPostCategory'

export default {
  components: {
    MultilangTextField,
    MultilangEditor,
    ImagesSelector,
    AddPostCategory,
    ActionModal,
  },
  props: {
    post: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errors: [],
      tab: null,
      editor: null,
      imageData: null,
      categoryId:
        this.post && this.post.category ? this.post.category.id : null,
      articleId:
        this.post && this.post.linkedArticle
          ? this.post.linkedArticle.id
          : null,
      shopId:
        this.post && this.post.linkedShop ? this.post.linkedShop.id : null,
      lang: this.post && this.post.lang ? this.post.lang[0] : null,
      title: this.post ? this.post.title : {},
      body: this.post ? this.post.body : {},
      published: this.post ? this.post.published : false,
      vedette:
        this.post && this.post.vedette ? this.post.vedette.active : false,
      vedetteStart:
        this.post && this.post.vedette ? this.post.vedette.start : false,
      vedetteEnd:
        this.post && this.post.vedette ? this.post.vedette.end : false,
      position: this.post ? this.post.position : 'all',
      banner: this.post ? this.post.banner : null,
      loading: false,
      addCategoryDialog: false,
      category: null,
    }
  },
  computed: {
    articles() {
      return this.$store.getters['articles/getArticles']
    },
    shops() {
      return this.$store.state.shop.shops
    },
    postCategories() {
      return this.$store.getters['blog/getPostCategories']
    },
    articleItems() {
      return this.articles.map((pc) => {
        return {
          value: pc.id,
          text: controli18n(this.$i18n.locale, pc.name),
        }
      })
    },
    shopItems() {
      return this.shops.map((pc) => {
        return {
          value: pc.id,
          text: pc.publicInfo.name,
        }
      })
    },
    categoryItems() {
      return this.postCategories.map((pc) => {
        return {
          value: pc.id,
          text: controli18n(this.$i18n.locale, pc.name),
        }
      })
    },
    langItems() {
      return [
        {
          id: 'all',
          name: this.$t('icons.all'),
        },
        {
          id: 'fr',
          name: 'Français',
        },
        {
          id: 'en',
          name: 'English',
        },
        {
          id: 'es',
          name: 'Español',
        },
        {
          id: 'it',
          name: 'Italiano',
        },
        {
          id: 'de',
          name: 'Deutsch',
        },
      ].map((pc) => {
        return {
          value: pc.id,
          text: pc.name,
        }
      })
    },
    positionItems() {
      return [
        {
          id: 'all',
          name: this.$t('blog.wholeSite'),
        },
        {
          id: 'home',
          name: this.$t('blog.homeOnly'),
        },
        {
          id: 'shop',
          name: this.$t('blog.shopOnly'),
        },
      ].map((pc) => {
        return {
          value: pc.id,
          text: pc.name,
        }
      })
    },

    nickname() {
      return this.$store.state.auth.company.nickname
    },
  },
  created() {
    this.banner = [this.banner]

    this.banner = this.banner.map((i) => {
      if (i) {
        return {
          url: i.src,
        }
      } else {
        return null
      }
    })
  },
  async mounted() {
    try {
      await this.$store.dispatch('blog/fetchPostCategories')
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    controli18n,
    checkForm() {
      this.errors = []

      let titleValid = false
      Object.keys(this.title).forEach((k) => {
        if (this.title[k]) {
          titleValid = true
        }
      })
      if (!titleValid) {
        this.errors.push(this.$t('blog.titleRequired'))
      }

      if (!this.categoryId) {
        this.errors.push(this.$t('blog.categoryRequired'))
      }

      if (this.position === 'shop' && !this.shopId) {
        this.errors.push(this.$t('blog.shopRequired'))
      }

      if (!this.position) {
        this.errors.push(this.$t('blog.positionRequired'))
      }

      if (!this.lang) {
        this.errors.push(this.$t('blog.languageRequired'))
      }

      let bodyValid = false
      Object.keys(this.body).forEach((k) => {
        if (this.body[k]) {
          bodyValid = true
        }
      })
      if (!bodyValid) {
        this.errors.push(this.$t('blog.contentRequired'))
      }

      if (this.vedette && this.vedette.active) {
        if (!this.start) {
          this.errors.push(this.$t('blog.featuredStartDateRequired'))
        }
        if (!this.end) {
          this.errors.push(this.$t('blog.featuredEndDateRequired'))
        }
      }

      return !this.errors.length
    },
    actionForm(e) {
      if (e) {
        this.handleFormCat()
      } else {
        this.addCategoryDialog = false
      }
    },

    async handleForm() {
      this.loading = true

      if (!this.checkForm()) {
        this.loading = false
        return
      }

      let imagesUrl = []

      try {
        for (let i = 0; i < this.banner.length; i++) {
          if (this.banner[i].new) {
            const image = this.banner[i].blob
            // const imageLazy = this.banner[i].blobLazy
            const fileName = uploadsHelpers.randomName()

            /*
            const result = await Promise.all([
              uploadsHelpers.uploadSingleBase64(image, 'admin/blog', fileName),
              uploadsHelpers.uploadSingleBase64(
                imageLazy,
                'admin/blog',
                fileName + '-lazy'
              )
            ])*/

            const imageUrl = await uploadsHelpers.uploadSingleBase64(
              image,
              'imagekit/eden/blog',
              fileName,
              this.nickname
            )

            imagesUrl.push({
              src: imageUrl,
              description: this.banner[i].description,
              alt: this.banner[i].alt,
            })
          } else {
            imagesUrl.push(this.banner[i])
          }
        }
      } catch (err) {
        console.log(err)
        return {
          error: true,
          message: this.$t('settings.anErrorOccuredImages'),
        }
      }

      try {
        if (this.post) {
          await this.$store.dispatch('blog/updateBlogPost', {
            post: this.post,
            data: {
              title: this.title,
              banner: imagesUrl.length
                ? {
                    src: imagesUrl[0].src,
                  }
                : {},
              categoryId: this.categoryId,
              articleId: this.articleId,
              shopId: this.shopId,
              lang: [this.lang],
              body: this.body,
              published: this.published,
              vedette: {
                active: this.vedette,
                start: this.vedetteStart,
                end: this.vedetteEnd,
              },
              position: this.position,
              slug: slugify(
                Date.now() + '-' + controli18n(this.$i18n.locale, this.title),
                {
                  strict: true,
                }
              ).toLowerCase(),
            },
          })
        } else {
          await this.$store.dispatch('blog/createBlogPost', {
            data: {
              title: this.title,
              banner: imagesUrl.length
                ? {
                    src: imagesUrl[0].src,
                  }
                : {},
              categoryId: this.categoryId,
              articleId: this.articleId,
              shopId: this.shopId,
              lang: [this.lang],
              body: this.body,
              published: this.published,
              vedette: {
                active: this.vedette,
                start: this.vedetteStart,
                end: this.vedetteEnd,
              },
              position: this.position,
              slug: slugify(
                Date.now() + '-' + controli18n(this.$i18n.locale, this.title)
              ).toLowerCase(),
            },
          })
        }

        this.loading = false
        return true
      } catch (err) {
        console.log('err :>> ', err)
      }

      this.loading = false
      return false
    },
    async handleFormCat() {
      if (await this.$refs.itemForm.handleForm()) {
        this.categoryId = this.postCategories[this.postCategories.length - 1].id
        this.addCategoryDialog = false
      }
    },
  },
}
</script>

<style scoped>
@import '../../../helpers/edit-panel.css';
</style>
