<template>
  <div>
    <h1>Clients</h1>
    <div class="d-flex align-center">
      <v-text-field v-model="options.email" label="Adresse email" hide-details outlined dense />
      <v-btn @click="fetchUsers">Rechercher</v-btn>
    </div>
    <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="totalUsers"
        :loading="loading"
        class="elevation-1"
    >
      <template v-slot:item.firstname="{ item }">
        {{ item.links[0].profile.firstname }}
      </template>
      <template v-slot:item.lastname="{ item }">
        {{ item.links[0].profile.lastname }}
      </template>
      <template v-slot:item.actions="{ }">
        <v-btn dark x-small>
          Supprimer
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import layoutHome from "@/layouts/layout-home";
import {MKPSDK} from "@/packages/artebeaute-sdk/src";

export default {
  data() {
    return {
      users: [],
      totalUsers: 0,
      loading: false,
      options: {},
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Prenom', value: 'firstname' },
        { text: 'Nom', value: 'lastname' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  mounted() {
    this.fetchUsers()
  },
  watch: {
    'options.page': {
      handler () {
        this.fetchUsers()
      },
    },
  },
  methods: {
    async fetchUsers () {
      this.loading = true

      // const { sortBy, sortDesc, page, itemsPerPage } = this.options

      let resp = await MKPSDK.Auth.getPaginatedUsers(this.options)
      const users = resp.users
      const total = resp.total

      this.users = users
      this.totalUsers = total
      this.loading = false
    },
  }
}
</script>

<style scoped>
</style>
