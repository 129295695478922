var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-cards"},_vm._l((_vm.values.filter(
      v =>
        v.items
          .filter(i => !i.secret || _vm.showSecret)
          .filter(i => _vm.haveAccess(i)).length
    )),function(category,index){return _c('div',{key:'category-' + index},[_c('div',{staticClass:"content-category-title"},[_c('span',{staticClass:"category-title"},[_vm._v(_vm._s(category.title))]),_c('span',{staticClass:"category-line"})]),_c('v-row',{staticClass:"my-6",attrs:{"align":"stretch"}},_vm._l((category.items
          .filter(i => !i.secret || _vm.showSecret)
          .filter(i => _vm.haveAccess(i))),function(card,index_card){return _c('v-col',{key:'card-' + index_card,attrs:{"cols":"12","md":"3"}},[_c('menu-card',{attrs:{"title":card.title,"icon":card.icon,"description":card.description,"color":card.color,"to":card.to,"commingSoon":card.commingSoon,"in-development":card.inDevelopment}})],1)}),1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }