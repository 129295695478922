export const eorderStatus = [
  { text: 'Tous', value: '-1' },
  { text: 'A venir ( non encaissé )', value: '8' },
  { text: 'En attente', value: '0' },
  { text: 'Arrivee', value: '1' },
  { text: 'Encaissé', value: '9' },
  { text: 'Retard', value: '2' },
  { text: 'Dépassé ( non encaissé )', value: '12' },
  { text: 'No Show', value: '4' },
  { text: 'Annulé sans préavis', value: '3' },
  { text: 'Crédité', value: '7' },
  { text: 'Demande de remboursement', value: '14' },
  { text: 'Demande de remboursement ( import eden )', value: '6' },
  { text: 'Remboursement auto', value: '11' },
  { text: 'Remboursé manuellement', value: '13' },
  { text: 'Remboursé', value: '15' },
  { text: 'Annulé', value: '16' }
]
