<template>
  <div>
    <v-card>
      <v-card-title>
        <v-btn
          text
          v-bind:class="{ periodeSel: pSel === 'today' }"
          @click="changeP('today')"
          >Journée en cours</v-btn
        >
        <v-btn
          text
          v-bind:class="{ periodeSel: pSel === 'yesterday' }"
          @click="changeP('yesterday')"
          >Journée précedente</v-btn
        >
        <v-btn
          text
          v-bind:class="{ periodeSel: pSel === 'month' }"
          @click="changeP('month')"
          >Mois en cours</v-btn
        >
        <v-btn
          v-if="currentPeriode"
          text
          v-bind:class="{ periodeSel: pSel === 'current' }"
          @click="changeP('current')"
          ><span>{{ currentPeriode }}</span></v-btn
        >
      </v-card-title>
      <v-card-text
        style="max-width: 800px; margin-left: auto; margin-right: auto"
      >
        <v-row dense>
          <v-col cols="4">
            <v-btn color="blue" text x-small v-if="pSel === 'today'">
              actualiser</v-btn
            >
            <span v-else>*</span>
          </v-col>
          <v-col cols="3" style="font-weight: bold; text-align: center"
            >Réservations</v-col
          >
          <v-col cols="2" style="font-weight: bold; text-align: center"
            >Bons Cadeaux</v-col
          >
          <v-col cols="3" style="font-weight: bold; text-align: center"
            >Total</v-col
          >
        </v-row>
        <v-row dense style="background-color: #eeeeee">
          <v-col
            cols="4"
            style="font-weight: bold; border-right: 1px solid grey"
            >Encaissé</v-col
          >
          <v-col
            cols="3"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px">
              {{
                currency(bookerTotalPaid, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}</span
            >
          </v-col>
          <v-col
            cols="2"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px">
              {{
                currency(giftTotalPaid, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </span></v-col
          >
          <v-col
            cols="3"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px">
              {{
                currency(giftTotalPaid + bookerTotalPaid, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}</span
            ></v-col
          >
        </v-row>
        <v-row dense>
          <v-col
            cols="4"
            style="font-weight: bold; border-right: 1px solid grey"
            >Remboursé</v-col
          >
          <v-col
            cols="3"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px">
              {{
                currency(bookerTotalRefund, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </span></v-col
          >
          <v-col
            cols="2"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px">
              {{
                currency(giftTotalRefund, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </span>
          </v-col>
          <v-col
            cols="3"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px">
              {{
                currency(giftTotalRefund + bookerTotalRefund, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </span>
          </v-col>
        </v-row>
        <v-row dense style="background-color: #eeeeee">
          <v-col
            cols="4"
            style="font-weight: bold; border-right: 1px solid grey"
            >Solde</v-col
          >
          <v-col
            cols="3"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px; font-weight: bold">
              {{
                currency(bookerTotalPaid - bookerTotalRefund, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </span></v-col
          >
          <v-col
            cols="2"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px; font-weight: bold">
              {{
                currency(giftTotalPaid - giftTotalRefund, {
                  symbol: '€',
                  separator: ' ',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}</span
            ></v-col
          >
          <v-col
            cols="3"
            style="text-align: right; border-right: 1px solid grey"
          >
            <span style="padding-right: 10px; font-weight: bold">
              {{
                currency(
                  giftTotalPaid -
                    giftTotalRefund +
                    bookerTotalPaid -
                    bookerTotalRefund,
                  {
                    symbol: '€',
                    separator: ' ',
                    decimal: ',',
                    pattern: '# !',
                  }
                ).format()
              }}</span
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import currency from 'currency.js'
export default {
  name: 'bookerAggregate',
  props: {
    aggregates: {
      type: Object,
      default: null,
    },
    periodeTitle: {
      type: String,
      default: 'Journée en cours',
    },
  },
  data() {
    return {
      aggregateSel: null,
      pSel: 'today',
    }
  },
  mounted() {
    this.aggregateSel = this.aggregates[this.pSel]
  },
  watch: {
    aggregates: {
      handler(val) {
        this.aggregateSel = val[this.pSel]
      },
      deep: true,
    },
  },
  computed: {
    currentPeriode() {
      if (
        this.aggregates.current.periode.start &&
        this.aggregates.current.periode.end
      ) {
        if (
          this.aggregates.current.periode.start ===
          this.aggregates.current.periode.end
        ) {
          return this.aggregates.current.periode.start
        } else {
          return (
            this.aggregates.current.periode.start +
            ' au ' +
            this.aggregates.current.periode.end
          )
        }
      }
      return ''
    },
    totalAmount() {
      if (!this.aggregateSel) {
        return 0
      }
      const tmpAmount = this.aggregateSel.booking.eden.reduce((prev, cur) => {
        if (cur.mStatus === 1) {
          prev += cur.mAmount
        }
        return prev
      }, 0)
      return tmpAmount
    },
    totalCreditUse() {
      if (!this.aggregateSel) {
        return 0
      }
      const tmpCreditUse = this.aggregateSel.booking.eden.reduce(
        (prev, cur) => {
          if (cur.mStatus === 1) {
            prev += cur.mCreditUsed + cur.mCreditBefore
          }
          return prev
        },
        0
      )
      return tmpCreditUse
    },
    bookerTotalPaid() {
      if (!this.aggregateSel) {
        return 0
      }
      const tmpPaid = this.aggregateSel.booking.eden.reduce((prev, cur) => {
        if (
          cur.mStatus === 1 ||
          cur.mStatus === 2 ||
          cur.mStatus === 3 ||
          cur.mStatus === 6
        ) {
          prev += cur.mPaid
        }
        return prev
      }, 0)
      return tmpPaid
    },
    giftTotalPaid() {
      if (!this.aggregateSel) {
        return 0
      }
      const tmpPaid = this.aggregateSel.gift.eden.reduce((prev, cur) => {
        if (cur.mStatus === 1 || cur.mStatus === 3 || cur.mStatus === 6) {
          prev += cur.mCredited
        }
        return prev
      }, 0)
      return tmpPaid
    },
    giftTotalRefund() {
      if (!this.aggregateSel) {
        return 0
      }
      const tmpRefund = this.aggregateSel.gift.eden.reduce((prev, cur) => {
        if (cur.mStatus === 3 || cur.mStatus === 6) {
          prev += cur.mCredited
        }
        return prev
      }, 0)
      return tmpRefund
    },
    bookerTotalRefund() {
      if (!this.aggregateSel) {
        return 0
      }
      const tmpRefund = this.aggregateSel.booking.eden.reduce((prev, cur) => {
        if (cur.mStatus === 3 || cur.mStatus === 6) {
          prev += cur.mPaid
        }
        return prev
      }, 0)
      return tmpRefund
    },
  },
  methods: {
    currency,
    changeP(val) {
      this.pSel = val
      this.aggregateSel = this.aggregates[this.pSel]
    },
    setPeriod(val) {
      this.changeP(val.type)
    },
  },
}
</script>

<style scoped>
.periodeSel {
  font-weight: bold;
  background-color: #f3f3f3;
  border-bottom: 1px solid #333333;
}
</style>
