<template>
  <v-container>
    <h1>Mon compte</h1>

    <router-link to="/users">Liste des comptes</router-link>

    <div>
      <pre>{{ $store.state.auth.user }}</pre>
    </div>
  </v-container>
</template>

<script>
import LayoutHome from '../../layouts/layout-home.vue'

export default {
  name: 'Home',
  data: () => {
    return {}
  },
  created() {
    this.$emit('update:layout', LayoutHome)
  },
  methods: {}
}
</script>
