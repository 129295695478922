<template>
  <div>
    <v-container class="py-8">
      <v-row>
        <v-col cols="12" class="d-flex align-center">
          <v-btn icon to="/closeddays" class="mr-3" exact>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h2>Ajouter une fermeture exceptionnelle</h2>
        </v-col>
        <v-col cols="12">
          <v-form @submit.prevent="handleSubmitForm">
            <v-row dense>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Type de fermeture</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group v-model="closeType" row>
                          <v-radio
                            label="Fermeture une journée"
                            value="single"
                          />
                          <v-radio
                            label="Fermeture plage de date"
                            value="range"
                          />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Date de fermeture</h3>
                      </v-col>
                      <v-col cols="12" v-if="closeType === 'single'">
                        <v-text-field
                          type="date"
                          v-model="closeDate"
                          hide-details
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col cols="12" v-else-if="closeType === 'range'">
                        <v-row dense>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              type="date"
                              v-model="closeDateStart"
                              hide-details
                              outlined
                              label="De"
                              dense
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              type="date"
                              v-model="closeDateEnd"
                              hide-details
                              outlined
                              label="A"
                              dense
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12">
                        <h3>Rayon de fermeture</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-radio-group v-model="closeRayon" row>
                          <v-radio label="Fermeture générale" value="all" />
                          <v-radio
                            label="Fermer un groupement"
                            value="groupment"
                          />
                          <v-radio label="Fermer une boutique" value="shop" />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-row dense v-if="closeRayon === 'all'">
                      <v-col cols="12">
                        <h3>Fermer tout</h3>
                      </v-col>
                      <v-col cols="12">
                        <p>Toutes les boutiques seront fermées.</p>
                      </v-col>
                    </v-row>
                    <v-row dense v-else-if="closeRayon === 'groupment'">
                      <v-col cols="12">
                        <h3>Groupements à fermer</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="search"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-magnify"
                          label="Rechercher un groupement"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="max-height: 400px; overflow-y: auto"
                      >
                        <v-row dense>
                          <v-col
                            cols="12"
                            sm="6"
                            v-for="groupment in filteredGroupments"
                            :key="groupment.id"
                          >
                            <div class="d-flex align-center">
                              <v-switch
                                inset
                                class="my-0 mr-2"
                                hide-details
                                dense
                                v-model="closeGroupments"
                                :value="groupment.id"
                              />
                              <div>{{ groupment.name.fr }}</div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row dense v-else-if="closeRayon === 'shop'">
                      <v-col cols="12">
                        <h3>Boutiques à fermer</h3>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="search"
                          hide-details
                          outlined
                          prepend-inner-icon="mdi-magnify"
                          label="Rechercher une boutique"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        style="max-height: 400px; overflow-y: auto"
                      >
                        <v-row dense>
                          <v-col
                            cols="12"
                            sm="6"
                            v-for="shop in filteredShops"
                            :key="shop.abId"
                          >
                            <div class="d-flex align-center">
                              <v-switch
                                inset
                                class="my-0 mr-2"
                                hide-details
                                dense
                                v-model="closeShops"
                                :value="shop.abId"
                              />
                              <div>{{ shop.name }}</div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn type="submit" color="primary">
                  Valider la fermeture
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import { mapGetters } from 'vuex'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'

export default {
  components: {},
  data() {
    return {
      closeType: 'single',
      closeDate: '',
      closeDateStart: '',
      closeDateEnd: '',
      closeRayon: 'all',
      search: '',
      closeGroupments: [],
      closeShops: [],
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    ...mapGetters({
      geogroupments: 'geogroupments/getGeogroupments',
    }),
    shops: function () {
      return this.$store.getters['shop/fusionShop'](this.shopsFiltered)
    },
    filteredGroupments() {
      return this.geogroupments.filter((g) => {
        const search = this.search.toLowerCase().trim()
        const name = (g.name.fr || '').toLowerCase().trim()

        if (!search) {
          return true
        }

        if (name.includes(search)) {
          return true
        }

        return false
      })
    },
    filteredShops() {
      return this.shops.filter((s) => {
        const search = this.search.toLowerCase().trim()
        const name = (s.name || '').toLowerCase().trim()

        if (!search) {
          return true
        }

        if (name.includes(search)) {
          return true
        }

        return false
      })
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('geogroupments/fetchGeogroupments', {})
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    async handleSubmitForm() {
      try {
        const dateStart =
          this.closeType === 'single' ? this.closeDate : this.closeDateStart
        const dateEnd =
          this.closeType === 'single' ? this.closeDate : this.closeDateEnd

        await MKPSDK.ClosedDays.createClosedDay({
          type: this.closeType,
          dateStart: dateStart,
          dateEnd: dateEnd,
          range: this.closeRayon,
          groupments: this.closeGroupments,
          shops: this.closeShops,
        })

        await this.$router.push('/closeddays')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped></style>
