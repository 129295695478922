<template>
  <div>
    <v-dialog
      width="450"
      persistent
      v-model="newItemPopup"
      :key="newItemPopupKey"
    >
      <action-modal
        @actionModal="actionForm"
        :title="
          newItem ? $t('blog.editBlogCategory') : $t('blog.addBlogCategory')
        "
      >
        <template v-slot:actionModalContent>
          <add-post-category ref="itemForm" :category="newItem" />
        </template>
      </action-modal>
    </v-dialog>

    <post-categories
      :includeAddAction="true"
      :addActionCallback="addNewItem"
      :editItemCallback="editItem"
      :deleteItemCallback="deleteItem"
    />
  </div>
</template>

<script>
// import layoutDatabase from '../../../layouts/layout-database.vue'
import layoutHome from '../../../layouts/layout-home.vue'
import PostCategories from '@/components/database/blog/postCategories.vue'
import AddPostCategory from '@/components/database/blog/addPostCategory.vue'
import ActionModal from '@/components/helpers/actionModal.vue'

export default {
  components: {
    PostCategories,
    AddPostCategory,
    ActionModal
  },
  data() {
    return {
      newItemPopup: false,
      newItemPopupKey: null,
      newItem: null
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  methods: {
    actionForm(e) {
      if (e) {
        this.handleForm()
      } else {
        this.closeForm()
      }
    },
    async handleForm() {
      if (await this.$refs.itemForm.handleForm()) {
        this.closeForm()
      }
    },
    closeForm() {
      this.newItemPopup = false
      this.newItem = null
    },
    addNewItem() {
      this.newItemPopup = true
      this.newItemPopupKey = Date.now()
      this.newItem = null
    },
    editItem(item) {
      this.newItemPopup = true
      this.newItemPopupKey = Date.now()
      this.newItem = JSON.parse(JSON.stringify(item))
    },
    async deleteItem(item) {
      try {
        await this.$store.dispatch('blog/deletePostCategory', {
          postCategory: item
        })
      } catch (err) {
        console.log('err :>> ', err)
      }
    }
  }
}
</script>

<style></style>
