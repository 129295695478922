<template>
  <!--<div
    style="position: absolute; right:0;top:0; bottom: 0;
    width: 600px;margin: 10px;z-index: 150"
  >-->

  <v-card elevation="2" style="border: 2px solid black" v-if="booker">
    <v-app-bar flat color="black">
      <v-toolbar-title class="text-h5 white--text pl-0"
        >Réservation n° {{ booker.nId }} / {{ booker.mStatus }}
      </v-toolbar-title>
      <v-spacer />
      <span class="white--text mr-3">{{ getStatus(booker.mStatus) }}</span>
      <v-btn icon color="white" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-text v-if="loaded === false" style="height: 60vh">
      <v-row
        v-if="booker.mStatus === 7 && booker.creditedAt !== ''"
        style="background-color: #f3f3f3"
      >
        <v-col cols="12">
          <span style="color: black"
            ><strong
              >Crédité le {{ formatAbDateHour(booker.creditedAt) }}</strong
            ></span
          >
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          (booker.mStatus === 6 || booker.mStatus === 14) &&
          booker.refundAt !== ''
        "
        style="background-color: #f3f3f3"
      >
        <v-col cols="12">
          <span style="color: black"
            ><strong
              >Demande de remboursement le
              {{ formatAbDateHour(booker.refundAt) }}</strong
            ></span
          >
        </v-col>
      </v-row>
      <!--client-->
      <v-row>
        <v-col cols="12" style="font-size: 1rem">
          <span style="font-weight: bold">Client </span>
          <v-divider style="margin-top: 5px">-</v-divider>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          {{ booker.customerLastname }}
        </v-col>
        <v-col cols="6">
          {{ booker.customerFirstname }}
        </v-col>
        <v-col cols="6">
          {{ booker.customerEmail }}
        </v-col>
        <v-col cols="6">
          {{ booker.customerPhone }}
        </v-col>
      </v-row>
      <v-row v-if="getCreditOnUse() > 0" style="background-color: #eeeeee">
        <v-col style="font-weight: bold">Crédits : </v-col>
        <v-col style="font-weight: bold">Crédits en cours d'utilisation </v-col>
        <v-col style="font-weight: bold; text-align: right">
          {{
            currency(getCreditOnUse(), {
              symbol: '€',
              decimal: ',',
              pattern: '# !',
            }).format()
          }}</v-col
        >
      </v-row>
      <!--Réservation-->
      <v-row v-if="booker.norigin !== 10">
        <v-col>
          <v-row no-gutters>
            <v-col cols="12" style="font-size: 1rem">
              <span style="font-weight: bold">Réservation </span>
              <v-divider style="margin-top: 5px">-</v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5">
              {{ getShopName(booker.sShopId) }}
            </v-col>
            <v-col cols="1"> le </v-col>
            <v-col cols="3">
              {{ formatShortDate(booker.dBookingAt) }}
            </v-col>
            <v-col cols="1"> à </v-col>
            <v-col cols="2">
              {{ formatHour(booker.m_start) }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="4">
              <label>Prestation(s) : </label>
            </v-col>
            <v-col cols="8">
              <div v-for="(rdv, id) in booker.tab_rdv" :key="id">
                <div v-for="(lrdv, id) in rdv.m_details" :key="'l' + id">
                  {{ lrdv.m_prestation }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--paiement-->
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" style="font-size: 1rem">
              <span style="font-weight: bold">Paiement </span>
              <v-divider style="margin-top: 5px">-</v-divider>
            </v-col>
          </v-row>
          <v-row v-if="booker.mStatus === 15">
            <v-col cols="3">N° de transaction</v-col>
            <v-col cols="9" style="text-align: left">
              {{ booker.sTransactionId }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" style="font-weight: 500"> Total à régler </v-col>
            <v-col cols="2" style="text-align: right">
              {{
                currency(booker.mAmount, {
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </v-col>
            <v-col cols="2" style="padding-left: 5px; font-weight: 500">
              Crédit Utilisé
            </v-col>
            <v-col cols="2" style="text-align: right">
              {{
                currency(booker.mCreditUsed + booker.mCreditBefore, {
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </v-col>
            <v-col cols="2" style="padding-left: 5px; font-weight: 500">
              Bkdo Utilisé
            </v-col>
            <v-col cols="2" style="text-align: right">
              {{
                currency(booker.mGiftUsed, {
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" style="font-weight: bold"> Réglé </v-col>
            <v-col cols="2" style="text-align: right">
              {{
                currency(booker.mPaid, {
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </v-col>
            <v-col cols="2" style="padding-left: 5px">Encaissé</v-col>
            <v-col cols="2" style="text-align: right; padding-right: 5px">
              {{
                currency(eorderUse, {
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </v-col>

            <v-col
              v-if="transaction && transaction.details.refunded"
              cols="2"
              style="font-weight: bold"
            >
              Remboursé
            </v-col>
            <v-col
              v-if="transaction && transaction.details.refunded"
              cols="2"
              style="text-align: right"
            >
              {{
                currency(
                  (transaction.details.refunded + transaction.details.fees) /
                    100,
                  {
                    symbol: '€',
                    decimal: ',',
                    pattern: '# !',
                  }
                ).format()
              }}
            </v-col>

            <v-col cols="2" style="font-weight: bold"> Restant </v-col>
            <v-col cols="2" style="text-align: right">
              {{
                currency(getSoldeEorder(), {
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="booker.norigin === 1">
        <v-col>
          <v-row
            v-if="
              (booker.mStatus === 11 || booker.mStatus === 15) && transaction
            "
            style="background-color: #eeeeee"
          >
            <v-col cols="2" style="font-weight: bold"> Remboursé </v-col>
            <v-col cols="2" style="font-weight: bold; text-align: right">
              {{
                currency(transaction.details.refunded, {
                  fromCents: true,
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}</v-col
            >

            <v-col cols="2" style="font-weight: bold"> Frais </v-col>
            <v-col cols="2" style="font-weight: bold; text-align: right">
              {{
                currency(transaction.details.fees, {
                  fromCents: true,
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }).format()
              }}</v-col
            >
          </v-row>

          <v-row v-else-if="transaction && getSoldeEorder() > 0">
            <v-col>
              <v-text-field
                disabled
                label="Numéro de transaction"
                type="text"
                v-model="transaction.transactionUUID"
              />
              <!--scenario-->

              <v-row v-if="scenario">
                <v-col>
                  <v-row>
                    <v-col cols="12" style="font-size: 1rem">
                      <span style="font-weight: bold"
                        >Scénario de remboursement
                      </span>
                      <v-divider style="margin-top: 5px">-</v-divider>
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        disabled
                        label="Remboursable"
                        type="number"
                        v-model="scenario.totalRefund"
                        suffix="€"
                      />
                    </v-col>
                    <v-col cols="3" style="padding-left: 10px">
                      <v-text-field
                        label="Frais"
                        type="number"
                        v-model="scenario.finalFees"
                        suffix="€"
                      />
                    </v-col>
                    <v-col></v-col>
                    <v-col cols="3" style="padding-left: 10px">
                      <v-text-field
                        disabled
                        label="Remboursement"
                        type="number"
                        :value="scenario.totalRefund - scenario.finalFees"
                        suffix="€"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-else-if="getCreditOnUse() === 0">
                <v-col>
                  <v-col cols="3">
                    <v-text-field
                      disabled
                      label="Remboursable"
                      type="number"
                      v-model="scenario.totalRefund"
                      suffix="€"
                    />
                  </v-col>
                  <v-col cols="3" style="padding-left: 10px">
                    <v-text-field
                      label="Frais"
                      type="number"
                      v-model="scenario.finalFees"
                      suffix="€"
                    />
                  </v-col>
                  <v-col cols="3" style="padding-left: 10px">
                    <v-text-field
                      disabled
                      label="Remboursement"
                      type="number"
                      :value="scenario.totalRefund - scenario.finalFees"
                      suffix="€"
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else style="height: 70vh">
      <v-progress-linear indeterminate />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="!loaded && actionControl() !== '-1'">
      <!--opération ancienne résa-->

      <v-btn
        v-if="actionControl() === 'cancelOldRefund'"
        @click="changeOldTransactionStatus(4)"
        >Annuler le remboursement
      </v-btn>
      <v-btn
        v-if="actionControl() === 'markOldRefund'"
        dark
        @click="changeOldTransactionStatus(5)"
        >Marquer comme remboursé
      </v-btn>
      <v-btn
        v-if="actionControl() === 'markOldRefundCredit'"
        dark
        @click="refundOldCredit(30)"
        >Marquer comme remboursé</v-btn
      >
      <v-btn
        v-if="actionControl() === 'prepareRefund'"
        dark
        @click="getTransaction(booker.sTransactionId)"
      >
        Préparer un remboursement
      </v-btn>
      <v-btn
        v-if="actionControl() === 'refundable'"
        :disabled="
          scenario.finalFees < 0 || scenario.finalFees >= scenario.totalRefund
        "
        dark
        @click="
          refund(false, scenario.finalFees * 100, scenario.totalRefund * 100)
        "
        >Rembourser</v-btn
      >
      <v-btn
        v-if="actionControl() === 'refundable'"
        :disabled="
          scenario.finalFees < 0 || scenario.finalFees >= scenario.totalRefund
        "
        dark
        @click="refund(true, 0, scenario.totalRefund * 100)"
        >Rembourser sans frais</v-btn
      >
      <v-btn
        v-if="actionControl() === 'refundable' && isCreditable()"
        :disabled="
          scenario.finalFees < 0 || scenario.finalFees >= scenario.totalRefund
        "
        color="green"
        @click="abDeleteRdv(3)"
        >Mettre au crédit</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import swal from 'sweetalert'
import currency from 'currency.js'
import { MKPSDK, StatsSDK } from '@/packages/artebeaute-sdk/src'

const Moment = require('moment')
import { eorderStatus } from '@/helpers/eorder'

export default {
  name: 'bookerEdit',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bookerStatus: eorderStatus,
      eorderUse: 0,
      booker: null,
      transaction: null,
      scenario: null,
      loaded: false,
      creditUsedOnToBooking: null,
    }
  },
  watch: {
    item: {
      handler: async function (val) {
        this.booker = val
        this.transaction = null
        this.scenario = null
        this.loaded = true
        if (
          this.booker.mStatus === 9 ||
          (this.booker.mStatus === 7 && this.booker.norigin !== 10) ||
          this.booker.mStatus === 11 ||
          this.booker.mStatus === 15
        ) {
          await this.searchUseEorder()
        }
        if (
          (this.booker.mStatus === 7 &&
            this.booker.norigin !== 10 &&
            this.booker.norigin !== 9) ||
          this.booker.mStatus === 11 ||
          this.booker.mStatus === 9 ||
          this.booker.mStatus === 15
        ) {
          await this.getTransaction(this.booker.sTransactionId)
        }

        await this.getCustomerHistory(this.booker.customerid)
        this.loaded = false
      },
      deep: true,
    },
  },
  async mounted() {
    this.booker = this.item
    this.loaded = true
    if (
      this.booker.mStatus === 9 ||
      (this.booker.mStatus === 7 && this.booker.norigin !== 10) ||
      this.booker.mStatus === 11 ||
      this.booker.mStatus === 15
    ) {
      await this.searchUseEorder()
    }
    this.booker = this.item
    if (
      (this.booker.mStatus === 7 &&
        this.booker.norigin !== 10 &&
        this.booker.norigin !== 9) ||
      this.booker.mStatus === 11 ||
      this.booker.mStatus === 9 ||
      this.booker.mStatus === 15
    ) {
      await this.getTransaction(this.booker.sTransactionId)
    }

    await this.getCustomerHistory(this.booker.customerid)
    await this.getCustomerRemainingAmount(this.booker.customerid)
    this.loaded = false
  },
  methods: {
    currency,
    async getCustomerRemainingAmount(val) {
      try {
        await StatsSDK.StatBooking.customerRemainingAmount(val)
      } catch (e) {
        console.log(e)
      }
    },
    getCreditOnUse() {
      if (this.creditUsedOnToBooking) {
        return this.creditUsedOnToBooking.reduce((prev, cur) => {
          prev += cur.mCreditUsed
          return prev
        }, 0)
      } else {
        return 0
      }
    },
    async getCustomerHistory(val) {
      try {
        const resp = await StatsSDK.StatBooking.orderByCustomerId(val, '1', '1')

        if (resp.eorder.success) {
          this.creditUsedOnToBooking = resp.eorder.data
        } else {
          this.creditUsedOnToBooking = null
        }
      } catch (e) {
        console.log(e)
      }
    },
    getSoldeEorder() {
      if (this.transaction && this.transaction.details.refunded) {
        return (
          this.booker.mPaid -
          this.eorderUse -
          (this.transaction.details.refunded + this.transaction.details.fees) /
            100
        )
      } else {
        return this.booker.mPaid - this.eorderUse
      }
    },
    getShopName(id) {
      return this.$store.getters['shop/abShopNameById'](id)
    },
    getFinalFees() {
      return this.scenario / 100
    },
    getStatus(val) {
      const mystatus = this.bookerStatus.find(
        (st) => st.value === val.toString()
      )
      return mystatus.text
    },
    async refund(noFees = false, setFees = 0, setAmount = 0) {
      try {
        const solde = this.getSoldeEorder()

        swal({
          title: 'Etes-vous sûr ?',
          text: 'Voulez-vous vraiment rembourser cette transaction ?',
          buttons: ['Annuler', 'Valider'],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            const response = await MKPSDK.Payment.refund(this.booker.nId, {
              transactionID: this.transaction.id,
              noFees,
              setFees,
              setAmount,
            })

            if (response.success) {
              if (
                solde > 0 &&
                (this.booker.mStatus === 9 || this.booker.mStatus === 7)
              ) {
                await StatsSDK.StatBooking.appointmentPartialRefund(
                  this.booker.nId,
                  this.booker.customerid,
                  solde
                )
              }

              // const abdel = await this.abDeleteRdv(11)
              // this.$emit('recharge', 11)
              const result =
                await StatsSDK.StatBooking.appointmentOldChangeStatus(
                  this.booker.nId,
                  6
                )
              if (result.refundAsk.booker.success) {
                this.$emit('recharge', 15)
              }
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async abDeleteRdv(typeDelete = 3) {
      try {
        swal({
          title: 'Etes-vous sûr ?',
          text: 'Voulez-vous vraiment Créditer cette transaction ?',
          buttons: ['Annuler', 'Valider'],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            const tabidrdv = this.booker.tab_rdv.reduce((prev, cur) => {
              prev.push(cur.m_idrdv)
              return prev
            }, [])
            await MKPSDK.Payment.appointmentDelete({
              shopId: this.booker.sShopId,
              reserveId: tabidrdv.join(','),
              eorderId: this.booker.nId,
              bookingDate: this.booker.dBookingAt,
              typeDelete: typeDelete,
              gift: null,
            })

            this.$emit('recharge', 7)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getTransaction(id) {
      this.loaded = true
      const response = await MKPSDK.Payment.getTransaction(id)
      this.transaction = response.transaction
      if (this.transaction) {
        const resp = await MKPSDK.Payment.getScenario({
          transactionID: this.transaction.id,
        })
        if (resp.transaction.scenario && this.eorderUse === 0) {
          this.scenario = resp.transaction.scenario
          this.scenario.finalFees = this.scenario.finalFees / 100
          this.scenario.finalRefund = this.scenario.finalRefund / 100
          this.scenario.totalRefund = this.scenario.totalRefund / 100
        } else {
          this.scenario = {
            finalFees: 0,
            finalRefund:
              this.transaction.details.amount.finalPriceTTC / 100 -
              this.eorderUse,
            totalRefund:
              this.transaction.details.amount.finalPriceTTC / 100 -
              this.eorderUse,
          }
        }
      }
      this.loaded = false
    },
    formatAbDateHour(val) {
      if (val) {
        const myd = new Date()
        const year = parseInt(String(val).substr(0, 4))
        const month = parseInt(String(val).substr(4, 2)) - 1
        const day = parseInt(String(val).substr(6, 2))
        const hour = parseInt(String(val).substr(8, 2))
        const min = parseInt(String(val).substr(10, 2))
        myd.setFullYear(year, month, day)
        myd.setHours(hour, min)
        if (Moment(myd).isValid()) {
          return Moment(myd).format('DD/MM/YYYY à HH:mm')
        } else {
          return 'non précisé'
        }
      } else {
        return ''
      }
    },
    formatHour(val) {
      if (val) {
        return val.substring(0, 5)
      } else {
        return ''
      }
    },
    formatShortDate(val) {
      if (val) {
        return Moment(String(val)).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    formatDate(val) {
      if (val) {
        return Moment(String(val)).format('DD/MM/YYYY HH:MM')
      } else {
        return ''
      }
    },
    close() {
      this.$emit('close')
    },
    isCreditable() {
      if (
        this.booker.mStatus === 1 ||
        this.booker.mStatus === 6 ||
        this.booker.mStatus === 7 ||
        this.booker.mStatus === 9 ||
        this.booker.mStatus === 11 ||
        this.booker.mStatus === 13 ||
        this.booker.mStatus === 15
      ) {
        return false
      }
      return true
    },
    isRefundable() {
      if (
        this.booker.mStatus !== 1 &&
        this.booker.mStatus !== 7 &&
        this.booker.mStatus !== 3 &&
        this.booker.mStatus !== 6 &&
        this.booker.mStatus !== 9 &&
        this.booker.mStatus !== 13 &&
        this.booker.mStatus !== 15 &&
        this.booker.mPaid > 0
      ) {
        return true
      } else if (
        this.booker.mStatus === 9 &&
        this.booker.mPaid - this.eorderUse > 0
      ) {
        return true
      } else if (
        this.booker.mStatus === 7 &&
        this.booker.mPaid - this.eorderUse > 0 &&
        this.getCreditOnUse() === 0
      ) {
        return true
      }

      return false
    },
    async refundOldCredit(val) {
      try {
        const textInfo =
          'Voulez-vous vraiment marquer comme remboursé ce crédit ?'
        swal({
          title: 'Etes-vous sûr ?',
          text: textInfo,
          buttons: ['Annuler', 'Valider'],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            const result =
              await StatsSDK.StatBooking.appointmentOldCreditRefund(
                this.booker.nId,
                this.booker.customerid,
                val
              )

            if (result.refundAsk.booker.success) {
              this.$emit('recharge', 13)
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async searchUseEorder() {
      try {
        const result = await StatsSDK.StatBooking.orderUse(this.booker.nId)

        if (result.use.success) {
          this.eorderUse = result.use.data
        }
      } catch (e) {
        console.log(e)
      }
    },
    actionControl() {
      if (this.booker.norigin === 9) {
        if (this.booker.mStatus === 13) {
          return 'cancelOldRefund'
        } else {
          return 'markOldRefund'
        }
      } else if (this.booker.norigin === 10 && this.booker.mStatus !== 13) {
        return 'markOldRefundCredit'
      } else if (this.booker.norigin === 1) {
        if (!this.transaction && this.isRefundable()) {
          return 'prepareRefund'
        } else if (
          this.transaction &&
          this.scenario &&
          this.getSoldeEorder() > 0
        ) {
          if (this.booker.mStatus === 7 && this.getCreditOnUse() > 0) {
            return '-1'
          } else {
            return 'refundable'
          }
        }
      }
      return '-1'
    },
    async changeOldTransactionStatus(status) {
      try {
        let textInfo = ''
        if (status === 5) {
          textInfo =
            'Voulez-vous vraiment marquer comme remboursé cette réservations ?'
        } else {
          textInfo = 'Voulez-vous vraiment annuler la marque remboursée ?'
        }
        swal({
          title: 'Etes-vous sûr ?',
          text: textInfo,
          buttons: ['Annuler', 'Valider'],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            const result =
              await StatsSDK.StatBooking.appointmentOldChangeStatus(
                this.booker.nId,
                status
              )
            if (result.refundAsk.booker.success) {
              this.booker.mStatus = status === 5 ? 13 : 6
              this.$emit('recharge', this.booker.mStatus)
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style scoped>
.block {
  padding: 10px;
  background-color: white;
}

.block legend {
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
}
</style>
