<template>
  <v-card>
    <v-app-bar flat color="black">
      <v-toolbar-title class="text-h5 white--text pl-0"
        >{{ shopName }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-text>
      <v-row>
        <v-col cols="6" style="font-weight: bold">
          {{ nbBooker }} Réglement(s) Rendez-vous :
          {{
            currency(totalBooker, {
              fromCents: false,
              symbol: '€',
              decimal: ',',
              pattern: '# !'
            })
          }}
        </v-col>
        <v-col cols="6" style="font-weight: bold">
          {{ nbGift }} Bon(s) Cadeau(x) :
          {{
            currency(totalGift, {
              fromCents: false,
              symbol: '€',
              decimal: ',',
              pattern: '# !'
            })
          }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="tab = 'booker'" style="margin-right: 10px"
            >Réglement Rendez-vous</v-btn
          >
          <v-btn @click="tab = 'gift'">Réglement Bon Cadeau</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="tab === 'booker'">
          <v-data-table :items="listBooker" :headers="headersBooker">
            <template v-slot:[`item.dateRdvUse`]="{ item }">
              <span v-if="item.dateRdvUse === '0000-00-00'"
                >Utilisé en boutique</span
              >
              <span v-else>{{ item.dateRdvUse }}</span>
            </template>
            <template v-slot:[`item.hourRdvUse`]="{ item }">
              <span v-if="item.dateRdvUse === '0000-00-00'"> </span>
              <span v-else>{{ item.hourRdvUse.substring(0, 5) }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{
                currency(item.amount, {
                  fromCents: false,
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !'
                })
              }}
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" v-else-if="tab === 'gift'">
          <v-data-table :items="listGift" :headers="headersGift">
            <template v-slot:[`item.dateRdvUse`]="{ item }">
              <span v-if="item.dateRdvUse === '0000-00-00'"
                >Encaissement hors web</span
              >
              <span v-else>{{ item.dateRdvUse }}</span>
            </template>
            <template v-slot:[`item.hourRdvUse`]="{ item }">
              <span v-if="item.dateRdvUse === '0000-00-00'"> </span>
              <span v-else>{{ item.hourRdvUse.substring(0, 5) }}</span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              {{
                currency(item.amount, {
                  fromCents: false,
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !'
                })
              }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import currency from 'currency.js'

export default {
  name: 'previewGiftAndOrder',
  props: {
    shopName: {
      type: String,
      default: () => ''
    },
    listBooker: {
      type: Array,
      default: () => []
    },
    listGift: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tab: 'booker',
      dialog: false,
      headersGift: [
        {
          text: 'titulaires',
          value: 'titulaire',
          width: 50,
          sortable: true
        },
        { text: 'Code', value: 'giftCode', width: 50, sortable: true },
        { text: 'Encaissé le', value: 'dateUse', width: 50, sortable: false },
        { text: 'à', value: 'hourUse', width: 50, sortable: false },
        { text: 'montant', value: 'amount', width: 50, sortable: false }
      ],
      headersBooker: [
        {
          text: 'Clients',
          value: 'titulaire',
          width: 50,
          sortable: true
        },
        { text: 'Encaissé le', value: 'dateUse', width: 50, sortable: true },
        {
          text: 'à',
          value: 'hourUse',
          width: 50,
          sortable: false
        },

        { text: 'montant', value: 'amount', width: 50, sortable: false }
      ]
    }
  },
  computed: {
    totalBooker() {
      return this.listBooker.reduce((prev, cur) => {
        prev += cur.amount
        return prev
      }, 0)
    },
    totalGift() {
      return this.listGift.reduce((prev, cur) => {
        prev += cur.amount
        return prev
      }, 0)
    },
    nbGift() {
      return this.listGift.length
    },
    nbBooker() {
      return this.listBooker.length
    }
  },
  methods: {
    currency,
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
