<template>
  <v-container class="ctn my-12">
    <h1>Activer votre compte</h1>

    <div v-if="success">
      <v-alert type="success">{{ success }}</v-alert>

      <v-btn to="/" type="submit" color="dark" dark tile
        >Accéder à mon espace</v-btn
      >
    </div>
    <div v-else-if="error">
      <v-alert type="error">{{ error }}</v-alert>
    </div>
    <div v-else>
      <v-form @submit.prevent="handleForm">
        <v-text-field
          type="email"
          disabled
          :value="user.email"
          placeholder="Email"
        />
        <v-text-field type="text" v-model="firstname" placeholder="Prénom" />
        <v-text-field type="text" v-model="lastname" placeholder="Nom" />
        <v-text-field
          type="password"
          v-model="password"
          placeholder="Mot de passe"
        />
        <v-text-field
          type="password"
          v-model="passwordConfirmation"
          placeholder="Mot de passe (confirmation)"
        />
        <v-btn type="submit" color="dark" dark block tile>Valider</v-btn>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import { MKPSDK } from '@/packages/artebeaute-sdk/src'

export default {
  name: 'Home',
  data() {
    return {
      success: null,
      error: null,
      user: {},
      email: null,
      firstname: null,
      lastname: null,
      password: null,
      passwordConfirmation: null
    }
  },
  async mounted() {
    try {
      const response = await MKPSDK.CmpUsers.getByToken(
        this.$route.params.token
      )
      const user = response.user

      if (!user) {
        this.error = 'Bad Token'
        return
      }

      this.user = user
    } catch (err) {
      this.error = 'Bad Token'
    }
  },
  methods: {
    async handleForm() {
      if (!this.firstname) {
        return
      }

      if (!this.lastname) {
        return
      }

      if (!this.password) {
        return
      }

      if (this.password !== this.passwordConfirmation) {
        return
      }

      try {
        await MKPSDK.CmpUsers.updateByToken(this.$route.params.token, {
          firstname: this.firstname,
          lastname: this.lastname,
          password: this.password
        })

        this.success = 'Mis à jour avec succès.'
      } catch (err) {
        this.error = 'Error'
      }
    }
  }
}
</script>

<style scoped>
.ctn {
  width: 90%;
  max-width: 600px;
}
</style>
