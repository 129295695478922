<template>
  <v-container>
    <categories-cards :values="blocks" />
  </v-container>
</template>

<script>
import blocksDB from '@/helpers/blocksDataBase'
// import layoutDatabase from '../../layouts/layout-database.vue'
import layoutHome from '../../layouts/layout-home.vue'
import CategoriesCards from '@/components/general/categoriesCards.vue'
// import colors from 'vuetify/lib/util/colors'

export default {
  components: { CategoriesCards },
  data: () => {
    return {}
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  async mounted() {
    await this.$store.dispatch('collections/fetchCollectionSchemas')

    // const collectionSchemas = this.$store.state.collections.collectionSchemas
    const blocks = blocksDB().getBlockSortByCategory()

    /* collectionSchemas.forEach((cs) => {
      blocks.push(this.generateCollectionMenuCard(cs))
    }) */

    await this.$store.dispatch('blocks/setBlocks', blocks)
  },
  computed: {
    blocks() {
      return [...this.$store.state.blocks.blocks]
    }
  },
  methods: {
    /* generateCollectionMenuCard(collection) {
      return {
        title: collection.name,
        icon: 'mdi-toy-brick',
        description: collection.name,
        color: colors.orange.accent1,
        to: '/database/collections/items/' + collection.type,
        navBottom: [
          {
            to: '/database',
            icon: 'mdi-arrow-left-circle',
            tooltip: 'Données de base',
          },
          {
            to: '/',
            icon: 'mdi-home',
            tooltip: `retour à l accueil`,
          },
        ],
      }
    }, */
  },
  watch: {
    async '$i18n.locale'() {
      const blocks = blocksDB().getBlockSortByCategory()

      await this.$store.dispatch('blocks/setBlocks', blocks)
    }
  }
}
</script>

<style></style>
