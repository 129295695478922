<template>
  <div>
    <multilang-text-field
      v-model="name"
      :placeholder="$t('categories.categoryName')"
    />
  </div>
</template>

<script>
import MultilangTextField from '@/components/helpers/multilangTextField.vue'

export default {
  components: {
    MultilangTextField
  },
  props: {
    category: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      name: this.category ? this.category.name : {}
    }
  },
  methods: {
    async handleForm() {
      try {
        if (this.category) {
          await this.$store.dispatch('blog/updatePostCategory', {
            postCategory: this.category,
            data: {
              name: this.name
            }
          })
        } else {
          await this.$store.dispatch('blog/createPostCategory', {
            data: {
              name: this.name
            }
          })
        }

        this.name = null
        return true
      } catch (err) {
        console.log('err :>> ', err)
      }

      return false
    }
  }
}
</script>

<style></style>
