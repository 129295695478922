<template>
  <div>
    <v-dialog v-model="sendGiftOrder" v-if="sendGiftOrder" max-width="350px">
      <v-card elevation="2" style="border: 2px solid red" v-if="gift">
        <v-app-bar flat color="red">
          <v-toolbar-title class="text-h5 white--text pl-0"
            >Envoyer le bon cadeau
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon color="white" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text>
          <div>
            <div>
              <v-form v-model="formIsValid" @submit.prevent>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="sendGiftOrderEmail"
                      placeholder="Adresse email"
                      :rules="emailRules"
                      outlined
                      dense
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      tile
                      @click="
                        resendGift(
                          'email',
                          transaction.giftOrderId,
                          sendGiftOrderEmail
                        )
                      "
                      :disabled="!formIsValid"
                      :dark="!!formIsValid"
                      block
                    >
                      OK
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card elevation="2" style="border: 2px solid black" v-if="gift">
      <v-app-bar flat color="black">
        <v-toolbar-title class="text-h5 white--text pl-0"
          >Bon cadeau n° {{ gift.m_Id }} {{ gift.m_status }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon color="white" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-row>
          <v-col cols="12" style="font-size: 1rem">
            <span style="font-weight: bold">Acheté par </span>
            <v-divider style="margin-top: 5px">-</v-divider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="2">
            {{ gift.m_custormerlastname }}
          </v-col>
          <v-col cols="2">
            {{ gift.m_customerfirstname }}
          </v-col>
          <v-col cols="1"> le </v-col>
          <v-col cols="2">
            {{ formatShortDate(gift.m_buyAt) }}
          </v-col>
          <v-col cols="1"> pour </v-col>
          <v-col cols="2">
            <span
              v-if="gift.m_beneficiary !== '0 0' && gift.m_beneficiairy !== ''"
            >
              {{ gift.m_beneficiary }}
            </span>
            <span> non précisé </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" style="font-size: 1rem">
            <span style="font-weight: bold">Détails </span>
            <v-divider style="margin-top: 5px">-</v-divider>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="1"> Activé </v-col>
          <v-col cols="1">
            <v-icon color="red" v-if="gift.m_status === '3'"
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-icon
              color="green"
              v-else-if="
                (gift.m_status === '1' && gift.m_totalDebit > 0) ||
                gift.m_totalCredit > 0
              "
              >mdi-checkbox-blank-circle</v-icon
            >
            <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
          </v-col>
          <v-col cols="1"> Code </v-col>
          <v-col cols="2">
            {{ gift.m_Code }}
          </v-col>

          <v-col cols="1"> Valeur </v-col>
          <v-col cols="3">
            {{
              currency(gift.m_credited, {
                fromCents: false,
                symbol: '€',
                decimal: ',',
                pattern: '# !',
              })
            }}
          </v-col>
          <v-col cols="1"> Solde </v-col>
          <v-col cols="2">
            {{
              currency(
                gift.m_credited - (gift.m_totalCredit + gift.m_totalDebit),
                {
                  fromCents: false,
                  symbol: '€',
                  decimal: ',',
                  pattern: '# !',
                }
              )
            }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3" v-if="gift.m_status === '1' || gift.m_status === '0'">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="gift.m_validAt"
                  label="Valide jusqu'au"
                  @blur="gift.m_validAt = parseDate(validAt)"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="gift.m_validAt"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row v-if="gift.m_status === '1' && transaction">
          <v-col cols="12" style="font-size: 1rem">
            <span style="font-weight: bold">Scénario de remboursement </span>
            <v-divider style="margin-top: 5px">-</v-divider>
          </v-col>
          <v-col cols="4">
            <v-text-field
              disabled
              label="N° de transaction"
              :value="gift.sTransactionId"
            />
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-text-field
              disabled
              label="Remboursable"
              type="number"
              v-model="scenario.totalRefund"
              suffix="€"
            />
          </v-col>
          <v-col cols="2" style="padding-left: 10px">
            <v-text-field
              label="Frais"
              type="number"
              v-model="scenario.finalFees"
              suffix="€"
            />
          </v-col>

          <v-col cols="2" style="padding-left: 10px">
            <v-text-field
              disabled
              label="Remboursement"
              type="number"
              :value="scenario.totalRefund - scenario.finalFees"
              suffix="€"
            />
          </v-col>
        </v-row>

        <v-row v-if="gift.m_status === '3'">
          <v-col>Remboursé</v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-card-actions
        v-if="
          gift.m_status.toString() === '1' || gift.m_status.toString() === '0'
        "
      >
        <v-btn dark @click="updateValidAt(gift.m_validAt)">
          Modifier la date de validité</v-btn
        >
        <v-btn
          v-if="
            transaction && gift.m_totalDebit === 0 && gift.m_totalCredit === 0
          "
          dark
          @click="
            refundGift(
              gift.m_Id,
              transaction.giftOrderId,
              false,
              scenario.finalFees * 100,
              scenario.totalRefund * 100
            )
          "
          >Remboursement</v-btn
        >
        <v-btn v-if="transaction" @click="openSendGiftOrder(gift)">
          Envoyer le bon cadeau par mail
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
const Moment = require('moment')
import currency from 'currency.js'
import { MKPSDK, StatsSDK } from '@/packages/artebeaute-sdk/src'
import swal from 'sweetalert'
export default {
  name: 'giftEdit',
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gift: null,
      validAt: null,
      menu1: false,
      loaded: false,
      transaction: null,
      sendGiftOrder: null,
      sendGiftOrderEmail: null,
      sendGiftOrderEmailOpen: false,
      formIsValid: false,
      emailRules: [
        (v) => !!v || 'Email requis',
        (v) => /.+@.+/.test(v) || 'Email doit être valide',
      ],
      scenario: { finalFees: 0, finalRefund: 0, totalRefund: 0 },
    }
  },
  watch: {
    item: {
      handler: async function (val) {
        this.gift = JSON.parse(JSON.stringify(val))
        this.validAt = this.formatDate(this.gift.m_validAt)
        await this.getTransaction(this.gift.sTransactionId)
      },
      deep: true,
    },
  },
  async mounted() {
    this.gift = JSON.parse(JSON.stringify(this.item))
    this.validAt = this.formatDate(this.gift.m_validAt)
    await this.getTransaction(this.gift.sTransactionId)
  },
  methods: {
    currency,
    openSendGiftOrder(order) {
      this.sendGiftOrder = order
      this.sendGiftOrderEmail = null
      this.sendGiftOrderEmailOpen = false
      this.formIsValid = false
    },
    close() {
      this.$emit('close')
    },
    async resendGift(to, id, email) {
      this.loading = true
      this.sendGiftOrder = null

      const rep = await MKPSDK.Gift.resendPDF(to, id, email)
      if (rep) {
        swal({
          title: 'Bon Cadeau',
          text: 'Envoie réussi!',
          icon: 'success',
          button: 'Fermer',
        })
      } else {
        swal({
          title: 'Bon Cadeau',
          text: 'Envoie échoué!',
          icon: 'error',
          button: 'Fermer',
        })
      }

      this.loading = false
    },
    refundGift(giftId, localId, noFees = false, setFees = 0, setAmount = 0) {
      try {
        swal({
          title: 'Êtes vous sûr ?',
          text: 'Voulez-vous vraiment rembourser cette transaction ?',
          buttons: ['Annuler', 'Valider'],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            const response = await MKPSDK.Payment.refundGift({
              type: 'gift',
              localID: localId,
              noFees,
              setFees,
              setAmount,
            })
            if (response.success) {
              const respAb = await StatsSDK.StatGift.giftDelete(giftId)
              if (respAb) {
                swal({
                  title: 'Bon Cadeau',
                  text: 'Remboursement effectué!',
                  icon: 'success',
                  button: 'Fermer',
                })
              } else {
                swal({
                  title: 'Bon Cadeau',
                  text: 'Remboursement effectué pas validé sur artebeaute',
                  icon: 'error',
                  button: 'Fermer',
                })
              }
            } else {
              swal({
                title: 'Bon Cadeau',
                text: 'Remboursement non effectué!',
                icon: 'error',
                button: 'Fermer',
              })
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getTransaction(id) {
      this.loaded = true
      const response = await MKPSDK.Payment.getTransaction(id)
      this.transaction = response.transaction
      if (this.transaction) {
        this.scenario.finalFees = 0
        this.scenario.finalRefund =
          this.transaction.details.amount.finalPriceTTC / 100
        this.scenario.totalRefund = this.scenario.finalRefund
      }

      this.loaded = false
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatShortDate(val) {
      if (val) {
        return Moment(String(val)).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    parseToAb(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}${month.padStart(2, '0')}${day.padStart(2, '0')}`
    },
    callBack() {
      this.$emit('recharge')
    },
    updateValidAt(newDate) {
      const abDate = this.parseToAb(newDate)

      swal({
        title: 'Êtes vous sûr ?',
        text: 'Voulez-vous vraiment modifier la date de validité au ' + newDate,
        buttons: ['Annuler', 'Valider'],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const response = await StatsSDK.StatGift.giftUpdateValidAt(
            this.gift.m_Id,
            abDate
          )

          if (response.changeDate.booker.success) {
            swal({
              title: 'Bon Cadeau',
              text: 'Date de validité mis à jour',
              icon: 'success',
              button: 'Fermer',
            })
            this.callBack()
          } else {
            swal({
              title: 'Bon Cadeau',
              text: 'Erreur lors de la mise à jour de la date de validité !',
              icon: 'error',
              button: 'Fermer',
            })
          }
        }
      })
    },
  },
}
</script>

<style scoped></style>
