<template>
  <v-card
    tile
    style="height: 100%; min-height: 200px; position: relative"
    @click="goToPage"
    @keydown.enter="goToPage"
    :style="{
      cursor: to !== 'inherit' && !commingSoon ? 'pointer' : false,
    }"
    :ripple="false"
  >
    <div
      :style="
        'background-color: ' +
        color +
        ';width: 5px;height:25px; position: absolute; top: 12px; left: 0'
      "
    ></div>
    <v-chip
      color="gray"
      label
      dark
      x-small
      style="position: absolute; bottom: 12px; right: 12px"
      v-if="commingSoon"
    >
      {{ $t('home.comingSoon') }}
    </v-chip>
    <v-chip
      color="gray"
      label
      dark
      x-small
      style="position: absolute; bottom: 12px; right: 12px"
      v-if="inDevelopment"
    >
      {{ $t('home.inDevelopment') }}
    </v-chip>
    <v-card-text>
      <div>
        <span class="title-card">{{ title }}</span>
        <p class="mt-2">{{ description }}</p>
      </div>
      <template v-if="directory">
        <div class="card-2" />
        <div class="card-3" />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'white',
    },
    to: {
      type: String,
      default: '',
    },
    directory: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    commingSoon: {
      type: Boolean,
      default: false,
    },
    inDevelopment: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToPage() {
      if (this.commingSoon) {
        return
      }

      if (this.to !== '') {
        this.$router.push(this.to)
      }
    },
  },
}
</script>

<style scoped>
.title-card {
  font-weight: bold;
  text-transform: uppercase;
}
</style>
