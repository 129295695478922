<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <!--  HOME  -->
    <module-list-light v-if="!manageCategories" key="1">
      <template v-slot:actionBlock>
        <!--
        <v-select
          v-model="selectedStatus"
          :items="tabStatus"
          :label="$t('database.tables.status')"
          background-color="white"
          outlined
          hide-details
          dense
          style="width: 175px"
          class="customWidth"
        >
          <template v-slot:selection>
            <div>
              <v-icon :color="$icones[selectedStatus].color">
                {{ $icones[selectedStatus].icon }}
              </v-icon>
              <span class="ml-2">{{ $icones[selectedStatus].title }}</span>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <v-icon :color="$icones[item].color">
              {{ $icones[item].icon }}
            </v-icon>
            <span class="ml-2">{{ $icones[item].title }}</span>
          </template>
        </v-select>
        -->

        <v-btn-toggle v-model="selectedStatus" mandatory @change="search">
          <v-btn icon>
            <v-icon :color="$icones['all'].color">
              {{ $icones['all'].icon }}
            </v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon :color="$icones['onLine'].color">
              {{ $icones['onLine'].icon }}
            </v-icon>
          </v-btn>
          <v-btn icon>
            <v-icon :color="$icones['offLine'].color">
              {{ $icones['offLine'].icon }}
            </v-icon>
          </v-btn>
        </v-btn-toggle>

        <v-text-field
          v-model="selectedTitle"
          :label="$t('blog.title')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="search"
        />

        <v-select
          v-model="selectedCategories"
          :items="tabNameCategories"
          :label="$t('database.categories')"
          background-color="white"
          outlined
          hide-details
          dense
          multiple
          style="width: 200px"
          class="customWidth"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item }}</span>
            <span v-if="index === 1"> ,...</span>
          </template>
        </v-select>

        <v-select
          v-model="selectedLangs"
          :items="langItems"
          :label="$t('other.language')"
          background-color="white"
          outlined
          hide-details
          dense
          multiple
          style="width: 200px"
          class="customWidth"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1"> ,...</span>
          </template>
        </v-select>

        <v-btn dark @click="search">
          {{ $t('other.research') }}
        </v-btn>
      </template>

      <template v-slot:actionSearch v-if="$vuetify.breakpoint.smAndDown">
        <v-icon class="mx-4" @click="addActionCallback">
          mdi-plus
        </v-icon>
        <v-icon class="mx-4" @click="manageCategories = true">
          mdi-clipboard-list-outline
        </v-icon>
      </template>

      <template v-slot:manageContent v-else>
        <v-btn class="ma-2" dark @click="addActionCallback">
          {{ $t('blog.addArticle') }}
        </v-btn>
        <v-btn class="ma-2" dark @click="manageCategories = true">
          {{ $t('articles.manageCategories') }}
        </v-btn>
      </template>

      <template v-slot:contentBlock>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="tabPosts"
            :items-per-page="abItemsPerPage"
            :page.sync="abPage"
            :hide-default-footer="$vuetify.breakpoint.mdAndUp"
            fixed-header
            class="data-table"
            @page-count="abPageCount = $event"
            height="55vh"
          >
            <template v-slot:[`item.status`]="{ item }">
              <icon-display
                v-if="!item.published"
                @actionClick="publishBlog(item)"
                :icon="$icones.offLine"
                :small="false"
                :toolTip="$t('blog.blogPostNotPublished')"
              />
              <icon-display
                v-else
                @actionClick="unpublishBlog(item)"
                :icon="$icones.onLine"
                :small="false"
                :toolTip="$t('blog.blogPostPublished')"
              />
            </template>

            <template v-slot:[`item.title`]="{ item }">
              <span v-if="item.title && controli18n($i18n.locale, item.title)">
                {{ controli18n($i18n.locale, item.title) }}
              </span>
              <span v-else> {{ $t('blog.noTitle') }} </span>
            </template>

            <template v-slot:[`item.category`]="{ item }">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="update-gg" text v-bind="attrs" v-on="on">
                    <span v-if="item.category">
                      {{ controli18n($i18n.locale, item.category.name) }}
                    </span>
                    <span class="add-gg" v-else>{{ $t('other.add') }}</span>
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(cat, i) in postCategories"
                    :key="i"
                    @click="linkCat(cat, item)"
                  >
                    <v-list-item-title>
                      {{ controli18n($i18n.locale, cat.name) }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.lang`]="{ item }">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="update-gg" text v-bind="attrs" v-on="on">
                    <span v-if="item.lang && item.lang.length">
                      {{ langItems.find(l => l.value === item.lang[0]).text }}
                    </span>
                    <span class="add-gg" v-else>{{ $t('other.add') }}</span>
                    <v-icon>
                      mdi-chevron-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(lang, i) in langItems"
                    :key="i"
                    :class="{ 'grey lighten-2': lang.value === item.lang[0] }"
                    @click="linkLang(lang, item)"
                  >
                    <v-list-item-title>
                      {{ lang.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click="editItemCallback(item)"
                class="btn-tab mr-2"
                color="#433883"
                outlined
              >
                <v-icon>mdi-clipboard-edit-outline</v-icon>
                <span>{{ $t('other.edit') }}</span>
              </v-btn>
              <icon-display
                @actionClick="deleteItemCallback(item)"
                :small="false"
                :icon="$icones.delete"
                color="red darken-4"
              />
            </template>
          </v-data-table>
        </v-card>
      </template>

      <template v-slot:footerBlock>
        <div class="d-flex justify-center">
          <v-pagination
            v-if="abPageCount > 1"
            v-model="abPage"
            :length="abPageCount"
          />
        </div>
        <div class="d-flex justify-center mt-2">
          <div>
            <v-select
              v-model="abItemsPerPage"
              :items="selectAbItemsPerPage"
              background-color="white"
              outlined
              hide-details
              dense
              class="customWidth"
            />
          </div>
        </div>
      </template>
    </module-list-light>

    <!--  GESTION DES CATEGORIES  -->
    <module-list-light v-else key="2">
      <template v-slot:actionBlock>
        <v-text-field
          v-model="selectedNameCat"
          :label="$t('other.name')"
          background-color="white"
          outlined
          hide-details
          dense
          @keypress.enter="searchCat"
        />

        <v-btn dark @click="searchCat">
          {{ $t('other.research') }}
        </v-btn>
      </template>

      <template v-slot:contentBlock>
        <div>
          <categories-manager
            :categories="tabCategories"
            @update="emitUpdate"
            @back="manageCategories = false"
          />
        </div>
      </template>
    </module-list-light>
  </div>
</template>

<script>
import IconDisplay from '@/components/helpers/iconDisplay.vue'
import ModuleListLight from '@/components/general/moduleListLight.vue'
import icones from '../../../helpers/icones'
import { controli18n } from '../../../helpers/langs'
import CategoriesManager from './categoriesManager'

export default {
  components: {
    CategoriesManager,
    IconDisplay,
    ModuleListLight
  },
  props: {
    includeAddAction: {
      type: Boolean,
      default: true
    },
    addActionCallback: {
      type: Function,
      default: null
    },
    editItemCallback: {
      type: Function,
      default: null
    },
    deleteItemCallback: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      abPage: 1,
      abPageCount: 0,
      abItemsPerPage: 50,
      tabPosts: [],
      manageCategories: false,
      tabStatus: ['all', 'onLine', 'offLine'],
      selectedStatus: 0,
      selectedTitle: null,
      selectedCategories: null,
      selectedLangs: null,
      selectedNameCat: null,
      addArticleDialog: false,
      catLoading: [],
      filter: {
        active: false,
        online: false
      },
      loading: false,
      tabCategories: []
    }
  },
  computed: {
    postCategories() {
      return this.$store.getters['blog/getPostCategories']
    },
    blogPosts() {
      return this.$store.getters['blog/getBlogPosts']
    },
    filteredBlogPosts() {
      let blogPosts = this.blogPosts

      if (this.filter.active) {
        if (this.filter.online) {
          blogPosts = blogPosts.filter(post => post.published)
        } else {
          blogPosts = blogPosts.filter(post => !post.published)
        }
      }

      return blogPosts
    },
    headers() {
      return [
        {
          text: this.$t('database.tables.status'),
          value: 'status',
          width: '15%',
          divider: false,
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('blog.title'),
          value: 'title',
          width: '20%',
          divider: false,
          sortable: false,
          align: 'start'
        },
        {
          text: this.$t('articles.category'),
          value: 'category',
          width: '25%',
          divider: false,
          sortable: false,
          align: 'start'
        },
        {
          text: this.$t('other.language'),
          value: 'lang',
          width: '20%',
          divider: false,
          sortable: false,
          align: 'start'
        },
        {
          text: '',
          value: 'actions',
          width: '20%',
          divider: false,
          sortable: false
        }
      ]
    },
    tabNameCategories() {
      return this.postCategories.map(cat => {
        return controli18n(this.$i18n.locale, cat.name)
      })
    },
    langItems() {
      return [
        {
          id: 'all',
          name: this.$t('icons.all')
        },
        {
          id: 'fr',
          name: 'Français'
        },
        {
          id: 'en',
          name: 'English'
        },
        {
          id: 'es',
          name: 'Español'
        },
        {
          id: 'it',
          name: 'Italian'
        }
      ].map(pc => {
        return {
          value: pc.id,
          text: pc.name
        }
      })
    },
    selectAbItemsPerPage() {
      return [
        10,
        25,
        50,
        100,
        {
          text: this.$t('other.all'),
          value: -1
        }
      ]
    },
    $icones() {
      return icones().getIcones()
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch('blog/fetchPostCategories')
      await this.$store.dispatch('blog/fetchBlogPosts')

      this.filteredBlogPosts.map(post => {
        if (post.published) {
          this.filter.active = true
          this.filter.online = true
          this.selectedStatus = 1
        }
      })
      this.tabPosts = this.filteredBlogPosts
      this.tabCategories = this.postCategories
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    controli18n,

    emitUpdate() {
      this.search()
    },
    search() {
      let posts = []
      if (this.selectedStatus === 0) {
        this.filter.active = false
      } else if (this.selectedStatus === 1) {
        this.filter.active = true
        this.filter.online = true
      } else if (this.selectedStatus === 2) {
        this.filter.active = true
        this.filter.online = false
      }
      posts = this.filteredBlogPosts

      if (this.selectedTitle) {
        posts = posts.filter(post =>
          controli18n(this.$i18n.locale, post.title)
            .toUpperCase()
            .includes(this.selectedTitle.toUpperCase())
        )
      }

      if (this.selectedCategories && this.selectedCategories.length) {
        posts = posts.filter(post =>
          this.selectedCategories.includes(
            controli18n(this.$i18n.locale, post.category.name)
          )
        )
      }

      if (this.selectedLangs && this.selectedLangs.length) {
        posts = posts.filter(post => this.selectedLangs.includes(post.lang[0]))
      }

      this.tabPosts = posts
    },
    searchCat() {
      let categories = this.postCategories
      if (this.selectedNameCat) {
        categories = categories.filter(cat =>
          controli18n(this.$i18n.locale, cat.name)
            .toUpperCase()
            .includes(this.selectedNameCat.toUpperCase())
        )
      }
      this.tabCategories = categories
    },

    async publishBlog(post) {
      this.loading = true

      await this.$store.dispatch('blog/updateBlogPost', {
        post,
        data: {
          title: post.title,
          banner: post.banner.src
            ? {
                src: post.banner.src
              }
            : {},
          categoryId: post.category.id,
          articleId: post.article && post.article.id ? post.article.id : null,
          shopId: post.shop && post.shop.id ? post.shop.id : null,
          lang: post.lang,
          body: post.body,
          published: true,
          vedette: post.vedette,
          position: post.position,
          slug: post.slug
        }
      })

      this.search()

      this.loading = false
    },
    async unpublishBlog(post) {
      this.loading = true

      await this.$store.dispatch('blog/updateBlogPost', {
        post,
        data: {
          title: post.title,
          banner: post.banner.src
            ? {
                src: post.banner.src
              }
            : {},
          categoryId: post.category.id,
          articleId: post.article && post.article.id ? post.article.id : null,
          shopId: post.shop && post.shop.id ? post.shop.id : null,
          lang: post.lang,
          body: post.body,
          published: false,
          vedette: post.vedette,
          position: post.position,
          slug: post.slug
        }
      })

      this.search()

      this.loading = false
    },
    async linkCat(category, post) {
      this.loading = true

      await this.$store.dispatch('blog/updateBlogPost', {
        post,
        data: {
          title: post.title,
          banner: post.banner.src
            ? {
                src: post.banner.src
              }
            : {},
          categoryId: category.id,
          articleId: post.article && post.article.id ? post.article.id : null,
          shopId: post.shop && post.shop.id ? post.shop.id : null,
          lang: post.lang,
          body: post.body,
          published: true,
          vedette: post.vedette,
          position: post.position,
          slug: post.slug
        }
      })

      this.search()

      this.loading = false
    },
    async linkLang(lang, post) {
      this.loading = true

      await this.$store.dispatch('blog/updateBlogPost', {
        post,
        data: {
          title: post.title,
          banner: post.banner.src
            ? {
                src: post.banner.src
              }
            : {},
          categoryId: post.category.id,
          articleId: post.article && post.article.id ? post.article.id : null,
          shopId: post.shop && post.shop.id ? post.shop.id : null,
          lang: [lang.value],
          body: post.body,
          published: true,
          vedette: post.vedette,
          position: post.position,
          slug: post.slug
        }
      })

      this.search()

      this.loading = false
    }
  },
  watch: {
    blogPosts() {
      this.tabPosts = this.filteredBlogPosts
    },
    postCategories() {
      this.tabCategories = this.postCategories
    }
  }
}
</script>

<style>
.customWidth input {
  display: none;
}

@media screen and (min-width: 1100px) {
  .data-table table {
    table-layout: fixed;
  }
}
</style>

<style scoped>
/* .btn-toggle {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
} */

.data-table >>> .v-data-table__divider {
  border-right: none;
}

.content-icon-tab {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.btn-tab >>> .v-btn__content {
  column-gap: 8px;
}

.btn-tab span {
  font-weight: 700;
  letter-spacing: normal;
}

.update-gg.v-btn {
  padding: 2px 5px;
  height: auto;
  margin-right: 5px;
}

.update-gg span {
  color: black;
  letter-spacing: normal;
  text-transform: none;
  margin-right: 10px;
  text-align: left;
}

.update-gg span.add-gg {
  color: rgb(150, 150, 150);
}

.cardTitle {
  font-size: 1.2rem !important;
}
</style>
