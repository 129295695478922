export function arrayToCSV({
  data = null,
  columnDelimiter = ';',
  lineDelimiter = '\n'
}) {
  let result, ctr, keys

  if (data === null || !data.length) {
    return null
  }

  keys = Object.keys(data[0])

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  data.forEach(item => {
    ctr = 0
    keys.forEach(key => {
      if (ctr > 0) {
        result += columnDelimiter
      }

      result +=
        typeof item[key] === 'string' && item[key].includes(columnDelimiter)
          ? `"${item[key]}"`
          : item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export function downloadFile(filename, text) {
  var element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
