<template>
  <v-container>
    <categories-cards :values="blocks" />
  </v-container>
</template>

<script>
import blocksBooker from '@/helpers/blocksBooker'
import layoutHome from '../../layouts/layout-home.vue'
import CategoriesCards from '@/components/general/categoriesCards.vue'

export default {
  name: 'Home',
  components: {
    CategoriesCards
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    blocks() {
      return blocksBooker().getBlockSortByCategory()
    }
  }
}
</script>
