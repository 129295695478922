<template>
  <div
    style="height: 100vh;max-height:100vh;background-color: white;overflow-y: auto"
  >
    <v-card>
      <v-app-bar fixed flat color="black">
        <v-toolbar-title class="text-h5 white--text pl-0"
          >Liste des réglements
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon color="white" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text style="margin-top: 50px">
        <v-row>
          <v-col>
            <v-tabs>
              <v-tab @click="displayMode = 'preview'">Prévisualisation</v-tab>
              <v-tab @click="displayMode = 'tabs'">Tableau</v-tab>
            </v-tabs>
          </v-col></v-row
        >
        <v-row v-if="displayMode === 'tabs'">
          <v-col cols="12">
            <v-row>
              <v-col cols="6" style="font-weight: bold">
                total réservation
              </v-col>
              <v-col cols="6" style="font-weight: bold">
                total Bkdo
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :dark="tab === 'booker'"
                  @click="tab = 'booker'"
                  style="margin-right: 10px"
                  >Réglement Rendez-vous</v-btn
                >
                <v-btn :dark="tab === 'gift'" @click="tab = 'gift'"
                  >Réglement Bon Cadeau</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="tab === 'booker'">
                <v-data-table :items="itemBooker" :headers="headersBooker" />
              </v-col>
              <v-col v-else>
                <v-data-table :items="itemBkdo" :headers="headersBkdo" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else-if="displayMode === 'preview'" style="margin:25px">
          <v-col cols="12">
            <v-btn dark @click="generateReport()">imprimer</v-btn>
          </v-col>
          <v-col cols="12">
            <div id="mypdf">
              <div>
                <div style="width: 50%">
                  <table>
                    <tr>
                      <td>
                        <span style="font-weight: bold">
                          {{ advise.m_societe }}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td style="font-weight:normal; font-size: 12px">
                        {{ advise.m_adresse }}
                      </td>
                    </tr>
                    <tr>
                      <td style="font-weight:normal; font-size: 12px">
                        {{ advise.m_localite }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div
                  style="border:1px solid grey ;width: 40%; margin-left: auto; margin-top: 30px;margin-bottom: 30px; padding: 10px; text-align: left"
                >
                  <table>
                    <tr>
                      <td style="font-weight:bold; font-size: 12px">
                        {{ advise.m_shopSociety }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span
                          style="font-weight: normal; font-size: 12px;font-style: italic;padding-left:5px"
                        >
                          {{ advise.m_shopName }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style="font-weight:normal; font-size: 12px">
                        <p>{{ advise.m_shopAddress }}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style="font-weight:normal; font-size: 12px">
                        {{ advise.m_shopZipCode }} / {{ advise.m_shopCity }}
                      </td>
                    </tr>
                  </table>
                </div>
                <div style="text-align: right;font-size: 12px">
                  A {{ advise.m_localite }} le {{ getDate(new Date()) }}
                </div>
                <div
                  style="text-align: left;font-weight:normal; font-size: 12px;margin-bottom: 50px"
                >
                  Facture de commissions n°
                  <span style="font-weight: bold">{{
                    advise.m_numfacture
                  }}</span>
                </div>
                <div
                  style="text-align: center;font-weight:normal; font-size: 12px;margin-bottom: 50px"
                >
                  Période du
                  <span style="font-weight: bold">{{
                    getDate(advise.m_start)
                  }}</span>
                  au
                  <span style="font-weight: bold">{{
                    getDate(advise.m_end)
                  }}</span>
                </div>
                <div
                  style="text-align: center;font-weight:normal; font-size: 13px;margin-bottom: 50px"
                >
                  COMMISSION RETENUE SUR LES ARTICLES ENCAISSES
                </div>
                <div style="width: 100%">
                  <table
                    width="50%"
                    style="margin-left: auto; margin-right: auto"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="font-weight: bold;font-size: 12px;text-align: left"
                        >
                          TOTAL ARTICLES ENCAISSES
                        </td>
                        <td
                          style="font-weight: bold;font-size: 12px;text-align: right"
                        >
                          {{
                            currency(advise.m_articleAmount, {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="margin-top: 30px">
                  <table
                    width="50%"
                    style="margin-left: auto; margin-right: auto"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="font-weight: bold;font-size: 12px;text-align: left"
                        >
                          COMMISSION
                        </td>
                        <td
                          style="font-weight: bold;font-size: 12px;text-align: right"
                        >
                          {{ advise.m_tauxCommission }} %
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="padding-left:10px;  font-weight: normal;font-size: 12px;text-align: left"
                        >
                          HT
                        </td>
                        <td
                          style="padding-right:10px;  font-weight: normal;font-size: 12px;text-align: right"
                        >
                          {{
                            currency(advise.m_comHt, {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="padding-left:10px;  font-weight: normal;font-size: 12px;text-align: left"
                        >
                          TVA
                        </td>
                        <td
                          style="padding-right:10px;  font-weight: normal;font-size: 12px;text-align: right"
                        >
                          {{
                            currency(advise.m_comTva, {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="padding-left:10px;  font-weight: normal;font-size: 12px;text-align: left"
                        >
                          TTC
                        </td>
                        <td
                          style="padding-right:10px;  font-weight: normal;font-size: 12px;text-align: right"
                        >
                          {{
                            currency(advise.m_comTtc, {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style="padding-left:10px;  font-weight: normal;font-size: 12px;text-align: left"
                        >
                          REGLEMENT
                        </td>
                        <td
                          style="padding-right:10px;  font-weight: normal;font-size: 12px;text-align: right"
                        >
                          {{
                            currency(advise.m_regaVerse, {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div style="text-align: center; margin-top: 50px">
                  <table
                    width="50%"
                    style="margin-left: auto; margin-right: auto"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="; font-weight: bold;font-size: 14px;text-align: left"
                        >
                          Acompte versé
                        </td>
                        <td
                          style="font-weight: bold;padding-right:14px;  font-size: 12px;text-align: right"
                        >
                          {{
                            currency(advise.m_deposit, {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div style="text-align: center">
                <table
                  width="50%"
                  style="margin-left: auto; margin-right: auto"
                >
                  <tbody>
                    <tr>
                      <td
                        style="; font-weight: bold;font-size: 14px;text-align: left"
                      >
                        Versé sur votre compte
                      </td>
                      <td
                        style="font-weight: bold;padding-right:14px;  font-size: 12px;text-align: right"
                      >
                        {{
                          currency(advise.m_reglement, {
                            symbol: '€',
                            separator: ' ',
                            decimal: ',',
                            pattern: '# !'
                          }).format()
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="html2pdf__page-break"></div>
              <div>
                <span style="font-weight: bolder;font-size: 14px;padding: 5px"
                  >Réservation(s) encaissée(s)</span
                >
                <table class="details" style="width: 100%">
                  <tr style="border-bottom: 5px solid green">
                    <th v-for="(item, i) in headersBooker" v-bind:key="'h' + i">
                      {{ item.text }}
                    </th>
                  </tr>
                  <slot v-for="(item, i) in itemBooker">
                    <div
                      v-if="i % 43 === 0 && i > 1"
                      class="html2pdf__page-break"
                      v-bind:key="'bp' + i"
                    ></div>
                    <tr v-bind:key="'b' + i">
                      <td
                        v-for="(iteml, h) in headersBooker"
                        v-bind:key="'hl' + h"
                        style="font-size: 10px;padding: 5px"
                      >
                        <div
                          v-if="iteml.value === 'm_dateope'"
                          style="text-align: center"
                        >
                          {{ item[iteml.value] }}
                        </div>
                        <div
                          v-else-if="iteml.value === 'm_amount'"
                          style="text-align: right;padding-left: 5px"
                        >
                          {{
                            currency(item[iteml.value], {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </div>
                        <div v-else>
                          {{ item[iteml.value] }}
                        </div>
                      </td>
                    </tr>
                  </slot>
                </table>
              </div>
              <div class="html2pdf__page-break"></div>
              <div>
                <span style="font-weight: bolder;font-size: 14px;padding: 5px"
                  >Bon(s) Cadeau(x) encaissé(s)</span
                >
                <table class="details" style="width: 100%">
                  <tr>
                    <th v-for="(item, i) in headersBkdo" v-bind:key="'h' + i">
                      {{ item.text }}
                    </th>
                  </tr>
                  <slot v-for="(item, i) in itemBkdo">
                    <div
                      v-if="i % 50 === 0 && i > 1"
                      class="html2pdf__page-break"
                      v-bind:key="'bp' + i"
                    ></div>
                    <tr v-bind:key="'b' + i">
                      <td
                        v-for="(iteml, h) in headersBkdo"
                        v-bind:key="'hl' + h"
                        style="font-size: 10px;padding: 5px"
                      >
                        <div
                          v-if="iteml.value === 'm_dateope'"
                          style="text-align: center"
                        >
                          {{ item[iteml.value] }}
                        </div>
                        <div
                          v-else-if="iteml.value === 'm_amount'"
                          style="text-align: right;padding:5px"
                        >
                          {{
                            currency(item[iteml.value], {
                              symbol: '€',
                              separator: ' ',
                              decimal: ',',
                              pattern: '# !'
                            }).format()
                          }}
                        </div>
                        <div v-else>
                          {{ item[iteml.value] }}
                        </div>
                      </td>
                    </tr>
                  </slot>
                </table>
              </div>
            </div>
            <!--            </vue-html2pdf>-->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import VueHtml2pdf from 'vue-html2pdf'
import html2pdf from 'html2pdf.js'
import currency from 'currency.js'
import dayjs from 'dayjs'
export default {
  name: 'previewAdviseDetails',
  /*components: {
    VueHtml2pdf
  },*/
  props: {
    advise: {
      type: Object,
      default: () => null
    },
    directPrint: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayMode: 'preview',
      tab: 'booker',
      headersBooker: [
        {
          text: 'Nom',
          value: 'm_customerLastName',
          width: 100,
          sortable: false
        },
        {
          text: 'Prénom',
          value: 'm_customerFirstName',
          width: 100,
          sortable: false
        },
        { text: 'Encaissé le', value: 'm_dateope', width: 50, sortable: false },

        { text: 'Encaissé', value: 'm_amount', width: 50, sortable: false }
      ],
      headersBkdo: [
        {
          text: 'Code Cadeau',
          value: 'm_giftCode',
          width: 50,
          sortable: false
        },
        {
          text: 'Clients',
          value: 'm_customerLastName',
          width: 100,
          sortable: false
        },

        { text: 'Encaissé le', value: 'm_dateope', width: 50, sortable: false },
        {
          text: 'Libellé',
          value: 'm_info',
          width: 50,
          sortable: false
        },
        { text: 'montant', value: 'm_amount', width: 50, sortable: false }
      ]
    }
  },
  mounted() {
    if (this.directPrint) {
      this.generateReport()
    }
  },
  watch: {
    directPrint: {
      handler(val) {
        this.directPrint = val
        if (val) {
          this.generateReport()
        }
      },
      deep: true
    }
  },
  computed: {
    itemBooker() {
      const resp = this.advise.details.reduce((prev, cur) => {
        if (cur.m_gift === '0') {
          prev.push(cur)
        }
        return prev
      }, [])
      return resp
    },
    itemBkdo() {
      const resp = this.advise.details.reduce((prev, cur) => {
        if (cur.m_gift === '1') {
          prev.push(cur)
        }
        return prev
      }, [])
      return resp
    }
  },
  methods: {
    currency,
    getDate(val) {
      return dayjs(val).format('DD/MM/YYYY')
    },
    onProgress(val) {
      console.log(val)
    },
    generateTitle(numfacture, shopname) {
      return (
        'avisdepaiement' +
        '_' +
        numfacture +
        '_' +
        shopname.replace(' ', '-') +
        '.pdf'
      )
    },
    async generateReport() {
      this.displayMode = 'preview'
      // this.$refs.html2Pdf.generatePdf()
      const doc = document.getElementById('mypdf')
      console.log('pdf', doc)
      const config = {
        filename: this.generateTitle(
          this.advise.m_numfacture,
          this.advise.m_shopName
        ),
        margin: 1.5,
        pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
        jsPDF: {
          orientation: 'portrait',
          unit: 'cm',
          format: 'a4',
          compressPDF: true
        }
        // pdfCallback: pdfCallback
      }
      // const siret = this.$store.state.auth.company.ident
      const docpdpf = new html2pdf()
        .from(doc)
        .set(config)
        .toPdf()
        .get('pdf')
        .then(pdf => {
          var totalPages = pdf.internal.getNumberOfPages()

          for (let i = 1; i <= totalPages; i++) {
            // set footer to every page
            pdf.setPage(i)
            // set footer font
            pdf.setFontSize(10)
            // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
            pdf.text(
              'page ' + i + ' sur ' + totalPages,
              pdf.internal.pageSize.getWidth() - 3,
              pdf.internal.pageSize.getHeight() - 0.5
            )
            pdf.text(
              'Siret ' +
                this.$store.state.auth.company.identity.siret +
                ' Code Ape ' +
                this.$store.state.auth.company.identity.ape +
                ' NII ' +
                this.$store.state.auth.company.identity.nii,
              2,
              pdf.internal.pageSize.getHeight() - 0.5
            ) // you can add the line separator as an image, consult the docs below to properly set the place of the image
          }
        })
      await docpdpf.save()
      this.close()
      /* html2pdf(this.$refs.mypdf, {
        margin: 1,
        filename: 'document.pdf',
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
      })*/
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.pdf-item {
  padding: 20px;
}
.headersSociety td {
  font-size: 12px;
  font-family: sans-serif;
}
.details table,
tr,
th,
td {
  border-collapse: collapse;
  font-family: sans-serif;
}

.details th {
  background-color: #2f2833;
  color: white;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #2f2833;
  padding: 2px 8px;
}

.detail td {
  border: 1px solid #cccccc;
  padding: 2px 8px;
  line-height: 14px;
  font-size: 10px;
  color: #424242;
}

.details tr:nth-child(odd) {
  background-color: #efefef;
}
</style>
