<template>
  <div>
    <div v-if="error">
      <v-alert type="error">{{ $t('other.anErrorOccurred') }}</v-alert>
    </div>
    <div v-if="loading" class="text-center py-12">
      <v-progress-circular indeterminate />
    </div>
    <div v-else>
      <gifts-list
        :gifts="gifts"
        @refresh="loadData"
        :total-entries="totalEntries"
        @onUpdate="loadData"
        :data-loading="dataLoading"
      />
    </div>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import GiftsList from '@/components/booker/giftsList.vue'
import moment from 'moment'

export default {
  components: {
    GiftsList
  },
  data() {
    return {
      loading: true,
      dataLoading: true,
      error: false,
      perPage: 100
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    gifts() {
      return this.$store.state.transactions.gifts
    },
    totalEntries() {
      return this.$store.state.transactions.pages.count
    }
  },
  async mounted() {
    this.error = false
    this.loading = true

    const dStart = new Date()
    const dEnd = new Date()

    dStart.setMonth(dStart.getMonth() - 1)
    dEnd.setMonth(dEnd.getMonth() + 1)

    const dStartStr = moment(dStart).format('YYYY/MM/DD')
    const dEndStr = moment(dEnd).format('YYYY/MM/DD')

    this.loadData({
      start: dStartStr,
      end: dEndStr,
      page: 1
    })

    this.loading = false
  },
  methods: {
    async loadData(query = {}) {
      this.dataLoading = true
      try {
        await this.$store.dispatch('transactions/fetchGifts', {
          force: true,
          query: {
            start: query.start,
            end: query.end
          }
        })

        for (
          let p = 1;
          p <= this.$store.state.transactions.pages.count / 100;
          p++
        ) {
          await new Promise(resolve => setTimeout(resolve, 250))
          await this.$store.dispatch('transactions/fetchGifts', {
            force: true,
            query: {
              ...query,
              page: p
            },
            clean: p === 1
          })
        }
      } catch (err) {
        this.error = true
      }
      this.dataLoading = false
    },
    async refresh() {
      try {
        await this.$store.dispatch('transactions/fetchGifts', {
          force: true
        })
      } catch {
        //
      }
    }
  }
}
</script>

<style scoped></style>
