<template>
  <div>
    <file-explorer />
  </div>
</template>

<script>
import layoutDatabase from '@/layouts/layout-database.vue'
import FileExplorer from '@/components/vault/FileExplorer.vue'

export default {
  components: {
    FileExplorer
  },
  created() {
    this.$emit('update:layout', layoutDatabase)
  }
}
</script>

<style scoped></style>
