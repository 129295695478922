<template>
  <v-card>
    <v-app-bar flat color="black">
      <v-toolbar-title class="text-h5 white--text pl-0"
        >Acompte par lot
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="white" @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(100)">
            <div style="padding: 5px; text-align: center">100%</div>
          </v-sheet>
        </v-col>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(50)">
            <div style="padding: 5px; text-align: center">50%</div>
          </v-sheet>
        </v-col>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(25)">
            <div style="padding: 5px; text-align: center">25%</div>
          </v-sheet>
        </v-col>
        <v-col cols="3">
          <v-sheet class="prct" outlined @click="autoDeposit(10)">
            <div style="padding: 5px; text-align: center">10%</div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'depositBatch',
  methods: {
    autoDeposit(val) {
      console.log(val)
      this.$emit('deposit', val)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped></style>
