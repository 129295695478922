<template>
  <div>
    <v-bottom-navigation app v-model="value" fixed grow plain :ripple="false">
      <template v-for="(btn, index) in actions">
        <v-btn
          :key="index"
          icon
          plain
          :ripple="false"
          :value="index"
          :to="btn.to"
        >
          <v-icon>{{ btn.icon }}</v-icon>
        </v-btn>
      </template>
    </v-bottom-navigation>
  </div>
</template>

<script>
export default {
  props: {
    actions: { type: Array, default: () => [] }
  },
  data: () => {
    return {
      value: null
    }
  }
}
</script>

<style></style>
