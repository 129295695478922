<template>
  <div>
    <v-container class="py-8" fluid>
      <v-row>
        <v-col cols="12">
          <h2>Coupons de réduction</h2>
        </v-col>
        <v-col
          cols="12"
          md="3"
          style="
            background-color: #eee;
            border-radius: 4px;
            border: 1px solid #ccc;
          "
        >
          <v-row dense>
            <v-col cols="12">
              <v-btn tile color="primary" block to="/coupons/new">
                <v-icon left>mdi-plus</v-icon>
                Ajouter un coupon
              </v-btn>
              <!--              <v-btn tile block to="/coupons/batch">-->
              <!--                <v-icon left>mdi-circle-multiple</v-icon>-->
              <!--                Génération multiple-->
              <!--              </v-btn>-->
            </v-col>
            <v-col cols="12">
              <h3>Filtres</h3>
            </v-col>
            <v-col cols="12" v-if="filters.length">
              <div style="font-size: 12px; margin-top: 6px; margin-bottom: 6px">
                <div>
                  <strong>Filtres actifs : </strong>
                  <span v-for="(filter, fIndex) in filters" :key="filter"
                    ><span v-if="fIndex > 0">, </span>{{ filter }}</span
                  >
                </div>
                <div class="my-2">
                  <v-btn text x-small @click="clearFilters">
                    <v-icon x-small>mdi-close</v-icon>
                    Effacer tout
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="groupmentsList"
                outlined
                label="Groupement"
                hide-details
                v-model="filterGroupment"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-select
                :items="shopsList"
                v-model="filterShop"
                outlined
                label="Boutique"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="date"
                v-model="filterDateStart"
                outlined
                label="Date de réduction"
                hide-details
                clearable
                :max="filterDateEnd ? filterDateEnd : null"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="date"
                v-model="filterDateEnd"
                outlined
                label="Date de réduction"
                hide-details
                clearable
                :min="filterDateStart ? filterDateStart : null"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="text"
                v-model="filterCode"
                outlined
                label="Code de réduction"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-btn tile dark block @click="handleFilters"> Rechercher </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <div class="mb-3">
            <v-btn-toggle v-model="filterType" mandatory>
              <v-btn value="past">
                <v-icon color="black" left> mdi-square </v-icon>
                Passé
              </v-btn>
              <v-btn value="current">
                <v-icon color="green" left> mdi-circle </v-icon>
                En cours
              </v-btn>
              <v-btn value="next">
                <v-icon color="purple" left> mdi-triangle </v-icon>
                A venir
              </v-btn>
            </v-btn-toggle>
          </div>

          <v-data-table
            :items="filteredCoupons"
            :headers="headers"
            class="elevation-1"
            :items-per-page="couponsPerPage"
            :server-items-length="couponsTotal"
            :page="couponsPage"
            @update:page="fetchPage"
          >
            <template v-slot:item.code="{ item }">
              <div class="ab-code-container">
                <span class="ab-code" :class="'ab-color-' + filterType">{{
                  item.code
                }}</span>

                <span
                  v-if="item.reductionType === 'value'"
                  class="ab-code-append ab-code-blue"
                >
                  -{{ item.amount }}€
                </span>
                <span
                  v-else-if="item.reductionType === 'percentage'"
                  class="ab-code-append ab-code-purple"
                >
                  -{{ item.amount }}%
                </span>
              </div>
            </template>
            <template v-slot:item.dates="{ item }">
              <div>
                <v-icon small>mdi-calendar</v-icon>
                {{ dayjs(item.couponStartDate).format('DD/MM/YYYY') }}
                -
                {{ dayjs(item.couponEndDate).format('DD/MM/YYYY') }}
              </div>
              <div>
                <v-icon small>mdi-clock</v-icon>
                {{ item.couponStartHour.split(':').join('h') }}
                -
                {{ item.couponEndHour.split(':').join('h') }}
              </div>
            </template>
            <template v-slot:item.amount="{ item }">
              <span v-if="item.reductionType === 'value'" class="green--text">
                -{{ item.amount }}€
              </span>
              <span
                v-else-if="item.reductionType === 'percentage'"
                class="purple--text"
              >
                -{{ item.amount }}%
              </span>
            </template>
            <template v-slot:item.auto="{ item }">
              <span class="ab-badge ab-badge-yellow" v-if="item.autoApply">
                Auto
              </span>
              <span class="ab-badge ab-badge-blue" v-else> Manuel </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon :to="'/coupons/edit/' + item.id" small>
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>

              <v-btn icon @click="showCoupon = item" small>
                <v-icon small>mdi-eye</v-icon>
              </v-btn>

              <v-btn icon color="red" @click="deleteCoupon(item)" small>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent :value="true" v-if="showCoupon" max-width="500px">
      <v-card>
        <v-card-text>Voir un coupon</v-card-text>
        <v-card-text>
          <div>
            <strong>Code : </strong>
            <span>{{ showCoupon.code }}</span>
          </div>
          <div>
            <strong>Réduction : </strong>
            <span v-if="showCoupon.reductionType === 'value'"
              >-{{ getFormatCurrency(parseFloat(showCoupon.amount)) }}</span
            >
            <span v-else>-{{ showCoupon.amount }}%</span>
          </div>
          <div>
            <strong>Automatique : </strong>
            <span>{{ showCoupon.autoApply ? 'Oui' : 'Non' }}</span>
          </div>
          <div>
            <strong>Montant minimum : </strong>
            <span>{{
              getFormatCurrency(parseFloat(showCoupon.minAmount))
            }}</span>
          </div>
          <div>
            <strong>Date de début : </strong>
            <span>{{
              dayjs(showCoupon.couponStartDate).format('DD/MM/YYYY')
            }}</span>
          </div>
          <div>
            <strong>Date de fin : </strong>
            <span>{{
              dayjs(showCoupon.couponEndDate).format('DD/MM/YYYY')
            }}</span>
          </div>
          <div>
            <strong>Heure de début : </strong>
            <span>{{ showCoupon.couponStartHour }}</span>
          </div>
          <div>
            <strong>Heure de fin : </strong>
            <span>{{ showCoupon.couponEndHour }}</span>
          </div>
          <div v-if="showCoupon.groupments.length">
            <strong>Groupements : </strong>

            <div
              style="
                border: 1px solid #999;
                max-height: 300px;
                overflow-y: auto;
              "
              class="mb-3"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    style="
                      height: 25px;
                      padding: 14px 8px;
                      min-height: 25px;
                      max-height: 25px;
                    "
                  >
                    Groupements
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li
                        v-for="grp in groupmentsList.filter((g) =>
                          showCoupon.groupments.includes(g.value)
                        )"
                        :key="grp.text || 'Autre'"
                      >
                        {{ grp.text }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div v-if="showCoupon.geoGroupments.length">
            <strong>GéoGroupements : </strong>

            <div
              style="
                border: 1px solid #999;
                max-height: 300px;
                overflow-y: auto;
              "
              class="mb-3"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    style="
                      height: 25px;
                      padding: 14px 8px;
                      min-height: 25px;
                      max-height: 25px;
                    "
                  >
                    GéoGroupements
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li
                        v-for="grp in geogroupments.filter((g) =>
                          showCoupon.geoGroupments.includes(g.id)
                        )"
                        :key="grp.id || 'Autre'"
                      >
                        {{ grp.name.fr }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div v-if="showCoupon.shops.length">
            <strong>Boutiques : </strong>

            <div
              style="
                border: 1px solid #999;
                max-height: 300px;
                overflow-y: auto;
              "
              class="mb-3"
            >
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="grp in shopsByGroupment"
                  :key="grp.name || 'Autre'"
                >
                  <v-expansion-panel-header
                    style="
                      height: 25px;
                      padding: 14px 8px;
                      min-height: 25px;
                      max-height: 25px;
                    "
                  >
                    {{ grp.name || 'Autre' }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li v-for="shop in grp.shops" :key="shop.id">
                        {{ shop.name }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
          <div v-if="showCoupon.services.length">
            <strong>Services : </strong>

            <div
              style="
                border: 1px solid #999;
                max-height: 300px;
                overflow-y: auto;
              "
              class="mb-3"
            >
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="family in servicesByFamily"
                  :key="family.name || 'Autre'"
                >
                  <v-expansion-panel-header
                    style="
                      height: 25px;
                      padding: 14px 8px;
                      min-height: 25px;
                      max-height: 25px;
                    "
                  >
                    {{ family.name || 'Autre' }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li v-for="service in family.services" :key="service.id">
                        {{ service.name.fr }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showCoupon = null" text> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import currency from 'currency.js'

export default {
  components: {},
  data() {
    return {
      coupons: [],
      couponsPerPage: 0,
      couponsPage: 1,
      couponsTotal: 0,
      currentCoupons: [],
      currentCouponsPerPage: 0,
      currentCouponsPage: 1,
      currentCouponsTotal: 0,
      pastCoupons: [],
      pastCouponsPerPage: 0,
      pastCouponsPage: 1,
      pastCouponsTotal: 0,
      nextCoupons: [],
      nextCouponsPerPage: 0,
      nextCouponsPage: 1,
      nextCouponsTotal: 0,
      filteredCoupons: [],
      headers: [
        { value: 'code', text: 'Code' },
        { value: 'dates', text: 'Date de réduction' },
        // { value: 'amount', text: 'Réduction', align: 'right' },
        { value: 'auto', text: 'Type' },
        { value: 'actions', text: 'Actions', align: 'right' },
      ],
      filterGroupment: null,
      filterShop: null,
      filterDateStart: null,
      filterDateEnd: null,
      filterCode: null,
      filterType: 'current',
      showCoupon: null,
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    ...mapGetters({
      geogroupments: 'geogroupments/getGeogroupments',
    }),
    shops: function () {
      return this.$store.getters['shop/fusionShop'](this.shopsFiltered)
    },
    abGroupments() {
      return this.shops.reduce((acc, cur) => {
        const index = acc.findIndex((grp) => grp.id === cur.grp.id)

        if (index === -1) {
          acc.push(cur.grp)
        }

        return acc
      }, [])
    },
    groupmentsList() {
      return this.abGroupments.map((g) => ({
        value: g.id,
        text: g.name,
      }))
    },
    shopsList() {
      return this.shops.map((s) => ({
        value: s.abId,
        text: s.name,
      }))
    },
    services() {
      return this.$store.getters['articles/fusionArticle']().filter((a) =>
        [1, 2, 4].includes(parseInt(a.art_type))
      )
    },
    shopsByGroupment() {
      return this.shops
        .filter((s) => this.showCoupon.shops.includes(s.abId))
        .reduce((acc, cur) => {
          const grpIndex = acc.findIndex(
            (g) => g.id.toString() === (cur.grp ? cur.grp.id.toString() : -1)
          )

          if (grpIndex === -1) {
            acc.push({
              id: cur.grp.id,
              name: cur.grp.name,
              shops: [cur],
            })
          } else {
            acc[grpIndex].shops.push(cur)
          }

          return acc
        }, [])
    },
    servicesByFamily() {
      return this.services
        .filter((s) => this.showCoupon.services.includes(s.id))
        .reduce((acc, cur) => {
          const grpIndex = acc.findIndex(
            (g) => g.name.toString() === cur.family
          )

          if (grpIndex === -1) {
            acc.push({
              name: cur.family,
              services: [cur],
            })
          } else {
            acc[grpIndex].services.push(cur)
          }

          return acc
        }, [])
    },
    filters() {
      const fs = []

      if (this.filterGroupment) {
        fs.push('Groupement')
      }
      if (this.filterShop) {
        fs.push('Boutique')
        fs.push('Boutique')
      }
      if (this.filterDateStart && this.filterDateEnd) {
        fs.push('Dates')
      }
      if (this.filterCode) {
        fs.push('Code')
      }

      return fs
    },
  },
  watch: {
    filterType(v) {
      this.switchToType(v)
    },
  },
  async mounted() {
    await this.fetchCurrentCoupons()
    await this.fetchPastCoupons()
    await this.fetchNextCoupons()

    this.coupons = this.currentCoupons
    this.couponsPerPage = this.currentCouponsPerPage
    this.couponsPage = this.currentCouponsPage
    this.couponsTotal = this.currentCouponsTotal
    this.filteredCoupons = this.coupons

    try {
      await this.$store.dispatch('geogroupments/fetchGeogroupments', {})
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    dayjs,
    getFormatCurrency(val) {
      return currency(val, {
        symbol: '€',
        decimal: ',',
        pattern: ' # !',
        negativePattern: '- # !',
      }).format()
    },
    switchToType(type) {
      if (type === 'past') {
        this.coupons = this.pastCoupons
        this.couponsPerPage = this.pastCouponsPerPage
        this.couponsPage = this.pastCouponsPage
        this.couponsTotal = this.pastCouponsTotal
        this.filteredCoupons = this.coupons
      } else if (type === 'current') {
        this.coupons = this.currentCoupons
        this.couponsPerPage = this.currentCouponsPerPage
        this.couponsPage = this.currentCouponsPage
        this.couponsTotal = this.currentCouponsTotal
        this.filteredCoupons = this.coupons
      } else if (type === 'next') {
        this.coupons = this.nextCoupons
        this.couponsPerPage = this.nextCouponsPerPage
        this.couponsPage = this.nextCouponsPage
        this.couponsTotal = this.nextCouponsTotal
        this.filteredCoupons = this.coupons
      }
    },
    async fetchPage(p) {
      await Promise.all([
        this.fetchPastCoupons(p),
        this.fetchCurrentCoupons(p),
        this.fetchNextCoupons(p),
      ])

      this.switchToType(this.filterType)
    },
    async fetchCurrentCoupons(page = 1) {
      try {
        const couponsResponse = await MKPSDK.Coupons.getAllCoupons(
          'current',
          page
        )
        this.currentCoupons = couponsResponse.coupons
        this.currentCouponsPerPage = couponsResponse.perPage
        this.currentCouponsPage = couponsResponse.page
        this.currentCouponsTotal = couponsResponse.nbCoupons
      } catch (e) {
        console.log(e)
      }
    },
    async fetchPastCoupons(page = 1) {
      try {
        const couponsResponse = await MKPSDK.Coupons.getAllCoupons('past', page)
        this.pastCoupons = couponsResponse.coupons
        this.pastCouponsPerPage = couponsResponse.perPage
        this.pastCouponsPage = couponsResponse.page
        this.pastCouponsTotal = couponsResponse.nbCoupons
      } catch (e) {
        console.log(e)
      }
    },
    async fetchNextCoupons(page = 1) {
      try {
        const couponsResponse = await MKPSDK.Coupons.getAllCoupons('next', page)
        this.nextCoupons = couponsResponse.coupons
        this.nextCouponsPerPage = couponsResponse.perPage
        this.nextCouponsPage = couponsResponse.page
        this.nextCouponsTotal = couponsResponse.nbCoupons
      } catch (e) {
        console.log(e)
      }
    },
    getGroupmentsList(groupmentsIds) {
      return this.geogroupments
        .filter((g) => groupmentsIds.includes(g.id))
        .map((g) => g.name.fr)
        .join(', ')
    },
    getShopsList(shopsIds) {
      return this.shops
        .filter((s) => shopsIds.includes(s.abId))
        .map((s) => s.name)
        .join(', ')
    },
    async deleteCoupon(item) {
      if (!confirm('Voulez-vous supprimer cette fermeture ?')) {
        return
      }

      await MKPSDK.Coupons.deleteCoupon(item.id)

      this.coupons = this.coupons.filter((c) => c.id !== item.id)
      this.filteredCoupons = this.filteredCoupons.filter(
        (c) => c.id !== item.id
      )
    },
    clearFilters() {
      this.filterGroupment = null
      this.filterShop = null
      this.filterDateStart = null
      this.filterDateEnd = null
      this.filterCode = null
    },
    handleFilters() {
      this.filteredCoupons = this.coupons.filter((c) => {
        if (
          !this.filterGroupment &&
          !this.filterShop &&
          !this.filterDateStart &&
          !this.filterDateEnd &&
          !this.filterCode
        ) {
          return true
        }

        if (
          this.filterGroupment &&
          c.groupments.includes(this.filterGroupment)
        ) {
          return true
        }
        if (this.filterShop && c.shops.includes(this.filterShop)) {
          return true
        }

        if (this.filterCode && c.code.includes(this.filterCode)) {
          return true
        }

        if (this.filterDateStart && this.filterDateEnd) {
          const fds = dayjs(this.filterDateStart).startOf('day')
          const fde = dayjs(this.filterDateEnd).endOf('day')
          const sd = dayjs(c.couponStartDate).startOf('day')
          const ed = dayjs(c.couponEndDate).endOf('day')

          if (!(fds.isAfter(ed) || fde.isBefore(sd))) {
            return true
          }
        }

        return false
      })
    },
  },
}
</script>

<style scoped>
.ab-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 15px;
  border-radius: 100px;
  background-color: #eeeeee;
  color: #333333;
  font-size: 10px;
  text-transform: uppercase;
}

.ab-badge.ab-badge-yellow {
  background-color: #ffeda9;
  color: #333333;
}

.ab-badge.ab-badge-blue {
  background-color: #efbebe;
  color: #333333;
}

.ab-code-container {
  display: flex;
  align-items: center;
}

.ab-code {
  display: inline-block;
  padding: 4px 8px;
  background-color: #eeeeee;
  border-left: 8px solid #cccccc;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.ab-code-append {
  display: inline-block;
  padding: 4px 8px 4px 12px;
  background-color: #eeeeee;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.ab-code-append:before {
  display: block;
  content: '';
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) translateX(calc(-50% - 1px));
  border-radius: 100%;
}

.ab-code-blue {
  background-color: #87c7f1;
  border-left: 2px dotted #63a2cb;
}
.ab-code-purple {
  background-color: #eacfff;
  border-left: 2px dotted #be99d7;
}

.ab-color-past {
  border-color: #424242;
}
.ab-color-current {
  border-color: #b9c57e;
}
.ab-color-next {
  border-color: #c587b3;
}
</style>
