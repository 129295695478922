<template>
  <div>
    <v-container class="py-8">
      <v-row>
        <v-col cols="12">
          <h2>Fermetures exceptionnelles</h2>
        </v-col>
        <v-col cols="12" md="3">
          <v-row dense>
            <v-col cols="12">
              <v-btn tile color="primary" block to="/closeddays/new">
                <v-icon left>mdi-plus</v-icon>
                Ajouter une fermeture
              </v-btn>
            </v-col>
            <v-col cols="12">
              <h3>Filtres</h3>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="groupmentsList"
                outlined
                label="Groupement"
                hide-details
                v-model="filterGroupment"
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-select
                :items="shopsList"
                v-model="filterShop"
                outlined
                label="Boutique"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="date"
                v-model="filterDate"
                outlined
                label="Date de fermeture"
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12">
              <v-btn tile dark block @click="handleFilters"> Rechercher </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="9">
          <v-data-table
            :items="filteredClosedDays"
            :headers="headers"
            class="elevation-1"
          >
            <template v-slot:item.dates="{ item }">
              Du {{ dayjs(item.dateStart).format('DD/MM/YYYY') }}<br />
              Au {{ dayjs(item.dateEnd).format('DD/MM/YYYY') }}
            </template>
            <template v-slot:item.range="{ item }">
              <span v-if="item.range === 'all'"> Fermeture générale </span>
              <span v-else-if="item.range === 'groupment'">
                Groupements<br />
                {{ getGroupmentsList(item.groupments) }}
              </span>
              <span v-else-if="item.range === 'shop'">
                Boutiques<br />
                {{ getShopsList(item.shops) }}
              </span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn icon color="red" @click="deleteClosedDay(item)" small>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

export default {
  components: {},
  data() {
    return {
      closedDays: [],
      filteredClosedDays: [],
      headers: [
        { value: 'dates', text: 'Date de fermeture' },
        { value: 'range', text: 'Rayon de fermeture' },
        { value: 'actions', text: 'Actions', align: 'right' },
      ],
      filterGroupment: null,
      filterShop: null,
      filterDate: null,
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    ...mapGetters({
      geogroupments: 'geogroupments/getGeogroupments',
    }),
    shops: function () {
      return this.$store.getters['shop/fusionShop'](this.shopsFiltered)
    },
    groupmentsList() {
      return this.geogroupments.map((g) => ({
        value: g.id,
        text: g.name.fr,
      }))
    },
    shopsList() {
      return this.shops.map((s) => ({
        value: s.abId,
        text: s.name,
      }))
    },
  },
  async mounted() {
    this.fetchClosedDays()

    try {
      await this.$store.dispatch('geogroupments/fetchGeogroupments', {})
    } catch (err) {
      console.log('err :>> ', err)
    }
  },
  methods: {
    dayjs,
    async fetchClosedDays() {
      try {
        const closedDaysResponse = await MKPSDK.ClosedDays.getAllClosedDays()
        this.closedDays = closedDaysResponse.closedDays
        this.filteredClosedDays = closedDaysResponse.closedDays
      } catch (e) {
        console.log(e)
      }
    },
    getGroupmentsList(groupmentsIds) {
      return this.geogroupments
        .filter((g) => groupmentsIds.includes(g.id))
        .map((g) => g.name.fr)
        .join(', ')
    },
    getShopsList(shopsIds) {
      return this.shops
        .filter((s) => shopsIds.includes(s.abId))
        .map((s) => s.name)
        .join(', ')
    },
    async deleteClosedDay(item) {
      if (!confirm('Voulez-vous supprimer cette fermeture ?')) {
        return
      }

      await MKPSDK.ClosedDays.deleteClosedDay(item.id)

      this.closedDays = this.closedDays.filter((c) => c.id !== item.id)
      this.filteredClosedDays = this.filteredClosedDays.filter(
        (c) => c.id !== item.id
      )
    },
    handleFilters() {
      this.filteredClosedDays = this.closedDays.filter((c) => {
        if (!this.filterGroupment && !this.filterShop && !this.filterDate) {
          return true
        }

        if (
          this.filterGroupment &&
          c.groupments.includes(this.filterGroupment)
        ) {
          return true
        }
        if (this.filterShop && c.shops.includes(this.filterShop)) {
          return true
        }

        if (this.filterDate) {
          const fd = dayjs(this.filterDate)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
          const sd = dayjs(c.dateStart)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
          const ed = dayjs(c.dateEnd).hour(0).minute(0).second(0).millisecond(0)

          if (
            fd.isSame(sd) ||
            fd.isSame(ed) ||
            (fd.isAfter(sd) && fd.isBefore(ed))
          ) {
            return true
          }
        }

        return false
      })
    },
  },
}
</script>

<style scoped></style>
