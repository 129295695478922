<template>
  <div style="display: flex; flex-wrap: wrap-reverse">
    <div
      v-if="displayButton"
      style="
        min-width: 250px;
        padding: 5px;
        margin-top: auto;
        margin-bottom: auto;
      "
    >
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon :color="iconSel().color" v-bind="attrs" v-on="on">{{
            iconSel().icon
          }}</v-icon>
          <v-btn text small :color="iconSel().color" v-bind="attrs" v-on="on">
            <span style="max-width: 100px; padding: 2px">{{
              iconSel().text
            }}</span>
          </v-btn>
        </template>
        <v-btn-toggle v-model="currentp" light>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="toDay()" v-bind="attrs" v-on="on">
                <v-icon color="blue">mdi-calendar</v-icon>
              </v-btn>
            </template>
            <span>Aujourd'hui</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="yesterday()" v-bind="attrs" v-on="on">
                <v-icon color="red">mdi-calendar</v-icon>
              </v-btn>
            </template>
            <span>Hier</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="currentWeek()" v-bind="attrs" v-on="on">
                <v-icon color="blue">mdi-calendar-week</v-icon>
              </v-btn>
            </template>
            <span>Semaine en cours</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="lastSevenDay()" v-bind="attrs" v-on="on">
                <v-icon color="red">mdi-calendar-week</v-icon>
              </v-btn>
            </template>
            <span>Semaine flottante</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="previousWeek()" v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-calendar-week</v-icon>
              </v-btn>
            </template>
            <span>Semaine précédente</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="currentMonth()" v-bind="attrs" v-on="on">
                <v-icon color="blue">mdi-calendar-month</v-icon>
              </v-btn>
            </template>
            <span>Mois en cours</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="last30Days()" v-bind="attrs" v-on="on">
                <v-icon color="red">mdi-calendar-month</v-icon>
              </v-btn>
            </template>
            <span>30 dernier jours</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="previousMonth()" v-bind="attrs" v-on="on">
                <v-icon color="black">mdi-calendar-month</v-icon>
              </v-btn>
            </template>
            <span>Mois précédent</span>
          </v-tooltip>
        </v-btn-toggle>
      </v-menu>
    </div>
    <div style="display: flex; padding: 5px">
      <div v-if="backButton">
        <button class="btn elevation-1" @click="back()">
          <i class="mdi mdi-arrow-left"></i>
        </button>
      </div>
      <div style="min-width: 150px; margin-top: auto; margin-bottom: auto">
        <input
          style="width: 150px; margin-left: auto; font-size: 1em"
          @input="changeDate"
          @keydown="currentp = -1"
          class="dateRange"
          type="date"
          v-model="dStart"
          :max="dEnd"
          id="start"
        />
      </div>
      <div style="min-width: 150px; margin-top: auto; margin-bottom: auto">
        <input
          style="
            width: 150px;
            margin-right: auto;
            margin-left: 5px;
            font-size: 1em;
          "
          @input="changeDate"
          @keydown="currentp = -1"
          class="dateRange"
          type="date"
          v-model="dEnd"
          :min="dStart"
          id="end"
        />
      </div>
      <div v-if="backButton">
        <button class="btn elevation-1" @click="donePeriod()">
          <i class="mdi mdi-magnify"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  name: 'DateRange',
  props: {
    period: {
      type: Object,
      default: null,
    },
    displayButton: {
      type: Boolean,
      default: () => true,
    },
    actionButton: {
      type: Boolean,
      default: () => false,
    },
    backButton: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dStart: null,
      dEnd: null,
      currentp: null,
    }
  },
  mounted() {
    if (!this.period) {
      this.dStart = this.formatShortDate(new Date())
      this.dEnd = this.formatShortDate(new Date())
    } else {
      const p = JSON.parse(JSON.stringify(this.period))
      this.dStart = this.formatShortDate(p.start)
      this.dEnd = this.formatShortDate(p.end)
    }
    this.currentp = 0
  },
  methods: {
    back() {
      this.$emit('back')
    },
    donePeriod() {
      const p = {
        start: this.dStart.replaceAll('-', ''),
        end: this.dEnd.replaceAll('-', ''),
      }

      this.$emit('period', p)
    },
    formatShortDate(val) {
      if (val) {
        return dayjs(val).format('YYYY-MM-DD')
      } else {
        return ''
      }
    },
    yesterday() {
      const myd = dayjs().subtract(1, 'days').format('YYYY-MM-DD')
      this.dStart = myd
      this.dEnd = myd
      this.changeDate('yesterday')
    },
    toDay() {
      const myd = dayjs().format('YYYY-MM-DD')
      this.dStart = myd
      this.dEnd = myd
      this.changeDate('today')
    },
    lastSevenDay() {
      const ds = dayjs().subtract(6, 'days').format('YYYY-MM-DD')
      const de = dayjs().format('YYYY-MM-DD')
      this.dStart = ds
      this.dEnd = de
      this.changeDate('current')
    },
    previousWeek() {
      const ds = dayjs().subtract('1', 'week').day(1).format('YYYY-MM-DD')
      const de = dayjs()
        .subtract('1', 'week')
        .day(6)
        .add(1, 'day')
        .format('YYYY-MM-DD')
      this.dStart = ds
      this.dEnd = de
      this.changeDate('current')
    },
    currentWeek() {
      const ds = dayjs().day(1).format('YYYY-MM-DD')

      const de = dayjs().day(6).add(1, 'day').format('YYYY-MM-DD')
      this.dStart = ds
      this.dEnd = de
      this.changeDate('current')
    },
    currentMonth() {
      const ds = dayjs().date(1).format('YYYY-MM-DD')

      const de = dayjs().date(dayjs().daysInMonth()).format('YYYY-MM-DD')
      this.dStart = ds
      this.dEnd = de
      this.changeDate('month')
    },
    previousMonth() {
      const ds = dayjs().subtract(1, 'month').date(1).format('YYYY-MM-DD')

      const de = dayjs()
        .subtract(1, 'month')
        .date(dayjs().subtract(1, 'month').daysInMonth())
        .format('YYYY-MM-DD')
      this.dStart = ds
      this.dEnd = de
      this.changeDate('current')
    },
    last30Days() {
      const ds = dayjs().subtract(30, 'days').format('YYYY-MM-DD')
      const de = dayjs().format('YYYY-MM-DD')
      this.dStart = ds
      this.dEnd = de
      this.changeDate('current')
    },
    changeDate(type) {
      this.$emit('updateDate', {
        start: this.dStart,
        end: this.dEnd,
        type: type,
      })
    },
    iconSel() {
      let icoSel = null
      switch (this.currentp) {
        case 0:
          icoSel = { icon: 'mdi-calendar', color: 'blue', text: "Aujourd'hui" }
          break
        case 1:
          icoSel = { icon: 'mdi-calendar', color: 'red', text: 'Hier' }
          break
        case 2:
          icoSel = {
            icon: 'mdi-calendar-week',
            color: 'blue',
            text: 'Semaine en cours',
          }
          break
        case 3:
          icoSel = {
            icon: 'mdi-calendar-week',
            color: 'red',
            text: '7 derniers jours',
          }
          break
        case 4:
          icoSel = {
            icon: 'mdi-calendar-week',
            color: 'black',
            text: 'Semaine précédente',
          }
          break
        case 5:
          icoSel = {
            icon: 'mdi-calendar-month',
            color: 'blue',
            text: 'Mois en cours',
          }
          break
        case 6:
          icoSel = {
            icon: 'mdi-calendar-month',
            color: 'red',
            text: '30 derniers jours',
          }
          break
        case 7:
          icoSel = {
            icon: 'mdi-calendar-month',
            color: 'black',
            text: 'Mois précédent',
          }
          break
        default:
          icoSel = {
            icon: 'mdi-calendar',
            color: 'black',
            text: 'Personnalisée',
          }
      }
      return icoSel
    },
  },
}
</script>

<style scoped>
.dateRange {
  display: block;
  font-size: 0.8rem;
  background-color: white;
  border: solid 1px black;
  padding: 4px;
}
.btn {
  background-color: #f1f1f1;
  color: black;
  font-size: 1em;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  margin: 3px;
}
.btn:hover {
  background-color: #c3c3c3;
}
</style>
