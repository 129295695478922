<template>
  <div>
    <module-list fullwidth>
      <template v-slot:actionBlock>
        <div style="display: flex; flex-direction: row; align-items: center">
          <v-menu
            ref="menuStart"
            v-model="dStartMenu"
            :close-on-content-click="false"
            :return-value.sync="dStartStr"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="mr-3"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dStartStr"
                :label="$t('booker.since')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dStartStr" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dStartMenu = false">
                {{ $t('icons.cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menuStart.save(dStartStr)
                  updateDate()
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            ref="menuEnd"
            v-model="dEndMenu"
            :close-on-content-click="false"
            :return-value.sync="dEndStr"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="ml-3"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dEndStr"
                :label="$t('booker.until')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dEndStr" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="dEndMenu = false">
                {{ $t('icons.cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menuEnd.save(dEndStr)
                  updateDate()
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <v-btn @click="refresh" icon large outlined text>
          <v-icon>mdi-refresh</v-icon>
        </v-btn>

        <v-btn-toggle v-model="filterType" mandatory>
          <v-btn value="all"> {{ $t('other.all') }} </v-btn>
          <v-btn value="1"> EdenPass </v-btn>
          <v-btn value="2"> ArteBeaute </v-btn>
        </v-btn-toggle>
      </template>

      <template v-slot:actionSearch>
        <v-text-field
          solo
          :label="$t('other.research')"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
        ></v-text-field>
      </template>

      <template v-slot:contentBlock>
        <v-data-table
          class="table-one elevation-1"
          fixed-header
          :headers="headers"
          :items="filteredGifts"
          :items-per-page="itemsPerPage"
          :page.sync="page"
          :hide-default-footer="$vuetify.breakpoint.mdAndUp"
          item-key="m_Code"
          @page-count="pageCount = $event"
          :loading="dataLoading"
        >
          <template v-slot:[`item.amount`]="{ item }">
            <span v-if="item.m_credited">
              {{ item.m_credited.toFixed(2) }}€
            </span>
            <span v-else>NaN€</span>
          </template>
          <template v-slot:[`item.paid`]="{ item }">
            <span v-if="item.details && item.details.amount">
              {{ (item.details.amount.finalPriceTTC / 100).toFixed(2) }}€
            </span>
            <span v-else>NaN€</span>
          </template>
          <template v-slot:[`item.from`]="{ item }">
            <div
              :style="'background-color:' + getGiftFrom(item.m_origin).color"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
              "
            >
              {{ getGiftFrom(item.m_origin).text }}
            </div>
          </template>
          <!--          <template v-slot:[`item.refunded`]="{ item }">-->
          <!--            <span v-if="item.details && item.details.refunded">-->
          <!--              {{ (item.details.refunded / 100).toFixed(2) }}€-->
          <!--            </span>-->
          <!--            <span v-else>-</span>-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.fees`]="{ item }">-->
          <!--            <span v-if="item.details && item.details.fees">-->
          <!--              {{ (item.details.fees / 100).toFixed(2) }}€-->
          <!--            </span>-->
          <!--            <span v-else>-</span>-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.provider`]="{ item }">-->
          <!--            <FreeLogo v-if="item.paymentProvider === 'free'" />-->
          <!--            <PayZenLogo v-if="item.paymentProvider === 'payzen'" />-->
          <!--            <StripeLogo v-if="item.paymentProvider === 'stripe'" />-->
          <!--          </template>-->
          <template v-slot:[`item.customer`]="{ item }">
            <div v-if="item.details.customer">
              <span v-if="item.details.customer.firstname">
                {{ item.details.customer.firstname }}
              </span>
              <span v-if="item.details.customer.lastname">
                {{ item.details.customer.lastname.toUpperCase() }}
              </span>
              <div>
                <span
                  style="font-size: 12px"
                  v-if="item.details.customer.email"
                >
                  {{ item.details.customer.email }}
                </span>
              </div>
            </div>
            <div v-else>{{ $t('booker.notFound') }}</div>
          </template>
          <template v-slot:[`item.beneficiary`]="{ item }">
            {{ item.m_beneficiary }}
          </template>
          <template v-slot:[`item.code`]="{ item }">
            {{ item.m_Code }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click="cancelGift(item)"
              :disabled="
                !!item.mTabGiftOperation.length || parseInt(item.m_status) === 3
              "
              text
              color="red"
            >
              {{ $t('icons.cancel') }}
            </v-btn>
          </template>
          <!--          <template v-slot:[`item.type`]="{ item }">-->
          <!--            <div-->
          <!--              :style="{ backgroundColor: getType(item).color }"-->
          <!--              style="-->
          <!--                width: 100%;-->
          <!--                height: 100%;-->
          <!--                display: flex;-->
          <!--                justify-content: center;-->
          <!--                align-items: center;-->
          <!--                padding: 0 10px;-->
          <!--              "-->
          <!--            >-->
          <!--              {{ getType(item).name }}-->
          <!--            </div>-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.cstatus`]="{ item }">-->
          <!--            <v-chip-->
          <!--              color="green"-->
          <!--              v-if="getStatus(item.status) === 'paid'"-->
          <!--              style="font-size: 12px; height: 18px"-->
          <!--              dark-->
          <!--            >-->
          <!--              {{ 'Terminé'.toUpperCase() }}-->
          <!--            </v-chip>-->
          <!--            <v-chip-->
          <!--              color="gray"-->
          <!--              v-else-if="getStatus(item.status) === 'waiting'"-->
          <!--              style="font-size: 12px; height: 18px"-->
          <!--              dark-->
          <!--            >-->
          <!--              {{ 'attente'.toUpperCase() }}-->
          <!--            </v-chip>-->
          <!--            <v-chip-->
          <!--              color="orange"-->
          <!--              v-else-if="getStatus(item.status) === 'refunded'"-->
          <!--              style="font-size: 12px; height: 18px"-->
          <!--              dark-->
          <!--            >-->
          <!--              {{ 'remboursé'.toUpperCase() }}-->
          <!--            </v-chip>-->
          <!--            <v-chip-->
          <!--              color="purple"-->
          <!--              v-else-if="getStatus(item.status) === 'waiting_refund'"-->
          <!--              style="font-size: 12px; height: 18px"-->
          <!--              dark-->
          <!--            >-->
          <!--              {{ 'remboursement en attente'.toUpperCase() }}-->
          <!--            </v-chip>-->
          <!--            <v-chip-->
          <!--              color="red"-->
          <!--              v-else-->
          <!--              style="font-size: 12px; height: 18px"-->
          <!--              dark-->
          <!--            >-->
          <!--              {{ 'echec'.toUpperCase() }}-->
          <!--            </v-chip>-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.cbooking`]="{ item }">-->
          <!--            <v-btn type="button" @click="showBookingOrder(item)" small>-->
          <!--              Voir-->
          <!--            </v-btn>-->
          <!--            <v-btn-->
          <!--              v-if="item.status === 'waiting_refund'"-->
          <!--              type="button"-->
          <!--              @click="acceptRefund(item)"-->
          <!--              small-->
          <!--              class="ml-2"-->
          <!--            >-->
          <!--              Accepter le remboursement-->
          <!--            </v-btn>-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.cservices`]="{ item }">-->
          <!--            <div style="font-size: 12px">-->
          <!--              {{ getShopName(item) }}-->
          <!--            </div>-->
          <!--            <div-->
          <!--              v-for="(service, serviceIndex) in getServicesNames(item)"-->
          <!--              :key="'si-' + serviceIndex"-->
          <!--            >-->
          <!--              {{ service }}-->
          <!--            </div>-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.datePaymentCall`]="{ item }">-->
          <!--            <span v-if="item.datePaymentCall">-->
          <!--              {{-->
          <!--                moment(item.datePaymentCall)-->
          <!--                  .locale('fr')-->
          <!--                  .format('dddd DD MMMM YYYY')-->
          <!--              }}-->
          <!--              <br />-->
          <!--              <span style="font-size: 12px">-->
          <!--                {{-->
          <!--                  moment(item.datePaymentCall).locale('fr').format('h:mm:ss')-->
          <!--                }}-->
          <!--              </span>-->
          <!--            </span>-->
          <!--          </template>-->
          <!--          <template v-slot:[`item.datePaymentResponse`]="{ item }">-->
          <!--            <span v-if="item.datePaymentResponse">-->
          <!--              {{-->
          <!--                moment(item.datePaymentResponse)-->
          <!--                  .locale('fr')-->
          <!--                  .format('dddd DD MMMM YYYY')-->
          <!--              }}-->
          <!--              <br />-->
          <!--              <span style="font-size: 12px">-->
          <!--                {{-->
          <!--                  moment(item.datePaymentResponse)-->
          <!--                    .locale('fr')-->
          <!--                    .format('h:mm:ss')-->
          <!--                }}-->
          <!--              </span>-->
          <!--            </span>-->
          <!--          </template>-->
        </v-data-table>
      </template>

      <template v-slot:footerBlock>
        <div class="d-flex">
          <!--          <div class="mr-10">-->
          <!--            <v-btn-toggle>-->
          <!--              <v-btn @click="itemsPerPage = 10">10</v-btn>-->
          <!--              <v-btn @click="itemsPerPage = 25">25</v-btn>-->
          <!--              <v-btn @click="itemsPerPage = 50">50</v-btn>-->
          <!--              <v-btn @click="itemsPerPage = 100">100</v-btn>-->
          <!--            </v-btn-toggle>-->
          <!--          </div>-->
          <v-pagination
            total-visible="10"
            circle
            v-if="pageCount > 1"
            v-model="page"
            :length="pageCount"
          ></v-pagination>
        </div>
      </template>
    </module-list>
    <v-overlay
      style="display: flex; justify-content: center; align-items: center"
      v-if="loading"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import moment from 'moment'
import swal from 'sweetalert'

import ModuleList from '@/components/general/moduleList.vue'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
// import {
//   MKPSDK,
// } from '@/packages/artebeaute-sdk/src'

export default {
  components: {
    ModuleList,
  },
  props: {
    gifts: {
      type: Array,
      default: () => [],
    },
    totalEntries: {
      type: Number,
      default: 0,
    },
    dataLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      filterStatus: 'all',
      filterType: 'all',
      viewBookingOrder: null,
      page: 1,
      itemsPerPage: 50,
      search: '',
      dStartStr: '',
      dStartMenu: false,
      dEndStr: '',
      dEndMenu: false,
      pageCount: Math.ceil(this.totalEntries / this.itemsPerPage),
    }
  },
  created() {
    const dStart = new Date()
    const dEnd = new Date()

    dStart.setMonth(dStart.getMonth() - 1)
    dEnd.setMonth(dEnd.getMonth() + 1)

    this.dStartStr = moment(dStart).format('YYYY-MM-DD')
    this.dEndStr = moment(dEnd).format('YYYY-MM-DD')
  },
  watch: {
    page() {
      // this.dStartMenu = false
      // this.dEndMenu = false
      //
      // const dStartStr = moment(this.dStartStr).format('YYYY/MM/DD')
      // const dEndStr = moment(this.dEndStr).format('YYYY/MM/DD')
      //
      // this.$emit('onUpdate', {
      //   start: dStartStr,
      //   end: dEndStr,
      //   page: this.page,
      // })
    },
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('booker.num'),
          value: 'm_Id',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t('booker.buyer'),
          value: 'customer',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t('booker.recipient'),
          value: 'beneficiary',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t('booker.value'),
          value: 'amount',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t('booker.code'),
          value: 'code',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t('booker.origin'),
          value: 'from',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true,
        },
        {
          text: this.$t('database.tables.actions'),
          value: 'actions',
          width: 100,
          divider: false,
          sortable: true,
          filterable: true,
        },
      ]
    },
    filteredGifts() {
      let gifts = this.gifts.slice()

      gifts = gifts.reduce((acc, cur) => {
        const fI = acc.findIndex((i) => i.id === cur.id)

        if (fI === -1) {
          acc.push(cur)
        }

        return acc
      }, [])

      if (this.filterType !== 'all') {
        gifts = gifts.filter(
          (t) =>
            parseInt(this.filterType) === this.getGiftFrom(t.m_origin).value
        )
      }

      if (this.search) {
        gifts = gifts.filter((i) => {
          let match = false

          // Buyer
          if (i.details.customer) {
            if (
              i.details.customer.firstname &&
              i.details.customer.firstname
                .toLowerCase()
                .includes(this.search.toLowerCase())
            ) {
              match = true
            }
            if (
              i.details.customer.lastname &&
              i.details.customer.lastname
                .toLowerCase()
                .includes(this.search.toLowerCase())
            ) {
              match = true
            }
            if (
              i.details.customer.email &&
              i.details.customer.email
                .toLowerCase()
                .includes(this.search.toLowerCase())
            ) {
              match = true
            }
          }
          // Price
          if (i.details.amount) {
            if (
              i.details.amount.finalPriceTTC &&
              (i.details.amount.finalPriceTTC / 100)
                .toFixed(2)
                .toLowerCase()
                .includes(this.search.toLowerCase())
            ) {
              match = true
            }
          }
          // Price
          if (i.m_Code) {
            if (i.m_Code.toLowerCase().includes(this.search.toLowerCase())) {
              match = true
            }
          }
          // Num
          if (i.m_Id) {
            if (i.m_Id.toLowerCase().includes(this.search.toLowerCase())) {
              match = true
            }
          }

          return match
        })
      }

      return gifts
    },
  },
  methods: {
    moment,
    cancelGift(item) {
      if (item.mTabGiftOperation.length || parseInt(item.m_status) === 3) {
        return
      }

      try {
        swal({
          title: this.$t('booker.areYouSure'),
          text: this.$t('booker.areYouSureCancelGift'),
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            this.loading = true

            try {
              const response = await MKPSDK.Payment.refund({
                artebeauteID: item.m_Id,
                localID: item.id,
                type: 'gift',
              })

              if (response.success) {
                await swal(
                  'Annulation effectuée',
                  'Le bon cadeau a été annulé.',
                  'success'
                )
              } else {
                await swal(
                  "Echec de l'annulation",
                  "L'annulation du bon cadeau a échoué.",
                  'error'
                )
              }

              this.refresh()
            } catch (err) {
              swal(
                "Echec de l'annulation",
                "L'annulation du bon cadeau a échoué.",
                'error'
              )
            }

            this.loading = false
          }
        })
      } catch (err) {
        swal(
          "Echec de l'annulation",
          "L'annulation du bon cadeau a échoué.",
          'error'
        )
      }

      this.loading = false
    },
    updateDate() {
      this.dStartMenu = false
      this.dEndMenu = false

      const dStartStr = moment(this.dStartStr).format('YYYY/MM/DD')
      const dEndStr = moment(this.dEndStr).format('YYYY/MM/DD')

      this.$emit('onUpdate', {
        start: dStartStr,
        end: dEndStr,
        page: this.page,
      })

      this.page = 1
    },
    getGiftFrom(val) {
      if (parseInt(val) === 1) {
        return {
          color: '#FEF3C7',
          text: 'EdenPass',
          value: parseInt(val),
        }
      } else if (parseInt(val) === 2) {
        return {
          color: '#fad1f9',
          text: 'ArteBeaute',
          value: parseInt(val),
        }
      } else {
        return {
          color: '#F3F4F6',
          text: this.$t('other.undefined'),
          value: -1,
        }
      }
    },
    // getStatus(statusStr) {
    //   if (statusStr === 'succeeded' || statusStr === 'paid') {
    //     return 'paid'
    //   } else if (statusStr === 'waiting') {
    //     return 'waiting'
    //   } else if (statusStr === 'refunded') {
    //     return 'refunded'
    //   } else if (statusStr === 'waiting_refund') {
    //     return 'waiting_refund'
    //   }
    //
    //   return 'failed'
    // },
    // getType(transaction) {
    //   if (transaction.bookingOrder) {
    //     return {
    //       color: '#FEF3C7',
    //       name: 'Réservation',
    //       id: 'booking',
    //     }
    //   } else if (transaction.eshopOrder) {
    //     return {
    //       color: '#D1FAE5',
    //       name: 'Boutique',
    //       id: 'shop',
    //     }
    //   } else if (transaction.giftOrder) {
    //     return {
    //       color: '#fad1f9',
    //       name: 'Bon Cadeau',
    //       id: 'gift',
    //     }
    //   } else {
    //     return {
    //       color: '#F3F4F6',
    //       name: 'Non défini',
    //       id: 'other',
    //     }
    //   }
    // },
    refresh() {
      this.dStartMenu = false
      this.dEndMenu = false

      const dStartStr = moment(this.dStartStr).format('YYYY/MM/DD')
      const dEndStr = moment(this.dEndStr).format('YYYY/MM/DD')

      this.$emit('onUpdate', {
        start: dStartStr,
        end: dEndStr,
        page: this.page,
      })

      this.page = 1
    },
    // showBookingOrder(tr) {
    //   this.viewBookingOrder = tr
    // },
    // getShopName(transaction) {
    //   if (!transaction.bookingOrder) {
    //     return '-'
    //   }
    //
    //   if (!transaction.bookingOrder.shop) {
    //     return '-'
    //   }
    //
    //   return transaction.bookingOrder.shop.publicInfo.name
    // },
    // getServicesNames(transaction) {
    //   if (!transaction.bookingOrder) {
    //     return ['-']
    //   }
    //
    //   if (!transaction.bookingOrder.details) {
    //     return ['-']
    //   }
    //
    //   return transaction.bookingOrder.details.cart.map((i) =>
    //     i.name ? i.name.fr : ''
    //   )
    // },
    // async acceptRefund(item) {
    //   try {
    //     swal({
    //       title: 'Etes-vous sûr ?',
    //       text: 'Voulez-vous vraiment rembourser cette transaction ?',
    //       buttons: true,
    //       dangerMode: true,
    //     }).then(async (willDelete) => {
    //       if (willDelete) {
    //         swal({
    //           title: 'Annuler les frais ?',
    //           text: 'Voulez-vous annuler les frais de remboursement ?',
    //           buttons: ['Non, garder les frais', 'Oui, annuler les frais'],
    //           dangerMode: true,
    //         }).then(async (noFees) => {
    //           if (item.paymentProvider === 'payzen') {
    //             if (!item.bookingOrder.abResponse) {
    //               swal(
    //                 'Echec du remboursement',
    //                 "Cette transaction n'est pas remboursable",
    //                 'error'
    //               )
    //
    //               return
    //             }
    //
    //             const response = await MKPSDK.Payment.refundPayzen({
    //               artebeauteID:
    //                 item.bookingOrder.abResponse.data.abAppointmentId.join(','),
    //               type: 'money',
    //               noFees,
    //             })
    //
    //             if (response.result.success) {
    //               // await ArteBeauteSDK.Appointments.appointmentDelete({
    //               //   shopId: booking.m_shopid,
    //               //   reserveId: booking.arrReserveID.join(','),
    //               //   eorderId: booking.m_eorder.m_id,
    //               //   bookingDate: booking.m_DATEENCAISSEMENT,
    //               //   typeDelete: 11,
    //               // })
    //
    //               swal(
    //                 'Remboursement effectué',
    //                 'Le remboursement à été validé.',
    //                 'success'
    //               )
    //
    //               item.status = 'refunded'
    //             } else {
    //               swal(
    //                 'Echec du remboursement',
    //                 'Le remboursement de la transaction à échoué.',
    //                 'error'
    //               )
    //             }
    //
    //             console.log('response.result :>> ', response.result)
    //           }
    //         })
    //       }
    //     })
    //   } catch (err) {
    //     console.log('err :>> ', err)
    //     swal(
    //       'Echec du remboursement',
    //       'Le remboursement de la transaction à échoué.',
    //       'error'
    //     )
    //   }
    // },
  },
}
</script>

<style scoped></style>
