<template>
  <div>
    test
    <v-btn @click="chargeCustomer">test</v-btn>
  </div>
</template>

<script>
import { ArteBeauteSDK } from '@/packages/artebeaute-sdk/src'
export default {
  name: 'customers',
  methods: {
    async chargeCustomer() {
      await ArteBeauteSDK.Customers.getAllCustomers(1, 50)
    },
  },
}
</script>

<style scoped></style>
