<template>
  <v-container fluid>
    <div class="d-inline-flex">
      <bm-range-picker @calendar="calendar" />
      <bm-shops-selector group :preselected="[]" />
    </div>
    <div
      style="
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: start;
        margin: 20px;
      "
    >
      <div style="min-width: 350px">
        <widg-dash-board
          title="Chiffre d'affaire Réalisé"
          :charged="chargeRP"
          :charged-compare="chargeRC"
          :periode="periode"
          :periode-compare="periodeCompare"
          :amount="amountRP"
          :amount-compare="amountRC"
        />
      </div>
      <div style="min-width: 350px">
        <widg-dash-board
          title="Chiffre d'affaire Encaissé"
          :charged="chargeCP"
          :charged-compare="chargeCC"
          :periode="periode"
          :periode-compare="periodeCompare"
          :amount="amountCP"
          :amount-compare="amountCC"
        />
      </div>
    </div>

    <!--    <v-row v-if="lastDayOpen" :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 125px' : 'margin-top: 75px'">
          <v-col>
            {{dayType}} / {{ periode }}
            <dashboard v-if="menuCurrent.name==='DashBoard'" :is-charged="isCharged"
                       :last-day-open="lastDayOpen"
                       :appointment-items="getAppointmentType()"
                       :cash-items="getCashType()"
                       :working-hours="getWorkHoursType()"
                       :periode-sel="dayType"
                       :periode="periode"
            ></dashboard>
            <cash-repartition v-if="menuCurrent.name==='Encaissement'" :is-charged="isCharged"
                              :cash-items="cashDispatcheByPeriod[dayType]"></cash-repartition>
            <appointment-repartition v-if="menuCurrent.name==='appointementRepartition'"
                                     :appointment-items="appointmentByPeriod[dayType]"/>
          </v-col>
        </v-row>-->
  </v-container>
</template>

<script>
// import { controli18n } from '@/helpers/lang'
import LayoutStat from '../../layouts/layout-statnew.vue'

// import {CaSDK} from '@/packages/artebeaute-sdk/src'

import dayjs from 'dayjs'
require('dayjs/locale/fr')
dayjs.locale('fr')
import BmRangePicker from '../../components/general/Date/bmRangePicker'
import WidgDashBoard from '../../components/card/widgDashBoard'
import BmShopsSelector from '@/components/helpers/bmShopsSelector'

export default {
  name: 'Stat',
  components: { BmShopsSelector, WidgDashBoard, BmRangePicker },
  data: () => {
    return {
      range: null,
      unSubscribeMutation: null,
      init: false,
      displayRangeDate: '',
      shops: [5, 20],
      realizedCharge: false,
      dayType: '',
      menuItems: [
        {
          title: 'Tableau de bord',
          icon: 'mdi-view-dashboard',
          name: 'DashBoard',
          type: 'module',
        },
        { type: 'line' },
        {
          title: 'Encaissement ( flux )',
          icon: 'mdi-realized-multiple',
          name: 'Encaissement',
          type: 'module',
        },
        { type: 'line' },
        {
          title: 'Rendez-Vous',
          icon: 'mdi-clock-outline',
          name: 'appointementRepartition',
          type: 'module',
        },
      ],
      menuCurrent: {
        title: 'Tableau de bord',
        icon: 'mdi-view-dashboard',
        name: 'DashBoard',
        type: 'module',
      },

      lastDayOpen: null,
    }
  },
  computed: {
    periode() {
      return {
        start: dayjs(this.range.periode.start),
        end: dayjs(this.range.periode.end),
      }
    },
    periodeCompare() {
      return {
        start: dayjs(this.range.compareTo.start),
        end: dayjs(this.range.compareTo.end),
      }
    },
    chargeRP() {
      return this.$store.getters['indicator/getIsChargedR']({
        start: dayjs(this.range.periode.start).locale('fr').format('YYYYMMDD'),
        end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })
    },
    chargeRC() {
      return this.$store.getters['indicator/getIsChargedR']({
        start: dayjs(this.range.compareTo.start)
          .locale('fr')
          .format('YYYYMMDD'),
        end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })
    },
    chargeCC() {
      return this.$store.getters['indicator/getIsChargedR']({
        start: dayjs(this.range.compareTo.start)
          .locale('fr')
          .format('YYYYMMDD'),
        end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })
    },
    chargeCP() {
      return this.$store.getters['indicator/getIsChargedR']({
        start: dayjs(this.range.periode.start).locale('fr').format('YYYYMMDD'),
        end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })
    },
    amountRP() {
      return this.$store.getters['indicator/getRealized']({
        start: dayjs(this.range.periode.start).locale('fr').format('YYYYMMDD'),
        end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
        value: 'totalTTC',
        charge: 'chargedR',
      })
    },
    amountRC() {
      return this.$store.getters['indicator/getRealized']({
        start: dayjs(this.range.compareTo.start)
          .locale('fr')
          .format('YYYYMMDD'),
        end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
        value: 'totalTTC',
        charge: 'chargedR',
      })
    },
    detailsCP() {
      return this.$store.getters['indicator/getCash']({
        start: dayjs(this.range.periode.start).locale('fr').format('YYYYMMDD'),
        end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
        value: 'detailsC',
        charge: 'chargedC',
      })
    },
    amountCP() {
      return this.$store.getters['indicator/getCash']({
        start: dayjs(this.range.periode.start).locale('fr').format('YYYYMMDD'),
        end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
        value: 'cash',
        charge: 'chargedC',
      })
    },
    amountCC() {
      return this.$store.getters['indicator/getCash']({
        start: dayjs(this.range.compareTo.start)
          .locale('fr')
          .format('YYYYMMDD'),
        end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
        value: 'cash',
        charge: 'chargedC',
      })
    },
  },
  mounted() {
    this.globalSearch()
  },
  created() {
    this.$emit('update:layout', LayoutStat)
    this.range = this.$store.state.indicator.currentRange
    // Mutations
  },
  methods: {
    calendar(val) {
      this.$store.dispatch('indicator/getCurrentRange', val)
      this.range = val

      this.globalSearch()
    },
    close() {
      this.$router.push(this.localePath('/'))
    },
    globalSearch() {
      Promise.all([
        this.realisedCurrent(),
        this.realisedCompare(),
        this.cashCurrent(),
        this.cashCompare(),
      ])
    },
    async realisedCurrent() {
      const isCharged = this.$store.getters['indicator/getIsChargedR']({
        start: dayjs(this.range.periode.start).locale('fr').format('YYYYMMDD'),
        end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })
      if (!isCharged) {
        const payloadCurrent = {
          type: 'current',
          start: dayjs(this.range.periode.start)
            .locale('fr')
            .format('YYYYMMDD'),
          end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
          name: 'realized',
          shopIds: this.shops.join(','),
        }
        await this.$store.dispatch('indicator/getRealized', payloadCurrent)
      }
    },
    async realisedCompare() {
      const isCharged = this.$store.getters['indicator/getIsChargedR']({
        start: dayjs(this.range.compareTo.start)
          .locale('fr')
          .format('YYYYMMDD'),
        end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })

      if (!isCharged) {
        const payloadCurrent = {
          type: 'current',
          start: dayjs(this.range.compareTo.start)
            .locale('fr')
            .format('YYYYMMDD'),
          end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
          name: 'realized',
          shopIds: this.shops.join(','),
        }
        await this.$store.dispatch('indicator/getRealized', payloadCurrent)
      }
    },
    async cashCurrent() {
      const isCharged = this.$store.getters['indicator/getIsChargedC']({
        start: dayjs(this.range.periode.start).locale('fr').format('YYYYMMDD'),
        end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })
      if (!isCharged) {
        const payloadCurrent = {
          type: 'current',
          start: dayjs(this.range.periode.start)
            .locale('fr')
            .format('YYYYMMDD'),
          end: dayjs(this.range.periode.end).locale('fr').format('YYYYMMDD'),
          name: 'cash',
          shopIds: this.shops.join(','),
        }
        await this.$store.dispatch('indicator/getCash', payloadCurrent)
      }
    },
    async cashCompare() {
      const isCharged = this.$store.getters['indicator/getIsChargedC']({
        start: dayjs(this.range.compareTo.start)
          .locale('fr')
          .format('YYYYMMDD'),
        end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
        shops: this.shops.join(','),
      })
      if (!isCharged) {
        const payloadCurrent = {
          type: 'current',
          start: dayjs(this.range.compareTo.start)
            .locale('fr')
            .format('YYYYMMDD'),
          end: dayjs(this.range.compareTo.end).locale('fr').format('YYYYMMDD'),
          name: 'cash',
          shopIds: this.shops.join(','),
        }
        await this.$store.dispatch('indicator/getCash', payloadCurrent)
      }
    },
  },
}
</script>
<style scoped>
.btn {
  background-color: #f1f1f1;
  color: black;
  font-size: 0.8em;
  padding: 8px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  margin: 3px;
}

.btn:hover {
  background-color: #c3c3c3;
}

.select {
  position: relative;
  width: 300px;
}

.select select {
  display: block;
  padding: 5px 50px 5px 10px;
  border: 1px solid black;
  background-color: #fff;
  color: black;
  width: 100%;
}

.select::after {
  display: block;
  content: '';
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translateY(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
}
</style>
