<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <v-row class="ma-0 mb-7" align="center">
      <v-col cols="3" class="d-none d-md-inline-block"> </v-col>
      <v-col
        cols="6"
        class="text-uppercase text-center font-weight-bold"
        style="font-size: 1.2rem"
      >
        {{ $t('communications.alertsManagement') }}
      </v-col>
      <v-col cols="6" md="3" class="text-end">
        <v-btn class="ma-2" color="primary" @click="createAlert">
          {{ $t('icons.new') }}
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="alerts.length">
      <v-card
        v-for="alert in alerts"
        :key="alert.id"
        class="ma-5"
        :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
      >
        <div class="pa-2">
          <v-card-title>
            {{
              $t('communications.events.fromTo', {
                start: getStartDate(alert.start),
                end: getEndDate(alert.end)
              })
            }}
          </v-card-title>
          <v-card-text
            v-html="controli18n($i18n.locale, alert.body)"
            style="height: 120px; overflow: auto"
          />
          <v-card-text v-if="alert.serviceId && getService(alert.serviceId)">
            {{ $t('communications.alerts.redirectionOnServicePage') }}
            {{
              controli18n($i18n.locale, getService(alert.serviceId).name) ||
                getService(alert.serviceId).artebeauteName
            }}
          </v-card-text>
        </div>
        <v-spacer />
        <v-card-actions style="min-width: 200px" class="justify-end mr-3">
          <v-btn icon @click="updateEvent(alert)">
            <v-icon color="blue darken-3">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteEvent(alert)">
            <v-icon color="red darken-1">mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div v-if="alertsPassed.length" class="mt-10">
      <div class="oldAlertsTitle">
        {{ $t('communications.alerts.pastAlerts') }}
      </div>

      <v-card
        v-for="alert in alertsPassed"
        :key="alert.id"
        class="ma-5"
        :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
      >
        <div class="pa-2">
          <v-card-title>
            {{
              $t('communications.events.fromTo', {
                start: getStartDate(alert.start),
                end: getEndDate(alert.end)
              })
            }}
          </v-card-title>
          <v-card-text
            v-html="controli18n($i18n.locale, alert.body)"
            style="height: 120px; overflow: auto"
          />
          <v-card-text v-if="alert.serviceId && getService(alert.serviceId)">
            {{ $t('communications.alerts.redirectionOnServicePage') }}
            {{
              controli18n($i18n.locale, getService(alert.serviceId).name) ||
                getService(alert.serviceId).artebeauteName
            }}
          </v-card-text>
        </div>
        <v-spacer />
        <v-card-actions style="min-width: 200px" class="justify-end mr-3">
          <v-btn icon @click="updateEvent(alert)">
            <v-icon color="blue darken-3">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteEvent(alert)">
            <v-icon color="red darken-1">mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-alert
      v-if="!alerts.length && !alertsPassed.length"
      outlined
      class="text-center"
    >
      {{ $t('communications.alerts.haveNotCreatedAlertsYet') }}
    </v-alert>

    <v-dialog v-model="createAlertDialog" width="950">
      <v-card v-if="createAlertDialog">
        <div>
          <v-card-title>
            {{ $t('communications.alerts.createNewAlert') }}
          </v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-3" style="height: 65vh; overflow: auto">
          <edit-alerts
            :edit="edit"
            :online-articles="onlineArticles"
            @updateEdit="edit = $event"
          />

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="createAlertDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="create" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updateAlertDialog" width="950">
      <v-card v-if="updateAlertDialog">
        <div>
          <v-card-title>
            {{ $t('communications.alerts.editAlert') }}
          </v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-3" style="height: 65vh; overflow: auto">
          <edit-alerts
            :edit="edit"
            :online-articles="onlineArticles"
            @updateEdit="edit = $event"
          />

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="updateAlertDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="update" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EditAlerts from '../../../components/alerts/editAlerts'
import { controli18n } from '@/helpers/langs'
import swal from 'sweetalert'
import { mapGetters } from 'vuex'

export default {
  name: 'index',
  components: { EditAlerts },
  async mounted() {
    await this.$store.dispatch('alerts/fetchAlerts')
  },
  data: () => ({
    loading: false,
    saveLoading: false,
    edit: null,
    createAlertDialog: false,
    updateAlertDialog: false,
    saveAlert: null
  }),
  computed: {
    ...mapGetters('alerts', ['getAlerts']),
    alerts() {
      return this.getAlerts.filter(alert => new Date(alert.end) >= new Date())
    },
    alertsPassed() {
      return this.getAlerts.filter(alert => new Date(alert.end) < new Date())
    },
    fusionArticles: function() {
      return this.$store.getters['articles/fusionArticle']()
    },
    onlineArticles() {
      return this.fusionArticles
        .filter(a => [1, 4].includes(parseInt(a.art_type)))
        .filter(a => !!a.id)
        .filter(a => a.categories.length)
    }
  },
  methods: {
    controli18n,
    createAlert() {
      this.edit = {
        start: null,
        end: null,
        body: {},
        serviceId: null
      }

      this.createAlertDialog = true
    },
    updateEvent(alert) {
      const start = new Date(alert.start)
      const startFormatted =
        start.getFullYear() +
        '-' +
        this.getFormattedMonth(start) +
        '-' +
        this.getFormattedDay(start)

      const end = new Date(alert.end)
      const endFormatted =
        end.getFullYear() +
        '-' +
        this.getFormattedMonth(end) +
        '-' +
        this.getFormattedDay(end)

      this.edit = {
        start: startFormatted,
        end: endFormatted,
        body: alert.body,
        serviceId: Number(alert.serviceId)
      }

      this.saveAlert = alert

      this.updateAlertDialog = true
    },

    getStartDate(start) {
      const date = new Date(start)
      return date.getDate() + ' ' + this.getMonth(date.getMonth())
    },
    getEndDate(end) {
      const date = new Date(end)
      return date.getDate() + ' ' + this.getMonth(date.getMonth())
    },
    getMonth(id) {
      const months = [
        this.$t('months.january'),
        this.$t('months.february'),
        this.$t('months.march'),
        this.$t('months.april'),
        this.$t('months.may'),
        this.$t('months.june'),
        this.$t('months.july'),
        this.$t('months.august'),
        this.$t('months.september'),
        this.$t('months.october'),
        this.$t('months.november'),
        this.$t('months.december')
      ]

      return months[id]
    },
    getFormattedMonth(date) {
      const format = Number(date.getMonth() + 1)

      if (format < 10) {
        return '0' + format
      } else {
        return format
      }
    },
    getFormattedDay(date) {
      const format = Number(date.getDate())

      if (format < 10) {
        return '0' + format
      } else {
        return format
      }
    },
    getService(id) {
      return this.onlineArticles.find(a => a.id.toString() === id)
    },
    formControl() {
      if (!this.edit.start) {
        swal({
          title: this.$t('communications.events.startDateRequired'),
          icon: 'error'
        })
        return false
      }

      if (!this.edit.end) {
        swal({
          title: this.$t('communications.events.endDateRequired'),
          icon: 'error'
        })
        return false
      }

      if (new Date(this.edit.start) >= new Date(this.edit.end)) {
        swal({
          title: this.$t('communications.events.startAfterEnd'),
          icon: 'error'
        })
        return false
      }

      if (!controli18n(this.$i18n.locale, this.edit.body)) {
        swal({
          title: this.$t('communications.events.contentRequired'),
          icon: 'error'
        })
        return false
      }

      if (!this.edit.serviceId) {
        swal({
          title: this.$t('communications.alerts.serviceRequired'),
          icon: 'error'
        })
        return false
      }

      return true
    },

    async create() {
      this.saveLoading = true

      if (this.formControl()) {
        const response = await this.$store.dispatch(
          'alerts/createAlert',
          this.edit
        )

        if (!response) {
          await swal({
            title: this.$t('other.anErrorOccurred'),
            icon: 'error'
          })
        } else {
          this.edit = null
          this.createAlertDialog = false
        }
      }

      this.saveLoading = false
    },
    async update() {
      this.saveLoading = true

      if (this.formControl()) {
        const response = await this.$store.dispatch('alerts/updateAlert', {
          id: this.saveAlert.id,
          data: this.edit
        })

        if (!response) {
          await swal({
            title: this.$t('other.anErrorOccurred'),
            icon: 'error'
          })
        } else {
          this.edit = null
          this.saveAlert = null
          this.updateAlertDialog = false
        }
      }
      this.saveLoading = false
    },
    async deleteEvent(alert) {
      swal({
        title: this.$t('communications.alerts.sureDeleteAlert'),
        buttons: [this.$t('icons.cancel'), this.$t('icons.delete')]
      }).then(async willDelete => {
        if (willDelete) {
          this.saveLoading = true

          await this.$store.dispatch('alerts/deleteAlert', {
            alert
          })

          this.saveLoading = false
        }
      })
    }
  }
}
</script>

<style scoped>
.oldAlertsTitle {
  font-weight: bold;
  font-size: 1.2rem;
  border-bottom: 1px solid #333;
}
</style>
