<template>
  <div class="my-8">
    <v-row>
      <v-col cols="12" md="3">
        <v-btn @click="addRemiseModal = true" block color="dark" dark>
          {{ $t('booker.addDiscount') }}
        </v-btn>
        <v-card class="mt-3">
          <v-card-title> {{ $t('booker.filterGeoGroup') }} </v-card-title>
          <v-card-text>
            <v-radio-group v-model="selectedGeogroupment">
              <v-radio :label="$t('other.all')" value="all"></v-radio>
              <v-radio
                v-for="geo in geogroupments"
                :key="geo.id"
                :label="geo.name.fr"
                :value="geo.id"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card class="mt-3">
          <v-card-title> {{ $t('booker.filterShop') }} </v-card-title>
          <v-card-text>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="geo in geogroupments.filter(
                  (g) =>
                    shops.filter(
                      (s) => s.geoGroupment && s.geoGroupment.id === g.id
                    ).length
                )"
                :key="geo.id"
              >
                <v-expansion-panel-header>
                  {{ geo.name.fr }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-checkbox
                    v-for="shop in shops.filter(
                      (s) => s.geoGroupment && s.geoGroupment.id === geo.id
                    )"
                    :key="shop.id"
                    v-model="selectedShops"
                    :label="shop.name"
                    :value="shop.id"
                  ></v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <div id="calendar"></div>
      </v-col>
    </v-row>
    <v-dialog v-model="addRemiseModal" v-if="addRemiseModal">
      <v-card>
        <v-card-title>{{ $t('booker.addDiscount') }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-select
                    :items="shopItems"
                    :label="$t('booker.associatedShop')"
                    v-model="newRemise.shopId"
                    clearable
                    solo
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-text-field
                    v-model="newRemise.startDate"
                    :label="$t('booker.dateDeparture')"
                    solo
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="6" class="d-flex">
                  <v-text-field
                    v-model="newRemise.startHours"
                    :label="$t('booker.departureTime')"
                    solo
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="6" class="d-flex">
                  <v-text-field
                    v-model="newRemise.endHours"
                    :label="$t('booker.endTime')"
                    solo
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="6" class="d-flex">
                  <v-text-field
                    v-model="newRemise.value"
                    :label="$t('booker.discountValue')"
                    solo
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="6" class="d-flex">
                  <v-select
                    :items="[
                      { value: 'percent', text: '%' },
                      { value: 'value', text: '€' },
                    ]"
                    v-model="newRemise.valueType"
                    :label="$t('booker.typeReduction')"
                    solo
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12" class="d-flex">
                  <v-checkbox
                    v-model="newRemise.intervalType"
                    :label="$t('booker.daily')"
                    value="days"
                    class="mr-6"
                  />
                  <v-checkbox
                    v-model="newRemise.intervalType"
                    :label="$t('booker.weekly')"
                    value="weeks"
                  />
                </v-col>
                <v-col cols="12" v-if="newRemise.intervalType === 'days'">
                  <div class="d-flex align-center">
                    {{ $t('booker.every') }}
                    <v-text-field
                      type="number"
                      step="1"
                      v-model="newRemise.interval"
                      solo
                      hide-details="auto"
                      style="width: 75px; max-width: 75px"
                      class="mx-4"
                    />
                    {{ $t('booker.days') }}
                  </div>
                </v-col>
                <v-col cols="12" v-if="newRemise.intervalType === 'weeks'">
                  <div class="d-flex align-center">
                    {{ $t('booker.everyE') }}
                    <v-text-field
                      type="number"
                      step="1"
                      v-model="newRemise.interval"
                      solo
                      hide-details="auto"
                      style="width: 75px; max-width: 75px"
                      class="mx-4"
                    />
                    {{ $t('booker.weeksOn') }}
                  </div>
                  <div>
                    <v-row>
                      <v-col cols="3">
                        <v-checkbox
                          value="lundi"
                          :label="$t('days.monday')"
                          v-model="newRemise.days"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          value="mardi"
                          :label="$t('days.tuesday')"
                          v-model="newRemise.days"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          value="mercredi"
                          :label="$t('days.wednesday')"
                          v-model="newRemise.days"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          value="jeudi"
                          :label="$t('days.thursday')"
                          v-model="newRemise.days"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          value="vendredi"
                          :label="$t('days.friday')"
                          v-model="newRemise.days"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          value="samedi"
                          :label="$t('days.saturday')"
                          v-model="newRemise.days"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          value="dimanche"
                          :label="$t('days.sunday')"
                          v-model="newRemise.days"
                        />
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="d-flex align-center">
                <v-checkbox
                  :label="$t('booker.endAfter')"
                  v-model="newRemise.endType"
                  value="afterNb"
                  class="mr-6"
                />
                <div
                  v-if="newRemise.endType === 'afterNb'"
                  class="d-flex align-center"
                >
                  <v-text-field
                    type="number"
                    step="1"
                    v-model="newRemise.nbOccurence"
                    solo
                    hide-details="auto"
                    style="width: 75px; max-width: 75px"
                    class="mx-4"
                  />
                  {{ $t('booker.occurrences') }}
                </div>
              </div>
              <div class="d-flex align-center">
                <v-checkbox
                  :label="$t('booker.endOn')"
                  v-model="newRemise.endType"
                  value="afterDate"
                />
                <div v-if="newRemise.endType === 'afterDate'">
                  <v-text-field
                    type="text"
                    v-model="newRemise.endDate"
                    solo
                    hide-details="auto"
                    style="width: 150px; max-width: 150px"
                    class="mx-4"
                  />
                </div>
              </div>
            </v-col>
            <v-col cols="12" style="text-align: right">
              <v-btn color="green" @click="addRemise" dark>
                {{ $t('booker.addDiscount') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Calendar } from '@fullcalendar/core'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import scrollGridPlugin from '@fullcalendar/scrollgrid'
import layoutHome from '../../layouts/layout-home.vue'
import { MKPSDK } from '@/packages/artebeaute-sdk/src'
import moment from 'moment'

export default {
  data() {
    return {
      calendar: null,
      events: [],
      resources: [],
      selectedGeogroupment: 'all',
      selectedShops: [],
      refreshShop: true,
      addRemiseModal: false,
      newRemise: {
        shopId: null,
        startDate: '',
        startHours: '',
        intervalType: '',
        interval: 1,
        days: [],
        endType: '',
        nbOccurence: 1,
        value: null,
        valueType: 'percent',
      },
      remises: [],
    }
  },
  watch: {
    selectedGeogroupment() {
      this.resources = this.$store.getters['shop/fusionShop']()
        .filter((s) => {
          return (
            this.selectedGeogroupment === 'all' ||
            (s.geoGroupment && s.geoGroupment.id === this.selectedGeogroupment)
          )
        })
        .map((s) => {
          return {
            id: s.id,
            title: s.name,
          }
        })

      this.calendar.getResources().forEach((r) => r.remove())
      this.resources.forEach((r) => this.calendar.addResource(r))

      this.refreshShop = false

      this.selectedShops = this.$store.getters['shop/fusionShop']()
        .filter((s) => {
          return (
            this.selectedGeogroupment === 'all' ||
            (s.geoGroupment && s.geoGroupment.id === this.selectedGeogroupment)
          )
        })
        .map((s) => s.id)
    },
    selectedShops() {
      if (!this.refreshShop) {
        this.refreshShop = true
        return
      }

      this.resources = this.shops
        .filter((s) => this.selectedShops.includes(s.id))
        .map((s) => {
          return {
            id: s.id,
            title: s.name,
          }
        })

      this.calendar.getResources().forEach((r) => r.remove())
      this.resources.forEach((r) => this.calendar.addResource(r))
    },
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    geogroupments() {
      return this.$store.getters['geogroupments/getGeogroupments']
    },
    shops() {
      if (!this.selectedGeogroupment || this.selectedGeogroupment === 'all') {
        return this.$store.getters['shop/fusionShop']()
      }

      return this.$store.getters['shop/fusionShop']().filter((s) => {
        return s.geoGroupment && s.geoGroupment.id === this.selectedGeogroupment
      })
    },
    shopItems() {
      return this.$store.getters['shop/fusionShop']().map((s) => ({
        value: s.id,
        text: s.name,
      }))
    },
    geogroupmentsItems() {
      return this.$store.getters['geogroupments/getGeogroupments'].map((g) => ({
        value: g.id,
        text: g.name.fr,
      }))
    },
    servicesItems() {
      return this.$store.getters['articles/fusionArticle']()
        .filter((a) => a.booker && a.booker.active)
        .map((a) => ({
          value: a.id,
          text: a.name ? a.name.fr : '???',
        }))
    },
    categoriesItems() {
      return this.$store.getters['categories/getCategories'].map((a) => ({
        value: a.id,
        text: a.name ? a.name.fr : '???',
      }))
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('geogroupments/fetchGeogroupments')
      await this.$store.dispatch('categories/fetchCategories')

      const { remises } = await MKPSDK.Remises.getAllRemises()
      this.remises = remises
    } catch (err) {
      console.log('err :>> ', err)
    }

    const calendarEl = document.getElementById('calendar')

    this.events = this.remises.map((r) => ({
      id: r.id,
      title: r.title + ' -' + r.value + (r.valueType === 'percent' ? '%' : '€'),
      start: moment(r.date).format('YYYY-MM-DD') + ' ' + r.startHours,
      end: moment(r.date).format('YYYY-MM-DD') + ' ' + r.endHours,
      resourceId: r.shopId,
    }))

    this.resources = this.$store.getters['shop/fusionShop']().map((s) => {
      return {
        id: s.id,
        title: s.name,
      }
    })

    this.calendar = new Calendar(calendarEl, {
      plugins: [resourceTimeGridPlugin, scrollGridPlugin],
      initialView: 'resourceTimeGridSevenDay',
      events: this.events,
      resources: this.resources,
      datesAboveResources: true,
      slotMinWidth: '500px',
      dayMinWidth: 150,
      views: {
        resourceTimeGridSevenDay: {
          type: 'resourceTimeGrid',
          duration: { days: 7 },
          buttonText: this.$t('booker.week'),
        },
      },
    })

    this.calendar.render()

    this.refreshShop = false

    this.selectedShops = this.$store.getters['shop/fusionShop']().map(
      (s) => s.id
    )
  },
  methods: {
    async addRemise() {
      try {
        await MKPSDK.Remises.createRemise(this.newRemise)

        // this.addRemiseModal = false
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style scoped></style>
