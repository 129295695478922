<template>
  <div class="text-center">
    <bm-menu-button :text="periodeSel.text" @onClose="close" @onSave="save">
      <template #button-inset>
        <div
          style="font-size: .6rem ; padding-left: 10px;align-self: end"
          v-if="activeCompare"
        >
          {{ formatCompare() }}
        </div>
      </template>

      <template #title>
        <v-select
          v-model="periodeSel"
          dense
          hide-details
          :items="period"
          return-object
          item-text="text"
          :menu-props="{ bottom: true, offsetY: true }"
        />
      </template>

      <template #body>
        <v-row dense>
          <v-col cols="6">
            <label>du</label>
            <input
              :readonly="calendarReadOnly"
              @change="changeInputDate"
              type="date"
              v-model="datesVerif[0]"
              id="start"
            />
          </v-col>
          <v-col cols="6">
            <label>au</label>
            <input
              :readonly="calendarReadOnly"
              @change="changeInputDate"
              type="date"
              v-model="datesVerif[1]"
              id="end"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" class="text-center">
            <v-date-picker
              full-width
              :readonly="calendarReadOnly"
              reactive
              color="black"
              @change="changeDate"
              locale="fr-fr"
              :first-day-of-week="1"
              v-model="dates"
              range
              no-title
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row dense no-gutters>
          <v-col cols="12">
            <v-checkbox
              v-model="activeCompare"
              dense
              label="Comparer à des dates antèrieures"
            ></v-checkbox>
          </v-col>
          <v-col v-if="activeCompare">
            <v-select
              style="font-size: 0.8rem"
              :items="compares"
              v-model="compareSel"
              dense
              hide-details
              return-object
              item-text="text"
              :menu-props="{ bottom: true, offsetY: true }"
            />
          </v-col>
        </v-row>
      </template>
    </bm-menu-button>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import BmMenuButton from '@/components/base/bmMenuButton'

const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)
require('dayjs/locale/fr')
dayjs().format('Q')
export default {
  name: 'bmRangePicker',
  components: { BmMenuButton },
  data: () => {
    return {
      dates: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      datesCompare: [
        dayjs().format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ],
      datesVerif: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
      menu: false,
      activeCompare: true,
      calendarReadOnly: true,
      period: [
        { text: "Aujourd'hui", type: 'day', ops: 0, ope: 0 },
        { text: 'Hier', type: 'day', ops: 1, ope: 1 },
        { text: '7 derniers jours', type: 'day', ops: 0, ope: 6 },
        { text: '30 derniers jours', type: 'day', ops: 0, ope: 29 },
        { text: '90 derniers jours', type: 'day', ops: 0, ope: 89 },
        { text: 'Mois dernier', type: 'month', ops: 1, ope: 1 },
        { text: 'An dernier', type: 'year', ops: 1, ope: 1 },
        { text: 'Cette semaine à ce jour', type: 'wtd', ops: 1, ope: 1 },
        { text: 'Mois actuel', type: 'month', ops: 0, ope: 0 },
        { text: 'Ce trimestre à ce jour', type: 'qtd', ops: 1, ope: 1 },
        { text: 'Cette année à ce jour', type: 'ytd', ops: 1, ope: 1 }
      ],
      compares: [
        { text: 'Période qui précède', value: 'previousPeriod' },
        { text: 'Année précédente', value: 'previousYear' }
      ],
      periodeSel: { text: "Aujourd'hui", type: 'day', ops: 0, ope: 0 },
      periodeBefore: { text: "Aujourd'hui", type: 'day', ops: 0, ope: 0 },
      compareSel: { text: 'Période qui précède', value: 'previousPeriod' }
    }
  },
  watch: {
    periodeSel(val) {
      this.periodTrad(val)
      this.compareToCalcul(this.compareSel)
    },
    compareSel(val) {
      this.compareToCalcul(val)
    },
    menu(val) {
      if (val) {
        this.periodeBefore = this.periodeSel
        console.log(this.periodeSel)
      }
    }
  },
  created() {
    this.completeQuarter()
    this.compareToCalcul(this.compareSel)
  },
  mounted() {
    console.log('mounted')
    this.dates[0] = this.$store.state.indicator.currentRange.periode.start
    this.dates[1] = this.$store.state.indicator.currentRange.periode.end
    this.changeDate(this.dates)
    this.compareToCalcul(this.compareSel)
  },
  methods: {
    formatCompare() {
      return (
        'par rapport au ' +
        dayjs(this.datesCompare[0]).format('dd DD MMM') +
        ' - ' +
        dayjs(this.datesCompare[1]).format('dd DD MMM YYYY')
      )
    },
    close(cb) {
      this.periodeSel = this.periodeBefore
      this.dates[0] = this.$store.state.indicator.currentRange.periode.start
      this.dates[1] = this.$store.state.indicator.currentRange.periode.end
      this.changeDate(this.dates)
      this.compareToCalcul(this.compareSel)
      cb()
    },
    save(cb) {
      const rep = {
        periode: {
          start: this.datesVerif[0],
          end: this.datesVerif[1]
        },
        compareTo: {
          active: this.activeCompare,
          start: this.datesCompare[0],
          end: this.datesCompare[1]
        }
      }
      this.$emit('calendar', rep)
      cb()
    },
    completeQuarter() {
      // {text: "Ce trimestre à ce jour", type: 'qtd', ops: 1, ope: 1},
      this.period.push({
        text: dayjs().format('[Trimestre] Q (YYYY)'),
        type: 'quarter',
        ops: 0,
        ope: 0
      })

      this.period.push({
        text: dayjs()
          .subtract(1, 'quarter')
          .format('[Trimestre] Q (YYYY)'),
        type: 'quarter',
        ops: 1,
        ope: 1
      })
      this.period.push({
        text: dayjs()
          .subtract(2, 'quarter')
          .format('[Trimestre] Q (YYYY)'),
        type: 'quarter',
        ops: 2,
        ope: 2
      })
      this.period.push({
        text: dayjs()
          .subtract(3, 'quarter')
          .format('[Trimestre] Q (YYYY)'),
        type: 'quarter',
        ops: 3,
        ope: 3
      })
      this.period.push({ text: 'Personnalisée', type: 'perso', ops: 1, ope: 1 })
    },
    periodTrad(val) {
      console.log(val)
      if (val.type === 'day') {
        if (val.ops !== 0 || val.ope !== 0) {
          this.dates[0] = dayjs()
            .subtract(val.ops, 'day')
            .format('YYYY-MM-DD')
          this.dates[1] = dayjs()
            .subtract(val.ope, 'day')
            .format('YYYY-MM-DD')
        } else {
          this.dates[0] = dayjs().format('YYYY-MM-DD')
          this.dates[1] = dayjs().format('YYYY-MM-DD')
        }
      } else if (val.type === 'month') {
        if (val.ops !== 0) {
          this.dates[0] = dayjs()
            .subtract(val.ops, 'month')
            .date(1)
            .format('YYYY-MM-DD')
          this.dates[1] = dayjs()
            .subtract(val.ops, 'month')
            .date(dayjs(this.dates[0]).daysInMonth())
            .format('YYYY-MM-DD')
        } else {
          this.dates[0] = dayjs()
            .date(1)
            .format('YYYY-MM-DD')
          this.dates[1] = dayjs().format('YYYY-MM-DD')
        }
      } else if (val.type === 'year') {
        if (val.ops !== 0) {
          this.dates[0] = dayjs()
            .subtract(val.ops, 'year')
            .month(0)
            .date(1)
            .format('YYYY-MM-DD')
          this.dates[1] = dayjs()
            .subtract(val.ops, 'year')
            .month(11)
            .date(31)
            .format('YYYY-MM-DD')
        } else {
          this.dates[0] = dayjs().format('YYYY-MM-DD')
          this.dates[1] = dayjs().format('YYYY-MM-DD')
        }
      } else if (val.type === 'wtd') {
        if (val.ops !== 0) {
          this.dates[0] = dayjs()
            .day(1)
            .format('YYYY-MM-DD')
          this.dates[1] = dayjs().format('YYYY-MM-DD')
        } else {
          this.dates[0] = dayjs().format('YYYY-MM-DD')
          this.dates[1] = dayjs().format('YYYY-MM-DD')
        }
      } else if (val.type === 'qtd') {
        this.dates[0] = dayjs()
          .startOf('quarter')
          .format('YYYY-MM-DD')
        this.dates[1] = dayjs().format('YYYY-MM-DD')
      } else if (val.type === 'ytd') {
        this.dates[0] = dayjs()
          .startOf('year')
          .format('YYYY-MM-DD')
        this.dates[1] = dayjs().format('YYYY-MM-DD')
      } else if (val.type === 'quarter') {
        this.dates[0] = dayjs()
          .subtract(val.ope, 'quarter')
          .startOf('quarter')
          .format('YYYY-MM-DD')
        this.dates[1] = dayjs()
          .subtract(val.ops, 'quarter')
          .endOf('quarter')
          .format('YYYY-MM-DD')
      }
      if (val.type !== 'perso') {
        this.calendarReadOnly = true
      } else {
        this.calendarReadOnly = false
      }
      this.changeDate(this.dates)
    },
    changeDate(val) {
      if (dayjs(val[0]).isAfter(dayjs(val[1]))) {
        this.datesVerif[0] = val[1]
        this.datesVerif[1] = val[0]
      } else {
        this.datesVerif[0] = val[0]
        this.datesVerif[1] = val[1]
      }
    },
    changeInputDate() {
      this.dates[0] = document.getElementById('start').value
      this.dates[1] = document.getElementById('end').value
    },
    compareToCalcul(val) {
      console.log(val)
      if (val.value === 'previousYear') {
        this.datesCompare[0] = dayjs(this.datesVerif[0])
          .subtract(1, 'year')
          .format('YYYY-MM-DD')
        this.datesCompare[1] = dayjs(this.datesVerif[1])
          .subtract(1, 'year')
          .format('YYYY-MM-DD')
      } else if (val.value === 'previousPeriod') {
        if (this.periodeSel.type === 'day') {
          if (this.periodeSel.ops !== 0 || this.periodeSel.ope !== 0) {
            this.datesCompare[1] = dayjs(this.datesVerif[0])
              .subtract(1, 'day')
              .format('YYYY-MM-DD')
            this.datesCompare[0] = dayjs(this.datesCompare[1])
              .subtract(
                dayjs(this.datesVerif[1]).diff(this.datesVerif[0], 'day'),
                'day'
              )
              .format('YYYY-MM-DD')
          } else {
            this.datesCompare[0] = dayjs()
              .subtract(1, 'day')
              .format('YYYY-MM-DD')
            this.datesCompare[1] = dayjs()
              .subtract(1, 'day')
              .format('YYYY-MM-DD')
          }
        } else if (this.periodeSel.type === 'month') {
          if (this.periodeSel.ops !== 0) {
            this.datesCompare[0] = dayjs(this.datesVerif[0])
              .subtract(this.periodeSel.ops, 'month')
              .date(1)
              .format('YYYY-MM-DD')
            this.datesCompare[1] = dayjs(this.datesVerif[0])
              .subtract(this.periodeSel.ops, 'month')
              .date(dayjs(this.datesCompare[0]).daysInMonth())
              .format('YYYY-MM-DD')
          } else {
            this.datesCompare[0] = dayjs(this.datesVerif[0])
              .subtract(this.periodeSel.ops + 1, 'month')
              .date(1)
              .format('YYYY-MM-DD')
            this.datesCompare[1] = dayjs(this.datesVerif[0])
              .subtract(this.periodeSel.ops + 1, 'month')
              .date(dayjs(this.datesCompare[0]).daysInMonth())
              .format('YYYY-MM-DD')
          }
        } else if (this.periodeSel.type === 'year') {
          if (this.periodeSel.ops !== 0) {
            this.datesCompare[0] = dayjs(this.datesVerif[0])
              .subtract(this.periodeSel.ops, 'year')
              .month(0)
              .date(1)
              .format('YYYY-MM-DD')
            this.datesCompare[1] = dayjs(this.datesVerif[0])
              .subtract(this.periodeSel.ops, 'year')
              .month(11)
              .date(31)
              .format('YYYY-MM-DD')
          }
        } else if (this.periodeSel.type === 'wtd') {
          if (this.periodeSel.ops !== 0) {
            this.datesCompare[0] = dayjs()
              .subtract(1, 'week')
              .day(1)
              .format('YYYY-MM-DD')
            this.datesCompare[1] = dayjs()
              .subtract(1, 'week')
              .format('YYYY-MM-DD')
          } else {
            this.datesCompare[0] = dayjs().format('YYYY-MM-DD')
            this.datesCompare[1] = dayjs().format('YYYY-MM-DD')
          }
        } else if (this.periodeSel.type === 'qtd') {
          this.datesCompare[0] = dayjs()
            .subtract(1, 'quarter')
            .startOf('quarter')
            .format('YYYY-MM-DD')
          this.datesCompare[1] = dayjs(this.datesCompare[0])
            .add(
              dayjs(this.datesVerif[1]).diff(this.datesVerif[0], 'day'),
              'day'
            )
            .format('YYYY-MM-DD')
        } else if (this.periodeSel.type === 'ytd') {
          this.datesCompare[0] = dayjs()
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD')
          this.datesCompare[1] = dayjs(this.datesCompare[0])
            .date(dayjs().date())
            .month(dayjs().month())
            .format('YYYY-MM-DD')
        } else if (this.periodeSel.type === 'quarter') {
          this.datesCompare[0] = dayjs()
            .subtract(this.periodeSel.ope + 1, 'quarter')
            .startOf('quarter')
            .format('YYYY-MM-DD')
          this.datesCompare[1] = dayjs()
            .subtract(this.periodeSel.ops + 1, 'quarter')
            .endOf('quarter')
            .format('YYYY-MM-DD')
        }
      }
    }
  }
}
</script>

<style scoped></style>
