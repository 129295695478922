<template>
  <div>
    <v-sheet color="primary" class="px-4 py-2">
      <h1>Maintenance</h1>
    </v-sheet>
    <v-sheet class="elevation-2">
      <v-btn-toggle v-model="page">
        <v-btn value="gift" style="border-radius: 0">Bon Cadeaux</v-btn>
        <v-btn value="transaction" style="border-radius: 0">Transactions</v-btn>
      </v-btn-toggle>
    </v-sheet>
    <div class="py-6" v-if="page === 'gift'">
      <h2 class="mb-3">Bon Cadeaux</h2>
      <v-alert color="grey">
        L'action "Résoudre" recréé le GiftOrder dans la base de données.
        Aucun mail n'est envoyé.
      </v-alert>
      <v-data-table
          :headers="giftHeaders"
          :items="gifts"
          :loading="loading"
          class="elevation-2"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn dark @click="resolveGift(item.id)">
            Résoudre
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import layoutHome from '../../layouts/layout-home.vue'
import {MKPSDK} from "@/packages/artebeaute-sdk/src";

export default {
  components: {},
  data() {
    return {
      page: null,
      giftHeaders: [
        { value: 'id', text: 'ID' },
        { value: 'transactionID', text: 'Transaction ID' },
        { value: 'createdAt', text: 'Créé le' },
        { value: 'content.customer.firstname', text: 'Prénom' },
        { value: 'content.customer.lastname', text: 'Nom' },
        {
          value: 'actions',
          text: this.$t('database.tables.actions'),
          align: 'right',
        },
      ],
      gifts: []
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  mounted() {
    this.page = 'gift'
  },
  watch: {
    page() {
      if (this.page === 'gift') {
        this.fetchGifts()
      }
    }
  },
  methods: {
    async fetchGifts() {
      const result = await MKPSDK.Maintenance.getAllGifts()
      this.gifts = result.carts
    },
    async resolveGift(cartId) {
      try {
        await MKPSDK.Maintenance.resolveGift(cartId)
        this.gifts = this.gifts.filter((c) => c.id !== cartId)
        alert('Succès de la résolution')
      } catch (err) {
        console.log(err)
        alert('Echec de la résolution')
      }
    }
  }
}
</script>

<style scoped></style>
