<template>
  <v-container>
    <categories-cards :values="blocks" />
  </v-container>
</template>

<script>
import blocksManagment from '@/helpers/management'
import layoutHome from '../../layouts/layout-home.vue'
import CategoriesCards from '@/components/general/categoriesCards'

export default {
  name: 'index',
  components: { CategoriesCards },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  computed: {
    blocks() {
      return blocksManagment().getBlockSortByCategory()
    }
  }
}
</script>

<style scoped></style>
