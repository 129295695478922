<template>
  <div style="margin-right: 10px">
    <v-row v-if="filter" style="overflow-y: auto" class="content">
      <v-col>
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Période du {{ filter.createAt.start }} au
              {{ filter.createAt.end }}</v-expansion-panel-header
            >
            <v-expansion-panel-content class="expPanContent">
              <v-row dense no-gutters>
                <v-col>
                  <v-radio-group row v-model="filter.periodeType" dense>
                    <v-radio label="Acheter le" value="dSendat"></v-radio>
                    <v-radio label="Réserver le" value="dBookingAt"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  <date-range @updateDate="changeDate" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Boutique(s) </v-expansion-panel-header>
            <v-expansion-panel-content class="expPanContent">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="shopSearch"
                    append-icon="mdi-magnify"
                    label="Rechercher"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-data-table
                    v-model="shopSelected"
                    :items="shops"
                    :headers="shopHeader"
                    :search="shopSearch"
                    :items-per-page="-1"
                    show-select
                    hide-default-header
                    hide-default-footer
                    height="300px"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Réservation</v-expansion-panel-header>
            <v-expansion-panel-content class="expPanContent">
              <v-row no-gutters>
                <v-col>
                  <v-select
                    dense
                    :items="bookerOrigins"
                    v-model="filter.booker.origin"
                    item-text="text"
                    item-value="value"
                    :menu-props="{ top: true, offsetY: true }"
                    label="Origine"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-select
                    dense
                    :items="bookerStatus"
                    v-model="filter.booker.status"
                    item-text="text"
                    item-value="value"
                    :menu-props="{ top: true, offsetY: true }"
                    label="Status"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Clients</v-expansion-panel-header>
            <v-expansion-panel-content class="expPanContent">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Nom"
                    dense
                    type="text"
                    v-model="filter.customer.lastName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Prénom"
                    dense
                    type="text"
                    v-model="filter.customer.firstName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email"
                    dense
                    type="email"
                    v-model="filter.customer.adresseMail"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Paiements</v-expansion-panel-header>
            <v-expansion-panel-content class="expPanContent">
              <v-row dense no-gutters>
                <v-col>
                  <v-text-field
                    label="N° de transaction"
                    dense
                    type="text"
                    v-model="filter.transactionId"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-spacer />
        <v-btn dark @click="search"> Rechercher </v-btn>
        <v-spacer />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// const Moment = require('moment')
// import DateRangePicker from 'vue2-daterange-picker'
//you need to import the CSS manually
// import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import DateRange from '@/components/general/Date/DateRange'
require('dayjs/locale/fr')
import dayjs from 'dayjs'

export default {
  components: { DateRange },
  // components: { DateRangePicker },
  props: {
    bookerOrigins: {
      type: Array,
      default: () => [],
    },
    bookerStatus: {
      type: Array,
      default: () => [],
    },
    myFilter: {
      type: Object,
      default: null,
    },
    initialDates: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      toggle_exclusive: undefined,
      filter: null,
      shopHeader: [{ text: 'Désignation', value: 'publicInfo.name' }],
      shopSearch: null,
      shopSelected: [],
      valRange: null,
      openRange: true,
      dateRange: {
        startDate: null,
        endDate: null,
      },
    }
  },
  watch: {
    myFilter: {
      handler(val) {
        this.filter = val
      },
      deep: true,
    },
    shopSelected: {
      handler(val) {
        this.filter.shops = val.reduce((prev, cur) => {
          prev.push(cur.artebeauteId)
          return prev
        }, [])
      },
    },
  },
  computed: {
    shops() {
      return this.$store.state.shop.shops ? this.$store.state.shop.shops : []
    },
  },
  mounted() {
    this.filter = this.myFilter
    dayjs.locale('fr')

    const t = setInterval(() => {
      if (this.filter) {
        if (this.initialDates) {
          this.changeDate(this.initialDates)
        }

        clearInterval(t)
      }
    }, 200)
  },
  methods: {
    changeDate(val) {
      this.filter.createAt.start = val.start
      this.filter.createAt.end = val.end
      this.$emit('onChangeDate', val)
    },
    toDay() {
      // const d = new Date().toISOString().split('T')[0]
      const myd = dayjs().format('YYYY-MM-DD')

      this.filter.createAt.start = myd // new Date().toISOString().split('T')[0]
      this.filter.createAt.end = myd // new Date().toISOString().split('T')[0]
      // return d.getDate() + '/' + d.getMonth()  + '/' + d.getFullYear()
    },
    lastSevenDay() {
      const ds = dayjs().subtract(6, 'days').format('YYYY-MM-DD')

      const de = dayjs().format('YYYY-MM-DD')

      this.filter.createAt.start = ds
      this.filter.createAt.end = de
    },
    previousWeek() {
      const ds = dayjs().subtract('1', 'week').day(1).format('YYYY-MM-DD')

      const de = dayjs()
        .subtract('1', 'week')
        .day(6)
        .add(1, 'day')
        .format('YYYY-MM-DD')

      this.filter.createAt.start = ds
      this.filter.createAt.end = de
    },
    currentWeek() {
      const ds = dayjs().day(1).format('YYYY-MM-DD')

      const de = dayjs().day(6).add(1, 'day').format('YYYY-MM-DD')

      this.filter.createAt.start = ds
      this.filter.createAt.end = de
    },
    currentMonth() {
      const ds = dayjs().date(1).format('YYYY-MM-DD')

      const de = dayjs().date(dayjs().daysInMonth()).format('YYYY-MM-DD')

      this.filter.createAt.start = ds
      this.filter.createAt.end = de
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    close() {
      this.$emit('close')
    },
    search() {
      this.$emit('search', this.filter)
    },
  },
}
</script>

<style scoped>
.content {
  max-height: 650px;
}
.expPanContent {
  border: 3px #333333 solid;
  padding-top: 15px;
}
@media (max-height: 920px) {
  .content {
    max-height: 500px;
  }
}
</style>
