<template>
  <v-container class="my-8">
    <menu-cards :values="blocks" />
  </v-container>
</template>

<script>
import MenuCards from '../../components/general/menuCards'
import LayoutHome from '../../layouts/layout-home'

export default {
  name: 'index',
  components: { MenuCards },
  created() {
    this.$emit('update:layout', LayoutHome)
  },
  computed: {
    blocks() {
      return [
        {
          index: 0,
          title: this.$t('communications.yourEvents'),
          description: this.$t('communications.eventsManagement'),
          color: '#1D4ED8',
          to: '/communications/events'
        },
        {
          index: 1,
          title: this.$t('communications.yourAlerts'),
          description: this.$t('communications.alertsManagement'),
          color: '#B91C1C',
          to: '/communications/alerts'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
