<template>
  <div>
    <v-overlay
      class="d-flex justify-center align-center"
      v-if="loading"
      absolute
    >
      <v-progress-circular indeterminate />
    </v-overlay>

    <v-row class="ma-0 mb-7" align="center">
      <v-col cols="3" class="d-none d-md-inline-block">
        <!--
        <v-icon color="dark" size="40" @click="back">
          mdi-keyboard-backspace
        </v-icon>-->
      </v-col>
      <v-col
        cols="6"
        class="text-uppercase text-center font-weight-bold"
        style="font-size: 1.2rem"
      >
        {{ $t('communications.eventsManagement') }}
      </v-col>
      <v-col cols="6" md="3" class="text-end">
        <v-btn class="ma-2" color="primary" @click="createEvent">
          {{ $t('icons.new') }}
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="events.length">
      <v-card
        v-for="event in events"
        :key="event.id"
        class="ma-5"
        :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
      >
        <v-img
          v-if="event.img.length"
          :src="event.img[0].url"
          :max-width="$vuetify.breakpoint.smAndUp ? 200 : ''"
          :max-height="$vuetify.breakpoint.xsOnly ? 200 : ''"
        />
        <div class="pa-2">
          <v-card-title>
            {{
              $t('communications.events.fromTo', {
                start: getStartDate(event.start),
                end: getEndDate(event.end)
              })
            }}
          </v-card-title>
          <v-card-text
            v-html="controli18n($i18n.locale, event.body)"
            style="height: 120px; overflow: auto"
          />
          <v-card-text v-if="event.link.url">
            {{ $t('communications.events.redirectsTo') }}
            <span class="link" @click="redirect(event.link.url)">
              {{ event.link.url }}
            </span>
          </v-card-text>
        </div>
        <v-spacer />
        <v-card-actions style="min-width: 200px" class="justify-end mr-3">
          <v-btn icon @click="updateEvent(event)">
            <v-icon color="blue darken-3">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteEvent(event)">
            <v-icon color="red darken-1">mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <div v-if="oldEvents.length" class="mt-10">
      <div class="oldEventsTitle">
        {{ $t('communications.events.pastEvents') }}
      </div>

      <v-card
        v-for="event in oldEvents"
        :key="event.id"
        class="ma-5"
        :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
      >
        <v-img
          v-if="event.img.length"
          :src="event.img[0].url"
          :max-width="$vuetify.breakpoint.smAndUp ? 200 : ''"
          :max-height="$vuetify.breakpoint.xsOnly ? 200 : ''"
        />
        <div class="pa-2">
          <v-card-title>
            {{
              $t('communications.events.fromTo', {
                start: getStartDate(event.start),
                end: getEndDate(event.end)
              })
            }}
          </v-card-title>
          <v-card-text
            v-html="controli18n($i18n.locale, event.body)"
            style="height: 120px; overflow: auto"
          />
          <v-card-text v-if="event.link.url">
            {{ $t('communications.events.redirectsTo') }}
            <span class="link" @click="redirect(event.link.url)">
              {{ event.link.url }}
            </span>
          </v-card-text>
        </div>
        <v-spacer />
        <v-card-actions style="min-width: 200px" class="justify-end mr-3">
          <v-btn icon @click="updateEvent(event)">
            <v-icon color="blue darken-3">mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteEvent(event)">
            <v-icon color="red darken-1">mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-alert
      v-if="!events.length && !oldEvents.length"
      outlined
      class="text-center"
    >
      {{ $t('communications.events.haveNotCreatedEventYet') }}
    </v-alert>

    <v-dialog v-model="createEventDialog" width="950">
      <v-card v-if="createEventDialog">
        <div>
          <v-card-title>{{
            $t('communications.events.createNewEvent')
          }}</v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-3" style="height: 65vh; overflow: auto">
          <edit-events :edit="edit" @updateEdit="edit = $event" />

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="createEventDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="create" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updateEventDialog" width="950">
      <v-card v-if="updateEventDialog">
        <div>
          <v-card-title>{{
            $t('communications.events.editEvent')
          }}</v-card-title>
          <v-divider />
        </div>

        <v-card-text class="px-3" style="height: 65vh; overflow: auto">
          <edit-events :edit="edit" @updateEdit="edit = $event" />

          <v-overlay
            class="d-flex justify-center align-center"
            v-if="saveLoading"
            absolute
            color="grey"
          >
            <v-progress-circular indeterminate />
          </v-overlay>
        </v-card-text>

        <div>
          <v-divider />
          <v-card-actions>
            <v-btn text @click="updateEventDialog = false">
              {{ $t('icons.close') }}
            </v-btn>
            <v-spacer />
            <v-btn type="button" @click="update" color="success" tile>
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EditEvents from '../../../components/events/editEvents'
import { controli18n } from '@/helpers/langs'
import swal from 'sweetalert'

export default {
  name: 'index',
  components: { EditEvents },
  async mounted() {
    await this.$store.dispatch('events/fetchEvents')
  },
  data: () => ({
    loading: false,
    saveLoading: false,
    edit: null,
    createEventDialog: false,
    updateEventDialog: false,
    saveEvent: null
  }),
  computed: {
    ...mapGetters('events', ['getEvents']),
    oldEvents() {
      return this.getEvents.filter(event => new Date(event.end) < new Date())
    },
    events() {
      return this.getEvents.filter(event => new Date(event.end) >= new Date())
    }
  },
  methods: {
    controli18n,
    createEvent() {
      this.edit = {
        img: [],
        showDates: false,
        start: null,
        end: null,
        body: {},
        link: {}
      }

      this.createEventDialog = true
    },
    updateEvent(event) {
      const start = new Date(event.start)
      const startFormatted =
        start.getFullYear() +
        '-' +
        this.getFormattedMonth(start) +
        '-' +
        this.getFormattedDay(start)

      const end = new Date(event.end)
      const endFormatted =
        end.getFullYear() +
        '-' +
        this.getFormattedMonth(end) +
        '-' +
        this.getFormattedDay(end)

      this.edit = {
        img: event.img,
        showDates: event.showDates,
        start: startFormatted,
        end: endFormatted,
        body: event.body,
        link: event.link
      }

      this.saveEvent = event

      this.updateEventDialog = true
    },
    getStartDate(start) {
      const date = new Date(start)
      return date.getDate() + ' ' + this.getMonth(date.getMonth())
    },
    getEndDate(end) {
      const date = new Date(end)
      return date.getDate() + ' ' + this.getMonth(date.getMonth())
    },
    getMonth(id) {
      const months = [
        this.$t('months.january'),
        this.$t('months.february'),
        this.$t('months.march'),
        this.$t('months.april'),
        this.$t('months.may'),
        this.$t('months.june'),
        this.$t('months.july'),
        this.$t('months.august'),
        this.$t('months.september'),
        this.$t('months.october'),
        this.$t('months.november'),
        this.$t('months.december')
      ]

      return months[id]
    },
    getFormattedMonth(date) {
      const format = Number(date.getMonth() + 1)

      if (format < 10) {
        return '0' + format
      } else {
        return format
      }
    },
    getFormattedDay(date) {
      const format = Number(date.getDate())

      if (format < 10) {
        return '0' + format
      } else {
        return format
      }
    },
    redirect(link) {
      window.open(link, '_blank')
    },
    formControl() {
      if (!this.edit.start) {
        swal({
          title: this.$t('communications.events.startDateRequired'),
          icon: 'error'
        })
        return false
      }

      if (!this.edit.end) {
        swal({
          title: this.$t('communications.events.endDateRequired'),
          icon: 'error'
        })
        return false
      }

      if (new Date(this.edit.start) >= new Date(this.edit.end)) {
        swal({
          title: this.$t('communications.events.startAfterEnd'),
          icon: 'error'
        })
        return false
      }

      if (!controli18n(this.$i18n.locale, this.edit.body)) {
        swal({
          title: this.$t('communications.events.contentRequired'),
          icon: 'error'
        })
        return false
      }

      return true
    },

    async create() {
      this.saveLoading = true

      if (this.formControl()) {
        const response = await this.$store.dispatch(
          'events/createEvent',
          this.edit
        )

        if (!response) {
          await swal({
            title: this.$t('other.anErrorOccurred'),
            icon: 'error'
          })
        } else {
          this.edit = null
          this.createEventDialog = false
        }
      }

      this.saveLoading = false
    },
    async update() {
      this.saveLoading = true

      if (this.formControl()) {
        const response = await this.$store.dispatch('events/updateEvent', {
          id: this.saveEvent.id,
          data: this.edit
        })

        if (!response) {
          await swal({
            title: this.$t('other.anErrorOccurred'),
            icon: 'error'
          })
        } else {
          this.edit = null
          this.saveEvent = null
          this.updateEventDialog = false
        }
      }
      this.saveLoading = false
    },
    async deleteEvent(event) {
      swal({
        title: this.$t('communications.events.sureDeleteEvent'),
        buttons: [this.$t('icons.cancel'), this.$t('icons.delete')]
      }).then(async willDelete => {
        if (willDelete) {
          this.saveLoading = true

          await this.$store.dispatch('events/deleteEvent', {
            event
          })

          this.saveLoading = false
        }
      })
    }
  }
}
</script>

<style scoped>
.link {
  font-weight: 300;
  cursor: pointer;
  letter-spacing: 1px;
}

.oldEventsTitle {
  font-weight: bold;
  font-size: 1.2rem;
  border-bottom: 1px solid #333;
}
</style>
