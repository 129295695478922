<template>
  <div>
    <v-row align="stretch">
      <v-col
        cols="12"
        md="3"
        v-for="(card, index) in values
          .filter(i => !i.secret || showSecret)
          .filter(i => haveAccess(i))"
        :key="'card-' + index"
      >
        <menu-card
          :title="card.title"
          :icon="card.icon"
          :description="card.description"
          :color="card.color"
          :to="card.to"
          :commingSoon="card.commingSoon"
          :in-development="card.inDevelopment"
          directory
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MenuCard from './menuCard'
export default {
  components: { MenuCard },
  props: {
    values: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      showSecret: false,
      showSecretTyped: ''
    }
  },
  mounted() {
    document.addEventListener('keydown', this.secretCode)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.secretCode)
  },
  methods: {
    haveAccess(i) {
      if (!i.acl) {
        return true
      }

      if (this.$store.state.auth.user.role === 'cmpadmin') {
        return true
      }

      return i.acl.includes(this.$store.state.auth.user.role)
    },
    secretCode(e) {
      // e.preventDefault()

      this.showSecretTyped += String.fromCharCode(e.keyCode) + ''

      if (!'SECRET'.startsWith(this.showSecretTyped)) {
        this.showSecretTyped = ''
      }

      if (this.showSecretTyped === 'SECRET') {
        this.showSecret = true
      }
    }
  }
}
</script>

<style scoped></style>
