<template>
  <v-app-bar dense app fixed tile flat raised color="white">
    <v-app-bar-title v-if="actions">
      <slot name="buttonNav" />
      <v-icon small :color="actions.color">{{ actions.icon }}</v-icon>
      <span class="pl-2" :style="{ color: actions.color }"
        ><strong> {{ actions.description || actions.title }}</strong></span
      ></v-app-bar-title
    >
    <v-spacer></v-spacer>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <template v-for="(item, index) in actions.navBottom">
        <v-btn icon :key="index" :to="item.to">
          <v-icon>{{ item.icon }}</v-icon>
        </v-btn>
      </template>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    actions: { type: Object, default: () => null }
  },
  methods: {
    goBack() {
      const parent = this.$route.path
        .split('/')
        .slice(0, -1)
        .join('/')

      this.$router.push(parent ? parent : '/')
    }
  }
}
</script>

<style></style>
