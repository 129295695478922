<template>
  <v-menu
    v-model="menu"
    :close-on-click="false"
    :close-on-content-click="false"
    :nudge-width="200"
    max-width="350"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="display: flex; flex-wrap: wrap;justify-content: center">
        <v-btn
          style="background-color: white"
          small
          color="black"
          outlined
          v-bind="attrs"
          v-on="on"
          width="200px"
        >
          <span>{{ text }}</span>
        </v-btn>
        <slot name="button-inset" />
      </div>
    </template>

    <v-card>
      <v-card-title>
        <slot name="title" />
      </v-card-title>
      <v-card-text>
        <slot name="body" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn text @click="close()">Fermer</v-btn>
        <v-spacer />
        <v-btn bottom dark @click="save()">Appliquer</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'bmMenuButton',
  data() {
    return {
      menu: false
    }
  },
  props: {
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('onClose', () => (this.menu = false))
    },
    save() {
      this.$emit('onSave', () => (this.menu = false))
    }
  }
}
</script>

<style scoped></style>
