<template>
  <v-container>
    <h1>Ajouter un compte</h1>
    <v-divider class="mb-6" />

    <div>
      <UserForm :from-manager="true" />
    </div>
  </v-container>
</template>

<script>
import UserForm from '../../components/users/form.vue'
import layoutHome from '../../layouts/layout-home'

export default {
  name: 'Home',
  components: {
    UserForm
  },
  data: () => {
    return {
      error: null,
      users: []
    }
  },
  created() {
    this.$emit('update:layout', layoutHome)
  },
  methods: {}
}
</script>
