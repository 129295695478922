<template>
  <v-sheet
    outlined
    rounded
    style="margin-left: 5px;margin-right: 5px;margin-top: 10px;margin-bottom: 10px"
  >
    <div
      style="margin:5px;background-color:#f3f3f3;padding:3px;position: relative; top:-15px"
    >
      <strong>{{ label }}</strong>
    </div>
    <div style="position:relative; top:-10px;padding: 10px; text-align: center">
      {{
        currency(amount, {
          symbol: '€',
          separator: ' ',
          decimal: ',',
          pattern: '# !'
        }).format()
      }}
    </div>
  </v-sheet>
</template>

<script>
import currency from 'currency.js'
export default {
  name: 'totalizer',
  props: {
    amount: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: () => 'Titre'
    }
  },
  methods: {
    currency
  }
}
</script>

<style scoped>
.bloc {
}
</style>
