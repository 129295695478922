<template>
  <div>
    <bm-menu-button text="Boutique" @onClose="handleClose" @onSave="handleSave">
      <template #body>
        <div class="bm-search">
          <input
            type="text"
            class="bm-search__input"
            placeholder="Rechercher"
            v-model="search"
          />
        </div>

        <div class="bm-actions">
          <div class="bm-actions__all"></div>
        </div>

        <div class="bm-list--fixed-height">
          <v-treeview
            selectable
            :items="grps"
            v-model="selection"
            item-key="abId"
            dense
            selected-color="primary"
            :search="search"
            :class="group ? 'sst-grouped' : 'sst-no-grouped'"
          >
            <template v-slot:prepend="{ item }">
              <v-icon v-if="group && item.children">
                mdi-folder
              </v-icon>
              <v-icon v-else>
                mdi-store
              </v-icon>
            </template>
          </v-treeview>
        </div>
      </template>
    </bm-menu-button>
  </div>
</template>

<script>
import BmMenuButton from '@/components/base/bmMenuButton'

export default {
  name: 'BmShopsSelector',
  components: { BmMenuButton },
  props: {
    only: {
      type: Array,
      default: null
    },
    preselected: {
      type: Array,
      default: () => []
    },
    group: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selection: [],
      search: ''
    }
  },
  mounted() {
    if (this.preselected) {
      this.selection = JSON.parse(JSON.stringify(this.preselected))
    }
  },
  computed: {
    grps() {
      if (this.group) {
        return this.shops.reduce((acc, cur) => {
          const grp = acc.find(g => g.id === 'grp-' + cur.grp.id)

          if (!grp) {
            acc.push({
              id: 'grp-' + cur.grp.id,
              name: cur.grp.name,
              abId: 'grp-' + cur.grp.id,
              children: [cur]
            })
          } else {
            grp.children.push(cur)
          }

          return acc
        }, [])
      } else {
        return this.shops.reduce((acc, cur) => {
          acc.push({
            ...cur
          })

          return acc
        }, [])
      }
    },
    shops() {
      return this.$store.getters['shop/fusionShop']()
        .filter(
          s =>
            this.$store.state.auth.user.role === 'cmpadmin' ||
            this.$store.state.auth.user.aclStatsShops.includes(s.abId)
        )
        .filter(s => !this.only || this.only.includes(s.abId))
    }
  },
  methods: {
    handleClose(cb) {
      this.selection = JSON.parse(
        JSON.stringify(this.$store.state.shop.statsSelectedShops)
      )
      cb()
    },
    async handleSave(cb) {
      await this.$store.dispatch('shop/setStatsSelectedShops', this.selection)
      cb()
      this.$emit('onSelection', this.selection)
    }
  }
}
</script>

<style scoped lang="scss">
.bm-search {
  margin-bottom: 8px;

  &__input {
    display: block;
    width: 100%;
    padding: 4px;
    border: 1px solid #5f5f5f;
  }
}

.bm-list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &--fixed-height {
    max-height: 250px;
    overflow-y: auto;
  }

  &__group {
    font-weight: bold;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__item {
  }

  &__line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  &__item-checkbox {
  }

  &__item-title {
  }
}
</style>

<style>
.sst-no-grouped .v-treeview-node__level {
  display: none !important;
}
</style>
