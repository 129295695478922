<template>
  <div>
    <v-overlay :value="progressBar && progressBar.isLoading">
      <div class="cardloading">
        <div v-if="title">
          <span style="color: black">{{ title }} </span>
        </div>
        <div>
          <v-progress-circular
            v-if="progressBar.type === 'circular'"
            color="black"
            :indeterminate="progressBar.value === -1"
          />
          <v-progress-linear
            v-else-if="
              progressBar.type === 'linear' && progressBar.value === -1
            "
            color="primary"
            indeterminate
          ></v-progress-linear
          ><v-progress-linear
            v-else-if="
              progressBar.type === 'linear' && progressBar.value !== -1
            "
            color="primary"
            v-model="progressBar.value"
            height="25px"
          ></v-progress-linear>
          <div style="color: black;padding-top: 10px">
            <span>{{ progressBar.title }}</span>
          </div>
        </div>
        <div v-if="progressBarSecondary && progressBarSecondary.isLoading">
          <v-progress-circular
            v-if="progressBarSecondary.type === 'circular'"
            color="black"
            indeterminate
          />
          <v-progress-linear
            v-else-if="
              progressBarSecondary.type === 'linear' &&
                progressBarSecondary.value === -1
            "
            color="primary"
            indeterminate
            height="25px"
          >
            <strong style="color:black">{{
              progressBarSecondary.title
            }}</strong> </v-progress-linear
          ><v-progress-linear
            v-else-if="
              progressBarSecondary.type === 'linear' &&
                progressBarSecondary.value !== -1
            "
            color="primary"
            v-model="progressBarSecondary.value"
            height="25px"
          >
            <strong style="color:black">{{
              progressBarSecondary.title
            }}</strong>
          </v-progress-linear>
        </div>

        <div
          v-if="
            isStoppable &&
              (progressBar.isLoading || progressBarSecondary.isLoading)
          "
        >
          <v-btn dark @click="stopSearch">Arrêter la recherche</v-btn>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import dayjs from 'dayjs'
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)
export default {
  name: 'loadingPagination',
  props: {
    title: {
      type: String
    },
    progressBar: {
      type: Object,
      default: null
    },
    progressBarSecondary: {
      type: Object,
      default: null
    },
    stoppable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      myTitle: '',
      isStoppable: false
    }
  },
  mounted() {
    this.myTitle = this.title
    this.isStoppable = this.stoppable
  },
  watch: {
    stoppable: {
      handler(val) {
        this.isStoppable = val
      },
      deep: true
    },
    title: {
      handler(val) {
        this.myTitle = val
      },
      deep: true
    }
  },
  methods: {
    humanizeDuration(val) {
      return dayjs.duration(val).format('mm [min] s [sec] ss sss ')
    },
    stopSearch() {
      this.$emit('stop')
    }
  }
}
</script>

<style scoped>
.cardloading {
  background-color: #ffffff;
  padding: 20px;
  color: black;
  min-width: 400px;
  border: 1px solid black;
  border-radius: 4px;
}
.cardloading div {
  padding-bottom: 15px;
  text-align: center;
}
</style>
