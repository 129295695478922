<template>
  <div class="content-cards">
    <div
      v-for="(category, index) in values.filter(
        v =>
          v.items
            .filter(i => !i.secret || showSecret)
            .filter(i => haveAccess(i)).length
      )"
      :key="'category-' + index"
    >
      <div class="content-category-title">
        <span class="category-title">{{ category.title }}</span>
        <span class="category-line" />
      </div>

      <v-row align="stretch" class="my-6">
        <v-col
          cols="12"
          md="3"
          v-for="(card, index_card) in category.items
            .filter(i => !i.secret || showSecret)
            .filter(i => haveAccess(i))"
          :key="'card-' + index_card"
        >
          <menu-card
            :title="card.title"
            :icon="card.icon"
            :description="card.description"
            :color="card.color"
            :to="card.to"
            :commingSoon="card.commingSoon"
            :in-development="card.inDevelopment"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MenuCard from './menuCard'
export default {
  components: { MenuCard },
  props: {
    values: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      showSecret: false,
      showSecretTyped: ''
    }
  },
  mounted() {
    document.addEventListener('keydown', this.secretCode)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.secretCode)
  },
  methods: {
    haveAccess(i) {
      if (!i.acl) {
        return true
      }

      if (this.$store.state.auth.user.role === 'cmpadmin') {
        return true
      }

      return i.acl.includes(this.$store.state.auth.user.role)
    },
    secretCode(e) {
      // e.preventDefault()

      this.showSecretTyped += String.fromCharCode(e.keyCode) + ''

      if (!'SECRET'.startsWith(this.showSecretTyped)) {
        this.showSecretTyped = ''
      }

      if (this.showSecretTyped === 'SECRET') {
        this.showSecret = true
      }
    }
  }
}
</script>

<style scoped>
.content-cards {
  max-width: 1200px;
  padding: 80px 0;
}

.card-menu {
  margin: 25px 20px 50px;
  height: 160px;
  vertical-align: top;
}

.content-category-title {
  margin-left: 20px;
  display: grid;
  align-items: center;
  column-gap: 20px;
  grid-template: auto / auto 1fr;
}

.category-title {
  text-transform: uppercase;
  font-size: 1.2rem;
  color: black;
  font-weight: 700;
}

.category-line {
  max-width: 500px;
  width: 100%;
  height: 2px;
  background-color: black;
  border-radius: 10px;
}
</style>
