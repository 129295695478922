<template>
  <div style="margin-right: 10px">
    <v-row v-if="filter" style="overflow-y: auto" class="content">
      <v-col>
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Période du {{ filter.createAt.start }} au
              {{ filter.createAt.end }}</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row dense no-gutters>
                <v-col>
                  <date-range @updateDate="changeDate" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Code Bon Cadeau</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    label="Numéro de code"
                    dense
                    type="text"
                    v-model="filter.code"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Numero de transaction</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-col>
                  <v-text-field
                    label="Numéro de code"
                    dense
                    type="text"
                    v-model="filter.transactionId"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Clients</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Nom"
                    dense
                    type="text"
                    v-model="filter.customerBuy.lastName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Prénom"
                    dense
                    type="text"
                    v-model="filter.customerBuy.firstName"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Email"
                    dense
                    type="text"
                    v-model="filter.customerBuy.adresseMail"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-spacer />
        <v-btn dark @click="search"> Rechercher </v-btn>
        <v-spacer />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DateRange from '@/components/general/Date/DateRange'
export default {
  components: { DateRange },
  props: {
    bookerOrigins: {
      type: Array,
      default: () => [],
    },
    myFilter: {
      type: Object,
      default: null,
    },
    initialDates: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      filter: null,
    }
  },
  watch: {
    myFilter: {
      handler(val) {
        this.filter = val
      },
      deep: true,
    },
  },
  mounted() {
    this.filter = this.myFilter

    const t = setInterval(() => {
      if (this.filter) {
        if (this.initialDates) {
          this.changeDate(this.initialDates)
        }

        clearInterval(t)
      }
    }, 100)
  },
  methods: {
    changeDate(val) {
      this.filter.createAt.start = val.start
      this.filter.createAt.end = val.end
      this.$emit('onChangeDate', val)
    },
    close() {
      this.$emit('close')
    },
    search() {
      this.$emit('search', this.filter)
    },
  },
}
</script>

<style scoped></style>
