<template>
  <div style="marginbottom: 70px">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="mini"
      app
      :dark="true"
      :floating="true"
      :right="$vuetify.rtl"
      color="grey darken-4"
    >
      <v-list dense nav>
        <v-list-item class="px-0">
          <v-list-item-avatar>
            <v-icon @click="mini = !mini">mdi-database</v-icon>
          </v-list-item-avatar>
          <v-list-item-title
            v-if="!mini"
            class="text-18 text-uppercase text-default"
          >
            <span>Données générales</span></v-list-item-title
          >
        </v-list-item>
      </v-list>
      <v-divider dark />
      <v-list>
        <template v-for="(item, i) in blocks">
          <v-list-item :height="48" :to="item.to" :key="`block-${i}`">
            <v-list-item-icon>
              <v-icon v-text="item.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              {{ item.title }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <header-app-bar :actions="myblock">
      <template v-slot:buttonNav>
        <v-app-bar-nav-icon
          @click="
            drawer = !drawer
            mini = false
          "
        ></v-app-bar-nav-icon>
      </template>
    </header-app-bar>
    <v-main class="my-14 mx-auto lighten-3">
      <slot />
    </v-main>
    <bottom-2-nav
      v-if="!$vuetify.breakpoint.mdAndUp"
      :actions="myblockAction"
    />
  </div>
</template>

<script>
import Bottom2Nav from '../components/general/Bottom2Nav.vue'
import HeaderAppBar from '../components/general/headerAppBar.vue'

export default {
  components: { Bottom2Nav, HeaderAppBar },
  props: {
    drawerBar: { type: Boolean, default: false },
  },
  data: () => {
    return {
      block: null,
      drawer: true,
      mini: true,
    }
  },
  computed: {
    blocks() {
      return [...this.$store.state.blocks.blocks]
    },
    parentPath: function () {
      const parent = this.$route.path.split('/').slice(0, -1).join('/')
      return parent ? parent : '/'
    },
    myblockAction: function () {
      const block = this.getBlockByTo(this.$route.path)

      if (block) {
        return block.navBottom
          ? block.navBottom
          : [
              {
                to: '/',
                icon: 'mdi-home',
                tooltip: `retour à l accueil`,
              },
            ]
      }
      return [
        {
          to: '/',
          icon: 'mdi-home',
          tooltip: `retour à l accueil`,
        },
      ]
    },
    myblock: function () {
      const block = this.getBlockByTo(this.$route.path)
      return block
        ? block
        : {
            title: 'Donnés générales',
            icon: 'mdi-database',
            navBottom: [
              {
                to: '/',
                icon: 'mdi-home',
                tooltip: `retour à l accueil`,
              },
            ],
          }
    },
  },
  mounted() {
    this.drawer = this.drawerBar
  },
  methods: {
    getBlockByTo(path) {
      const matches = this.blocks.filter((b) => this.routeMatch(path, b.to))

      let bestMatch = matches[0]

      matches.forEach((m) => {
        if (m.to.length > bestMatch.to.length) {
          bestMatch = m
        }
      })

      return bestMatch
    },
    routeMatch(path, to) {
      return path.startsWith(to)
    },
  },
}
</script>

<style></style>
