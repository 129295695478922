<template>
  <div>
    <v-app>
      <v-app-bar app fixed flat dark>
        <v-toolbar-title>Transactions</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon to="../booker"><v-icon>mdi-close</v-icon></v-btn>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <v-row style="background-color: #999999">
            <v-col cols="12" xl="6">
              <booker-aggregate
                periode-title="Journée en cours"
                :aggregates="aggregates"
                ref="bookerAggregate"
              ></booker-aggregate>
            </v-col>
          </v-row>
          <v-row style="border-top: 3px solid black">
            <v-col
              :cols="openSearchBox ? 5 : 2"
              :md="openSearchBox ? 5 : 2"
              :lg="openSearchBox ? 4 : 2"
              ><v-btn
                v-if="!openSearchBox"
                @click="openSearchBox = true"
                text
                tile
              >
                <v-icon color="black" left>mdi-filter</v-icon>
                <span v-show="$vuetify.breakpoint.mdAndUp">Filtrer</span>
              </v-btn>
              <v-btn icon v-else @click="openSearchBox = false"
                ><v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col
              :cols="openSearchBox ? 7 : 10"
              :md="openSearchBox ? 7 : 10"
              :lg="openSearchBox ? 8 : 10"
            >
              <v-tabs v-model="tab" grow right color="black">
                <v-tab>
                  <v-icon left>mdi-spa</v-icon>
                  Réservation en ligne
                </v-tab>
                <v-tab>
                  <v-icon left>mdi-gift</v-icon>
                  Bon Cadeaux
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col v-if="openSearchBox" cols="12" md="5" lg="4">
              <booker-search
                v-if="tab === 0"
                :my-filter="bookingFilter"
                :booker-origins="bookerOrigins"
                :booker-status="bookerStatus"
                @search="searchBookerWithFilter"
                @close="openSearchBox = false"
                @onChangeDate="setAggregatePeriod"
                :initial-dates="choosenDates"
              />
              <gift-search
                v-else
                :my-filter="giftFilter"
                :booker-origins="bookerOrigins"
                @close="openSearchBox = false"
                @search="searchGiftWithFilter"
                @onChangeDate="setAggregatePeriod"
                ref="giftSearch"
                :initial-dates="choosenDates"
              />
            </v-col>
            <v-col
              :cols="openSearchBox ? 12 : 12"
              :md="openSearchBox ? 7 : 12"
              :lg="openSearchBox ? 8 : 12"
            >
              <div v-if="tab === 0" style="overflow-x: auto">
                <v-data-table
                  dense
                  :headers="bookingHeaders"
                  :items="orderItems"
                  :options.sync="bookingOptions"
                  :server-items-length="bookingtotalItems"
                  :loading="bookingLoading"
                  :footer-props="footerProp"
                  :fixed-header="true"
                  :hide-default-header="true"
                  style="border: 1px solid #cccccc"
                  height="55vh"
                  :hide-default-footer="true"
                >
                  <template v-slot:header="{ props: { headers } }">
                    <tr>
                      <slot v-for="header in headers">
                        <th
                          style="
                            font-size: 0.8rem;
                            background-color: #f3f3f3;
                            padding: 5px;
                            border-right: 1px solid #cccccc;
                            border-bottom: 1px solid #cccccc;
                            border-top: 3px solid #cccccc;
                            position: sticky;
                            top: 0px;
                            z-index: 100;
                          "
                          :key="header.value"
                          :width="header.width"
                          :style="{ minWidth: header.width + 'px' }"
                        >
                          <div class="headerTableRubrique">
                            <div></div>
                            <div style="margin: auto">
                              {{ header.text }}
                            </div>
                            <div>
                              <!--                              <v-icon-->
                              <!--                                v-if="-->
                              <!--                                  header.value !== 'actions' &&-->
                              <!--                                  bookingOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                class="sortButton"-->
                              <!--                                @click="-->
                              <!--                                  bookingOptions.sortBy = header.value-->
                              <!--                                  bookingOptions.sortDesc =-->
                              <!--                                    !bookingOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                >mdi-arrow-down-thin-circle-outline-->
                              <!--                              </v-icon>-->
                              <!--                              <v-icon-->
                              <!--                                v-if="-->
                              <!--                                  header.value !== 'actions' &&-->
                              <!--                                  !bookingOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                class="sortButton"-->
                              <!--                                @click="-->
                              <!--                                  bookingOptions.sortBy = header.value-->
                              <!--                                  bookingOptions.sortDesc =-->
                              <!--                                    !bookingOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                >mdi-arrow-up-thin-circle-outline-->
                              <!--                              </v-icon>-->
                            </div>
                          </div>
                        </th>
                      </slot>
                    </tr>
                  </template>
                  <template v-slot:loading>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        padding: 20px;
                        justify-content: center;
                      "
                    >
                      <div><v-progress-circular button indeterminate /></div>
                      <div>Chargement en cours</div>
                    </div>
                  </template>
                  <template v-slot:item="{ item, index }">
                    <tr
                      :id="index"
                      v-bind:style="{
                        backgroundColor: getBookerColorLine(item),
                      }"
                    >
                      <td style="border-right: 1px solid #cccccc">
                        <v-icon @click="getBooker(item.nId, item.norigin)"
                          >mdi-pencil</v-icon
                        >
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ item.nId }}
                      </td>
                      <td style="border-right: 1px solid #cccccc">
                        {{ getStatus(item.mStatus) }}
                      </td>
                      <td style="border-right: 1px solid #cccccc">
                        {{ getOrigin(item.norigin) }}
                      </td>
                      <td style="border-right: 1px solid #cccccc">
                        {{ getShopName(item.sShopId) }}
                      </td>
                      <td
                        style="
                          border-right: 1px solid #cccccc;
                          text-align: center;
                        "
                      >
                        {{ formatDate(item.dSendat) }}
                      </td>
                      <td
                        style="
                          border-right: 1px solid #cccccc;
                          text-align: center;
                        "
                      >
                        {{ formatShortDate(item.dBookingAt) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ getFormatCurrency(item.mAmount) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ getFormatCurrency(item.mCreditUsed) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ getFormatCurrency(item.mGiftUsed) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ getFormatCurrency(item.mPaid) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ getFormatCurrency(getMontantEncaisse(item)) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ getFormatCurrency(getCreditRestant(item)) }}
                      </td>
                      <td style="border-right: 1px solid #cccccc">
                        {{ item.customerLastname }}
                      </td>
                      <td style="border-right: 1px solid #cccccc">
                        {{ item.customerFirstname }}
                      </td>
                      <td style="border-right: 1px solid #cccccc">
                        {{ item.customerPhone }}
                      </td>
                      <td style="border-right: 1px solid #cccccc">
                        {{ item.customerEmail }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <div>
                  <v-pagination
                    v-model="bookingOptions.page"
                    :length="
                      Math.ceil(bookingtotalItems / bookingOptions.itemsPerPage)
                    "
                  />
                </div>
              </div>
              <div v-else-if="tab === 1">
                <v-data-table
                  dense
                  :items="giftItems"
                  :headers="giftHeaders"
                  :options.sync="giftOptions"
                  :server-items-length="giftTotalItems"
                  :loading="giftLoading"
                  :fixed-header="true"
                  :hide-default-header="true"
                  :footer-props="footerProp"
                  height="55vh"
                  hide-default-footer
                >
                  <template v-slot:header="{ props: { headers } }">
                    <tr>
                      <slot v-for="header in headers">
                        <th
                          style="
                            font-size: 0.8rem;
                            background-color: #f3f3f3;
                            padding: 5px;
                            border-right: 1px solid #cccccc;
                            border-bottom: 1px solid #cccccc;
                            border-top: 3px solid #cccccc;
                            position: sticky;
                            top: 0px;
                            z-index: 100;
                          "
                          :key="header.value"
                          :width="header.width"
                          :style="{ minWidth: header.width + 'px' }"
                        >
                          <div
                            v-if="header.sortable"
                            class="headerTableRubrique"
                          >
                            <div></div>
                            <div style="margin: auto">{{ header.text }}</div>
                            <div>
                              <!--                              <v-icon-->
                              <!--                                v-if="-->
                              <!--                                  header.value !== 'actions' &&-->
                              <!--                                  giftOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                class="sortButton"-->
                              <!--                                @click="-->
                              <!--                                  giftOptions.sortBy = header.value-->
                              <!--                                  giftOptions.sortDesc = !giftOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                >mdi-arrow-down-thin-circle-outline-->
                              <!--                              </v-icon>-->
                              <!--                              <v-icon-->
                              <!--                                v-if="-->
                              <!--                                  header.value !== 'actions' &&-->
                              <!--                                  !giftOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                class="sortButton"-->
                              <!--                                @click="-->
                              <!--                                  giftOptions.sortBy = header.value-->
                              <!--                                  giftOptions.sortDesc = !giftOptions.sortDesc-->
                              <!--                                "-->
                              <!--                                >mdi-arrow-up-thin-circle-outline-->
                              <!--                              </v-icon>-->
                            </div>
                          </div>
                          <div v-else>
                            {{ header.text }}
                          </div>
                        </th>
                      </slot>
                    </tr>
                  </template>
                  <template v-slot:loading>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        padding: 20px;
                        justify-content: center;
                      "
                    >
                      <div><v-progress-circular button indeterminate /></div>
                      <div>Chargement en cours</div>
                    </div>
                  </template>
                  <template v-slot:item="{ item, index }">
                    <tr
                      :id="index"
                      v-bind:style="{ backgroundColor: getGiftColorLine(item) }"
                    >
                      <td style="border-right: 1px solid #cccccc">
                        <v-icon @click="getGift(item)">mdi-pencil</v-icon>
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ item.m_Id }}
                      </td>
                      <td
                        style="
                          text-align: center;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        <v-icon color="red" v-if="item.m_status === '3'"
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        <v-icon
                          color="green"
                          v-else-if="
                            (item.m_status === '1' && item.m_totalDebit > 0) ||
                            item.m_totalCredit > 0
                          "
                          >mdi-checkbox-blank-circle</v-icon
                        >
                        <v-icon v-else
                          >mdi-checkbox-blank-circle-outline</v-icon
                        >
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ formatShortDate(item.m_buyAt) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ formatShortDate(item.m_validAt) }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ getOrigin(item.m_origin) }}
                      </td>

                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        <span v-if="item.m_giftType === 1">Valeur</span>
                        <span v-if="item.m_giftType === 2">Prestation</span>
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ item.m_Code }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{ item.m_credited }}
                      </td>
                      <td
                        style="
                          text-align: right;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{
                          item.m_credited +
                          item.m_totalCredit -
                          item.m_totalDebit
                        }}
                      </td>
                      <td
                        style="
                          text-align: left;
                          border-right: 1px solid #cccccc;
                        "
                      >
                        {{
                          item.m_custormerlastname +
                          ' ' +
                          item.m_customerfirstname
                        }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <div>
                  <v-pagination
                    v-model="giftOptions.page"
                    :length="
                      Math.ceil(giftTotalItems / giftOptions.itemsPerPage)
                    "
                  />
                </div>
              </div>
            </v-col>
          </v-row>
          <div v-if="bookerEdit && tab === 0">
            <v-dialog
              v-model="bookerEditBox"
              scrollable
              :width="$vuetify.breakpoint.mdAndUp ? '60vw' : '90vw'"
            >
              <booker-edit
                @recharge="recharge"
                :item="bookerEdit"
                @close="bookereditClose"
              />
            </v-dialog>
          </div>
          <div v-else-if="giftEdit && tab === 1">
            <v-dialog
              v-model="giftEditBox"
              scrollable
              :width="$vuetify.breakpoint.lgAndUp ? '60vw' : '90vw'"
            >
              <gift-edit
                :item="giftEdit"
                @close="gifteditClose"
                @recharge="giftRecharge"
              />
            </v-dialog>
          </div>
        </v-container>
      </v-main>
      <v-footer app fixed>Biremont@Multimedia </v-footer>
    </v-app>
  </div>
</template>

<script>
import { StatsSDK } from '@/packages/artebeaute-sdk/src'
// import layoutHome from '../../layouts/layout-home.vue'
import dayjs from 'dayjs'
import BookerEdit from '@/components/booker/bookerEdit'
const Moment = require('moment')
// import TransactionsList from '@/components/booker/transactionsList.vue'
// import TransactionOld from '@/components/booker/transactionOld'
import { eorderStatus } from '@/helpers/eorder'
import currency from 'currency.js'
import BookerAggregate from '@/components/booker/bookerAggregate'
import BookerSearch from '@/components/booker/bookerSearch'
import GiftSearch from '@/components/booker/giftSearch'
import GiftEdit from '@/components/booker/giftEdit'

export default {
  components: {
    GiftEdit,
    GiftSearch,
    BookerSearch,
    BookerAggregate,
    BookerEdit,
    //  TransactionOld,
    // TransactionsList
  },
  data() {
    return {
      openSearchBox: false,
      bookerEditBox: false,
      giftEditBox: false,
      aggregates: {
        yesterday: {
          periode: {
            start: null,
            end: null,
          },
          booking: {
            eden: [],
            shop: [],
          },
          gift: {
            eden: [],
            shop: [],
          },
        },
        today: {
          periode: {
            start: null,
            end: null,
          },
          booking: {
            eden: [],
            shop: [],
          },
          gift: {
            eden: [],
            shop: [],
          },
        },
        week: {
          periode: {
            start: null,
            end: null,
          },
          booking: {
            eden: [],
            shop: [],
          },
          gift: {
            eden: [],
            shop: [],
          },
        },
        month: {
          periode: {
            start: null,
            end: null,
          },
          booking: {
            eden: [],
            shop: [],
          },
          gift: {
            eden: [],
            shop: [],
          },
        },
        current: {
          periode: {
            start: null,
            end: null,
          },
          booking: {
            eden: [],
            shop: [],
          },
          gift: {
            eden: [],
            shop: [],
          },
        },
      },
      bookerOrigins: [
        { text: 'Tous', value: '-1' },
        { text: 'EdenPass', value: '1' },
        { text: 'Boutique', value: '2' },
        { text: 'Import rdv', value: '9' },
        { text: 'Import crédit', value: '10' },
      ],
      bookerStatus: eorderStatus,
      tab: null,
      giftHeaders: [
        { text: '', value: 'actions', width: 50, sortable: false },
        { text: 'N°', value: 'm_Id', width: 100, sortable: true },
        { text: 'Statut', value: 'm_ActivAt', width: 50, sortable: false },
        { text: 'Acheté le', value: 'm_buyAt', width: 100, sortable: true },
        { text: 'Validité', value: 'm_validAt', width: 100, sortable: true },
        { text: 'Origine', value: 'm_origin', width: 100, sortable: false },

        { text: 'Type', value: 'm_giftType', width: 80, sortable: false },
        { text: 'Code', value: 'm_Code', width: 200, sortable: true },
        { text: 'valeur', value: 'm_credited', width: 80, sortable: true },
        { text: 'Solde', value: 'm_solde', width: 80, sortable: false },
        { text: 'Acheté par', value: 'm_customerfirstname', sortable: false },
      ],
      bookingHeaders: [
        { text: '', value: 'actions', width: 50 },
        { text: 'N°', value: 'nId', width: 80 },
        { text: 'Status', value: 'mStatus', width: 180 },
        { text: 'Origine', value: 'norigin', width: 120 },
        { text: 'Boutique', value: 'sShopName', width: 200 },
        { text: 'Opération du', value: 'dSendat', width: 150 },
        { text: 'Réserver le', value: 'dBookingAt', width: 120 },
        { text: 'Total Résa', value: 'mAmount', width: 120, align: 'end' },
        {
          text: 'Crédit Utilisé',
          value: 'mCreditUsed',
          width: 120,
          align: 'end',
        },
        { text: 'Bkdo Utilisé', value: 'mGiftUsed', width: 120, align: 'end' },
        { text: 'Réglement', value: 'mPaid', width: 120, align: 'end' },

        { text: 'Encaissé', value: 'mEncU', width: 120, align: 'end' },
        {
          text: 'Crédit/Débit',
          value: 'mCreditRestant',
          width: 140,
          align: 'end',
        },

        { text: 'Client', value: 'customerLastname', width: 150 },
        { text: 'Prénom', value: 'customerFirstname', width: 150 },
        { text: 'Tel', value: 'customerPhone', width: 100 },
        { text: 'email', value: 'customerEmail' },
      ],
      giftFilter: {
        createAt: {
          start: new Date().toISOString().split('T')[0],
          end: new Date().toISOString().split('T')[0],
        },
        used: null,
        code: '',
        transactionId: '',
        customerBuy: {
          lastName: '',
          firstName: '',
          adresseMail: '',
        },
      },
      bookingFilter: {
        customer: {
          lastName: '',
          firstName: '',
          adresseMail: '',
        },
        transactionId: '',
        periodeType: 'dSendat',
        createAt: {
          start: new Date().toISOString().split('T')[0],
          end: new Date().toISOString().split('T')[0],
        },
        booker: {
          origin: '-1',
          status: '-1',
        },
        shops: [],
      },
      currentId: null,
      orderItems: [],
      giftItems: [],
      bookingOptions: {},
      giftOptions: {},
      bookingtotalItems: 0,
      giftTotalItems: 0,
      bookingLoading: true,
      giftLoading: true,
      footerProp: {
        itemsPerPageOptions: [10, 25, 50, 100],
      },
      aggStart: null,
      aggEnd: null,
      statUrl: null,
      statToken: null,
      bookingOrders: [],
      bookerEdit: null,
      giftEdit: null,
      page: 'list',
      filterStatus: 'all',
      error: false,
      perPage: 25,
      transactionOld: [],
      choosenDates: null,
    }
  },
  async created() {
    await this.chargeToDayAggregate()
    await this.chargeYesterdayAggregate()
    await this.chargeMonthAggregate()

    // this.$emit('update:layout', layoutHome)
  },
  watch: {
    bookingOptions: {
      handler() {
        this.chargeBookingOrder()
      },
      deep: true,
    },
    giftOptions: {
      handler() {
        this.chargeGiftOrder()
      },
      deep: true,
    },
  },
  methods: {
    currency,
    getCreditRestant(item) {
      if (item.mStatus === 7) {
        return item.mPaid + item.mCreditUsed
      } else {
        return -1 * item.mCreditBefore
      }
    },
    getMontantEncaisse(item) {
      if (item.mIdEncaissement > 0) {
        return item.mUsed + item.mCreditBefore + item.mGiftRealUsed
      } else {
        return 0
      }
    },
    recharge(val) {
      let f = this.orderItems.findIndex((it) => it.nId === this.bookerEdit.nId)
      if (f > 0) {
        this.orderItems[f].mStatus = val
        this.bookerEdit = null
        // this.getBooker(this.bookerEdit.nId, this.booker.norigin)
      }
    },
    getOrigin(val) {
      const ori = this.bookerOrigins.find((bk) => bk.value === val.toString())
      if (ori) {
        return ori.text
      }
      return ''
    },
    getShopName(id) {
      return this.$store.getters['shop/abShopNameById'](id)
    },
    footerSearch() {
      const attrs = {}
      attrs.absolute = true
      attrs.fixed = true
      return attrs
    },
    bookereditClose() {
      this.bookerEdit = null
    },
    gifteditClose() {
      this.giftEdit = null
    },
    formatHour(val) {
      if (val) {
        return val.substring(0, 5)
      } else {
        return ''
      }
    },
    formatShortDate(val) {
      if (val) {
        return Moment(String(val)).format('DD/MM/YYYY')
      } else {
        return ''
      }
    },
    formatDate(val) {
      if (val) {
        return Moment.utc(val).local().format('DD/MM/YYYY HH:mm')
      } else {
        return ''
      }
    },
    searchBookerWithFilter(val) {
      this.bookingFilter = val
      this.bookingOptions.page = 1

      this.bookerEdit = null
      this.orderItems = []
      this.chargeBookingOrder()
    },
    searchGiftWithFilter(val) {
      this.giftFilter = val
      this.giftOptions.page = 1
      this.giftEdit = null
      this.giftItems = []
      this.chargeGiftOrder()
    },
    async getBooker(id, origin) {
      let booker = null
      if (origin !== 10) {
        booker = await StatsSDK.StatBooking.orderById(id)
      } else {
        booker = await StatsSDK.StatBooking.orderOldCreditById(id)
      }

      this.bookerEdit = booker.eorder.data
      this.bookerEditBox = true
    },
    getGift(val) {
      this.giftEdit = val
      this.giftEditBox = true
    },
    async chargeToDayPayment() {
      const today = Moment(new Date()).format('YYYYMMDD')
      this.aggregates.today.booking.shop =
        await StatsSDK.StatBooking.orderAggregate('', today, today)
      this.aggregates.today.gift.shop = await StatsSDK.StatGift.giftAggregate(
        today,
        today
      )
    },
    async chargeMonthPayment() {
      const debMonth = Moment().startOf('month').format('YYYYMMDD')
      const endMonth = Moment().endOf('month').format('YYYYMMDD')
      this.aggregates.month.booking.shop =
        await StatsSDK.StatBooking.orderAggregate('', debMonth, endMonth)
      this.aggregates.month.gift.eden = await StatsSDK.StatGift.giftAggregate(
        debMonth,
        endMonth
      )
    },
    async chargeCurrentPayment() {
      this.aggregates.current.booking.shop =
        await StatsSDK.StatShops.shopsBookerPayment(
          this.bookingFilter.createAt.start.replaceAll('-', ''),
          this.bookingFilter.createAt.end.replaceAll('-', '')
        )
      this.aggregates.current.gift.eden = await StatsSDK.StatGift.giftAggregate(
        this.giftFilter.createAt.start.replaceAll('-', ''),
        this.giftFilter.createAt.end.replaceAll('-', '')
      )
    },
    async chargeToDayAggregate() {
      const today = Moment(new Date()).format('YYYYMMDD')
      const aggreg = await StatsSDK.StatBooking.orderAggregate('', today, today)
      this.aggregates.today.booking.eden = aggreg.bookingAggregate
      await this.chargeToDayPayment()
    },
    async chargeYesterdayAggregate() {
      const yesterday = dayjs().subtract(1, 'days').format('YYYYMMDD') // Moment(new Date()).format('YYYYMMDD')
      const aggreg = await StatsSDK.StatBooking.orderAggregate(
        '',
        yesterday,
        yesterday
      )
      this.aggregates.yesterday.booking.eden = aggreg.bookingAggregate
      await this.chargeToDayPayment()
    },
    async chargeCurrentAggregate() {
      if (!this.aggStart || !this.aggEnd) {
        return
      }
      this.aggregates.current.periode.start = this.aggStart
      this.aggregates.current.periode.end = this.aggEnd
      const aggregBooker = await StatsSDK.StatBooking.orderAggregate(
        this.bookingFilter.periodeType,
        this.aggStart.replaceAll('-', ''),
        this.aggEnd.replaceAll('-', '')
      )
      this.aggregates.current.booking.eden = aggregBooker.bookingAggregate

      this.aggregates.current.gift.eden = await StatsSDK.StatGift.giftAggregate(
        this.aggStart.replaceAll('-', ''),
        this.aggEnd.replaceAll('-', '')
      )
      // await this.chargeCurrentPayment()
    },
    async chargeMonthAggregate() {
      const debMonth = Moment().startOf('month').format('YYYYMMDD')
      const endMonth = Moment().endOf('month').format('YYYYMMDD')
      const aggreg = await StatsSDK.StatBooking.orderAggregate(
        this.bookingFilter.periodeType,
        debMonth,
        endMonth
      )
      this.aggregates.month.booking.eden = aggreg.bookingAggregate
      await this.chargeMonthPayment()
    },
    async chargeGiftOrder() {
      this.giftLoading = true
      const result = await StatsSDK.StatGift.giftHistory(
        this.giftOptions.page,
        this.giftOptions.itemsPerPage,
        'createAt', // this.giftOptions.sortBy,
        1, // this.giftOptions.sortDesc,
        this.giftFilter.createAt.start.replaceAll('-', ''),
        this.giftFilter.createAt.end.replaceAll('-', ''),
        this.giftFilter.code,
        this.giftFilter.customerBuy.firstName,
        this.giftFilter.customerBuy.lastName,
        this.giftFilter.customerBuy.adresseMail,
        this.giftFilter.transactionId
      )
      this.giftTotalItems = result.giftHistory.m_nbenreg
      this.giftItems = result.giftHistory.tabs_gifts
      this.giftLoading = false

      this.aggStart = this.giftFilter.createAt.start
      this.aggEnd = this.giftFilter.createAt.end
      await this.chargeCurrentAggregate()
    },
    async chargeBookingOrder() {
      this.bookingLoading = true
      const fShops = this.bookingFilter.shops.reduce((prev, cur) => {
        prev += !prev ? cur : ',' + cur
        return prev
      }, '')

      const result = await StatsSDK.StatBooking.orderHistory(
        this.bookingOptions.page,
        this.bookingOptions.itemsPerPage,
        'createdAt', // this.bookingOptions.sortBy,
        1, // this.bookingOptions.sortDesc,
        this.bookingFilter.periodeType,
        this.bookingFilter.createAt.start.replaceAll('-', ''),
        this.bookingFilter.createAt.end.replaceAll('-', ''),
        this.bookingFilter.customer.lastName,
        this.bookingFilter.customer.firstName,
        this.bookingFilter.customer.adresseMail,
        this.bookingFilter.transactionId,
        this.bookingFilter.booker.origin,
        this.bookingFilter.booker.status,
        fShops
      )
      this.bookingtotalItems = result.bookingHistory.m_nbenreg
      this.orderItems = result.bookingHistory.tabs_order
      this.bookingLoading = false

      this.aggStart = this.bookingFilter.createAt.start
      this.aggEnd = this.bookingFilter.createAt.end
      await this.chargeCurrentAggregate()
    },
    getStatus(val) {
      const mystatus = this.bookerStatus.find(
        (st) => st.value === val.toString()
      )
      return mystatus.text
    },
    getFormatCurrency(val) {
      return currency(val, {
        symbol: '€',
        decimal: ',',
        pattern: ' # !',
        negativePattern: '- # !',
      }).format()
    },
    getBookerColorLine(val) {
      if (this.bookerEdit === null) return 'white'
      if (val.nId === this.bookerEdit.nId) {
        return '#edf0f5'
      }
      return 'white'
    },
    getGiftColorLine(val) {
      if (this.giftEdit === null) return 'white'
      if (val.m_Id === this.giftEdit.m_Id) {
        return '#edf0f5'
      }
      return 'white'
    },
    giftRecharge() {
      this.chargeGiftOrder()
    },
    /*async chargeOldTransaction() {
      const result = await ArteBeauteSDK.Appointments.appointmentOldRefundAsk()
      debugger
      if (result) {
        this.transactionOld = result.refundAsk.data
        debugger
      }
    },
    async changeOldTransactionStatus(ideorder, status) {
      debugger
      const result = await ArteBeauteSDK.Appointments.appointmentOldChangeStatus(
        ideorder,
        status
      )
      if (result) {
        if (result.refundAsk.data === true) {
          const l = this.transactionOld.findIndex(
            e => e.m_ideorder === ideorder
          )
          if (l !== -1) {
            this.transactionOld[l].m_status = status
          }
        }
      }
      console.log(result)
    },
    selectStatus(status) {
      this.page = 'list'
      this.filterStatus = status
    },
    async loadData(query = {}) {
      try {
        await this.$store.dispatch('transactions/fetchTransactions', {
          force: true,
          query: {
            page: query.page || 1,
            itemsPerPage: query.itemsPerPage || 25
          }
        })
      } catch (err) {
        this.error = true
      }
    },*/
    async refresh() {
      try {
        await this.$store.dispatch('transactions/fetchTransactions', {
          force: true,
        })
      } catch {
        //
      }
    },
    setAggregatePeriod(val) {
      this.$refs.bookerAggregate.setPeriod(val)
      this.choosenDates = val
    },
  },
}
</script>

<style scoped>
.borderCell {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.block {
  padding: 10px;
  background-color: white;
}
.block legend {
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px;
}
.celCurrency {
  text-align: right;
}
.mainFullHeight {
  height: 100% /* calc(100vh - 25px)*/;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #999999;
}
.topPanel {
  height: 30vh;
  background-color: #999999;
}
.bottomPanel {
  height: calc(100% - 25px);
  border-top: 2px solid #999999;
  display: flex;
  flex-direction: row;
}
.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #e0dddd;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 5px;
  z-index: 100;
  width: 20vw;
  min-width: 20vw;
}
.searchPanel {
}
.inputSearch {
  background-color: white;
  border: 1px solid grey;
  width: 100%;
  padding: 5px;
}
.boxEdit {
  position: absolute;
  background-color: white;

  padding: 15px;
  -webkit-box-shadow: -7px -2px 8px -7px #000000, -7px -2px 8px -7px #000000,
    -7px -2px 8px -7px #000000;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  width: 600px;
  right: 0;
  top: 0;
  z-index: 200;
  bottom: 5px;
}
.rightPanel {
  position: relative;
}
.sortButton {
  color: transparent;
}
.sortButton:hover {
  color: black;
}
.headerTableRubrique {
  display: flex;
  justify-content: space-between;
}
.headerTableRubrique div {
}
</style>
